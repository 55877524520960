import UrlRoute from "../API/UrlRoute";
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const useGetData = () => {
    const { authToken } = useAuth();
    const navigate = useNavigate();

    const getProfession = async () => {
        try {

            const apiUrl = `${process.env.REACT_APP_URL}${UrlRoute.REFERENCES_PROFESSIONS_URL}`;

            const response = await axios.get(apiUrl, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.data && response.data.response) {
                return response.data.response;
            } else {
                console.error('No valid response data found.');
            }

        } catch (error) {
            console.error('Failed to fetch professions:', error.message);
        }
    };

    const getCountry = async () => {
        try {

            const apiUrl = `${process.env.REACT_APP_URL}${UrlRoute.REFERENCES_COUNTRIES_URL}`;

            const response = await axios.get(apiUrl, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.data && response.data.response) {
                return response.data.response;
            } else {
                console.error('No valid response data found.');
            }

        } catch (error) {
            console.error('Failed to fetch country:', error.message);
        }
    };

    const getStates = async (city) => {
        try {

            const apiUrl = `${process.env.REACT_APP_URL}references/${city}/states`;

            const response = await axios.get(apiUrl, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.data && response.data.response) {
                return response.data.response;
            } else {
                console.error('No valid response data found.');
            }

        } catch (error) {
            console.error('Failed to fetch states:', error.message);
        }
    };

    const getCity = async (country, state) => {
        try {

            const apiUrl = `${process.env.REACT_APP_URL}references/${country}/${state}/cities`;

            const response = await axios.get(apiUrl, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.data && response.data.response) {
                return response.data.response;
            } else {
                console.error('No valid response data found.');
            }

        } catch (error) {
            console.error('Failed to fetch city:', error.message);
        }
    };

    const getBrandNames = async (brandName) => {
        try {

            const apiUrl = `${process.env.REACT_APP_URL}search/brand?q=${brandName}`;

            const response = await axios.get(apiUrl, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            
            if (response.data) {
                return response.data;
            } else {
                console.error('No valid response data found.');
            }

        } catch (error) {
            console.error('Failed to fetch brands:', error.message);
        }
    };

    const getSpecialities = async (speciality) => {
        try {

            const apiUrl = `${process.env.REACT_APP_URL}search/speciality?q=${speciality}`;

            const response = await axios.get(apiUrl, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.data) {
                return response.data;
            } else {
                console.error('No valid response data found.');
            }

        } catch (error) {
            console.error('Failed to fetch specialities:', error.message);
        }
    };

    const getProductCategories = async () => {
        try {

            const apiUrl = `${process.env.REACT_APP_URL}${UrlRoute.PRODUCT_CATEGORIES_URL}`;

            const response = await axios.get(apiUrl, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.data) {
                return response.data;
            } else {
                console.error('No valid response data found.');
            }

        } catch (error) {
            console.error('Failed to fetch product categories:', error.message);
        }
    };

    const getSkills = async (skills) => {
        try {

            const apiUrl = `${process.env.REACT_APP_URL}search/skills?q=${skills}`;

            const response = await axios.get(apiUrl, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.data) {
                return response.data;
            } else {
                console.error('No valid response data found.');
            }

        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/');
            } else {
                console.error('Failed to fetch skills:', error);
            }
        }
    };

    const getCollege = async (college) => {
        try {

            const apiUrl = `${process.env.REACT_APP_URL}search/college?q=${college}`;

            const response = await axios.get(apiUrl, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.data) {
                return response.data;
            } else {
                console.error('No valid response data found.');
            }

        } catch (error) {
            console.error('Failed to fetch college name:', error.message);
        }
    };

    const getHashtags = async (hashtags) => {
        try {

            const apiUrl = `${process.env.REACT_APP_URL}search/tags?q=${hashtags}`;

            const response = await axios.get(apiUrl, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.data) {
                return response.data;
            } else {
                console.error('No valid response data found.');
            }

        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/');
            } else {
                console.error('Failed to fetch hash tags:', error);
            }
        }
    };

    return {
        getProfession,
        getCountry,
        getStates,
        getCity,
        getBrandNames,
        getSpecialities,
        getProductCategories,
        getSkills,
        getCollege,
        getHashtags
    }
}

export default useGetData