import React, { useState } from 'react'
import { Box, TextField, Button, Container, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo_light.png';
import axios from 'axios';
import UrlRoute from "../API/UrlRoute";
import Constants from "../MeddyConnectConstant";
import { useAuth } from '../context/AuthContext';
import { toast } from 'react-toastify';

const LoginPage = () => {
  const [contactNo, setContactNo] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { saveToken } = useAuth();

  const handleLogin = async () => {
    setLoading(true);
    try {

      const apiUrl = `${process.env.REACT_APP_URL}${UrlRoute.GENERATE_TOKEN_URL}`;

      const response = await axios.post(apiUrl, {
        countryCode: "+91",
        contactNo,
        password,
        appUserType: "INTERNAL"
      });

      const token = response.data.token;

      if (token) {
        saveToken(token);
        toast.success("Login Successful!");
        navigate(Constants.HOME_PAGE);
      } else {
        toast.error("Something went wrong during login. Please try again.");
      }
    } catch (err) {
      toast.error("Login failed. Please check your credentials and try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Container
        maxWidth="xs"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >

        <Box
          sx={{
            width: '100%',
            p: 3,
            borderRadius: 4,
            boxShadow: 8,
            bgcolor: 'background.paper',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >

          <Box
            component="img"
            src={logo}
            alt="Logo"
            sx={{
              width: { xs: '60%', sm: '40%', md: '30%' },
              mb: 1,
            }}
          />

          {loading ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '200px',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box
              component="form"
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <TextField
                label="Mobile Number"
                variant="outlined"
                fullWidth
                margin="normal"
                value={contactNo}
                onChange={(e) => setContactNo(e.target.value)}
              />
              <TextField
                label="Password"
                type="password"
                variant="outlined"
                fullWidth
                margin="normal"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2, mb: 2 }}
                onClick={handleLogin}
              >
                Log In
              </Button>
            </Box>
          )}
        </Box>
      </Container>
    </>
  )
}

export default LoginPage