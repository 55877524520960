import {
    Button, Box, Typography,
    Card, CardActionArea, CardMedia, CardContent, CardActions,
} from '@mui/material';
import "./product.css";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import ImageNotAvailable from "../../../assets/imagenotavailable.jpg"
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import PlaceIcon from '@mui/icons-material/Place';
import Constants from "../../../MeddyConnectConstant";

const Product = ({ typeProduct, handleRemoveProductDialogClickOpen }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleEditProduct = (productId) => {
        navigate('/editsellproduct', { state: { data: productId } });
    };

    const handleShowInterested = (productId) => {
        navigate(`${Constants.SHOW_INTERESTED_USER}/${productId}`);
    };

    return (
        <>
            {fullScreen ? (
                <Box container direction="row" spacing={2}>
                    {typeProduct && typeProduct.length > 0 ? (
                        typeProduct.map((item) => {
                            return (
                                <Box flex={1} key={item.id}>
                                    <Card className="inventory-product-card" style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929" }}>
                                        <CardActionArea >
                                            {item.itemImages && item.itemImages[0] && item.itemImages[0].imageLink ?

                                                <CardMedia
                                                    component="img"
                                                    height="180"
                                                    image={item.itemImages[0].imageLink.href}
                                                    style={{ objectFit: "fill" }}
                                                    alt="inventory product picture"
                                                />
                                                :
                                                <CardMedia
                                                    component="img"
                                                    height="180"
                                                    image={ImageNotAvailable}
                                                    style={{ objectFit: "fill" }}
                                                    alt="inventory product picture"
                                                />
                                            }

                                            <CardContent>
                                                <Box container direction="row" alignItems="center" >
                                                    <Box flex={1}>
                                                        <Typography variant="h6" align="left" color="text.primary" className="text-capital inventoryproduct-title" >
                                                            {item.title}
                                                        </Typography>
                                                    </Box>
                                                    <Box flex={1}>
                                                        <Typography variant="subtitle1" align="left" color="text.primary" className='product-description'>
                                                            {item.description}
                                                        </Typography>
                                                    </Box>
                                                    <Box flex={1}>
                                                        <Typography variant="subtitle2" align="left" color="text.primary" className="text-capital">
                                                            Posted on: {moment(item.created).format("D-MMM-YYYY")}
                                                        </Typography>
                                                    </Box>
                                                    <Box flex={1}>
                                                        <Typography variant="body2" color="text.primary" className="text-capital" >
                                                            <PlaceIcon color="primary" style={{ fontSize: fullScreen ? "20px" : "24px", verticalAlign: "middle", marginLeft: "-4px" }} />
                                                            {item.city && item.state && item.country ? `${item.city}, ${item.state}, ${item.country}` :
                                                                (item.city && item.state ? `${item.city}, ${item.state}` :
                                                                    (item.city && item.country ? `${item.city}, ${item.country}` :
                                                                        (item.state && item.country ? `${item.state}, ${item.country}` :
                                                                            item.city || item.state || item.country
                                                                        )
                                                                    )
                                                                )
                                                            }
                                                        </Typography>
                                                    </Box>

                                                </Box>

                                            </CardContent>
                                        </CardActionArea>

                                        <CardActions style={{ padding: "0px 8px 8px 8px" }}>
                                            <Box container direction="column">
                                                <Box flex={1} >
                                                    <Button variant="contained" fullWidth className="inventoryproductcard-btn" color="primary"
                                                        onClick={() => handleShowInterested(item.id)}
                                                    >
                                                        Show interested
                                                    </Button>
                                                </Box>
                                                <Box flex={1} >
                                                    <Button variant="contained" fullWidth className="inventoryproductcard-btn" color="primary"
                                                        onClick={() => handleEditProduct(item.id)}>
                                                        Edit
                                                    </Button>
                                                </Box>
                                                <Box flex={1}>
                                                    <Button variant="contained" fullWidth className="inventoryproductcard-btn productdelete-btn"
                                                        onClick={() => { handleRemoveProductDialogClickOpen(item.id) }}>
                                                        Delete
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </CardActions>

                                    </Card>
                                </Box>
                            );
                        })
                    ) : (
                        <Box container style={{ marginTop: "10px" }}>
                            <Box flex={1}>
                                <Typography align="center" color="text.primary" variant="h6" gutterBottom>
                                    Your inventory is currently empty.
                                </Typography>
                            </Box>
                        </Box>
                    )
                    }

                </Box>
            )
                :
                (
                    <>
                        {typeProduct && typeProduct.length > 0 ? (
                            typeProduct.map((item) => {
                                return (
                                    <div className="product-card" key={item.id} style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929" }}>

                                        {item.itemImages && item.itemImages[0] && item.itemImages[0].imageLink ?
                                            <div className='product-img-container'>
                                                <img src={item.itemImages[0].imageLink.href} alt="inventory product picture" className='product-img' />
                                            </div>
                                            :
                                            <div className='product-img-container'>
                                                <img src={ImageNotAvailable} alt="inventory product picture" className='product-img' />
                                            </div>
                                        }

                                        <div className="product-info">
                                            <Typography variant="h6" align="left" color="text.primary" className="text-capital inventoryproduct-title" >
                                                {item.title}
                                            </Typography>
                                            <Typography variant="subtitle1" color="text.primary" align="left" className='product-description'>
                                                {item.description}
                                            </Typography>
                                            <Typography variant="subtitle2" color="text.primary" align="left" className="text-capital">
                                                Posted on: {moment(item.created).format("D-MMM-YYYY")}
                                            </Typography>
                                            <Typography variant="body2" color="text.primary" className="text-capital" >
                                                <PlaceIcon color="primary" style={{ fontSize: fullScreen ? "20px" : "24px", verticalAlign: "middle", marginLeft: "-4px" }} />
                                                {item.city && item.state && item.country ? `${item.city}, ${item.state}, ${item.country}` :
                                                    (item.city && item.state ? `${item.city}, ${item.state}` :
                                                        (item.city && item.country ? `${item.city}, ${item.country}` :
                                                            (item.state && item.country ? `${item.state}, ${item.country}` :
                                                                item.city || item.state || item.country
                                                            )
                                                        )
                                                    )
                                                }
                                            </Typography>

                                            <div className='product-btn-container'>
                                                <Button variant="contained" color="primary" className="product-button" onClick={() => handleShowInterested(item.id)}>Show interested</Button>
                                                <Button variant="contained" color="primary" className="product-button" onClick={() => handleEditProduct(item.id)}>Edit</Button>
                                                <Button variant="contained" className="product-button productdelete-btn" onClick={() => { handleRemoveProductDialogClickOpen(item.id) }}>Delete</Button>
                                            </div>

                                        </div>

                                    </div>
                                );
                            })
                        ) : (
                            <Box container style={{ marginTop: "10px" }}>
                                <Box flex={1}>
                                    <Typography align="center" color="text.primary" variant="h6" gutterBottom>
                                        Your inventory is currently empty.
                                    </Typography>
                                </Box>
                            </Box>
                        )
                        }
                    </>
                )
            }

        </>
    )
}

export default Product
