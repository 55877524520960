import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Slide, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Constants from "../MeddyConnectConstant";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const VerificationAlertDialog = ({ openAlertVerificationDialog, handleAlertVerificationDialogClickClose, message }) => {
    const navigate = useNavigate();

    const handleVerifyClick = () => {
        navigate(Constants.VERIFICATION);
    };

    const handleCancel = () => {
        navigate(Constants.HOME_PAGE);
    };

    return (
        <>
            <Dialog
                open={openAlertVerificationDialog}
                // onClose={handleAlertVerificationDialogClickClose}
                TransitionComponent={Transition}
            >
                <DialogTitle>MeddyConnect</DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle1" color="text.primary" align="left">
                        {message}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary" >Cancel</Button>
                    <Button onClick={handleVerifyClick} color="primary" >Verify</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default VerificationAlertDialog
