import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Tab,
  Tabs,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,Paper,
  DialogActions,Container,CircularProgress,
} from "@mui/material";
import "./ManageProducts.css";
import Product from "./Product";
import Contract from "./Contract";
import Archive from "./Archive";
import UrlRoute from "../../../API/UrlRoute";
import { useAuth } from "../../../context/AuthContext";
import NewLaunch from "./NewLaunch";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../MainLayout";
import axios from "axios";

const ManageProducts = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);
  const [currentPackage, setCurrentPackage] = useState("");
  const [inventory, setInventory] = useState([]);
  const [getProductId, setGetProductId] = useState("");
  const [getArchiveId, setArchiveId] = useState("");
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false)
  const [isActiveComment,setIsActiveComment] = useState("")
  const [isInActiveComment,setIsInActiveComment] = useState("")
  const { authToken, searchedUser } = useAuth();
  const userId = searchedUser?.userId;

  // Services

  // Dialogs
  const [openRemoveInventoryItemDialog, setOpenRemoveInventoryItemDialog] =
    React.useState(false);
  const [openSubscriptionDialog, setOpenSubscriptionDialog] =
    React.useState(false);
  const [openUpgradePackageDialog, setOpenUpgradePackageDialog] =
    React.useState(false);
    const [openRestoreItemDialog, setOpenRestoreItemDialog] =
    React.useState(false);

  const handleRemoveInventoryItemDialogClickOpen = (id) => {
    setOpenRemoveInventoryItemDialog(true);
    setGetProductId(id);
  };

  const handleRemoveInventoryItemDialogClickClose = () => {
    setOpenRemoveInventoryItemDialog(false);
  };

  // for opening restore item dialog

  const handleRestoreItemDialogClickOpen = (id) => {
    setOpenRestoreItemDialog(true)
    setArchiveId(id)
  };
 

  // for closing restore item dialog
  const restoreDialogClose = () =>{
    setOpenRestoreItemDialog(false)
  }

  const handleSubscriptionDialogClickOpen = () => {
    setOpenSubscriptionDialog(true);
  };

  const handleSubscriptionDialogClickClose = () => {
    setOpenSubscriptionDialog(false);
  };

  const handleUpgradePackageDialogClickClose = () => {
    setOpenUpgradePackageDialog(false);
  };

  const handleUpgradePackageDialogClickOpen = () => {
    setOpenUpgradePackageDialog(true);
  };

  const handleBuy = () => {
   navigate("/packagedetails");
  };

  useEffect(() => {
    // getPackages();
    getUserInventory();
    // checkPackage();
  }, []);


  // getpackages: Responsible for gettting user current  packages
  const getPackages = async (userId) => {
    const apiUrl = `${process.env.REACT_APP_URL}${userId}/${UrlRoute.ACTIVE_PACKAGE_URL}`;

    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      setCurrentPackage(response.data);
    } else {
      toast.error("Something went wrong!");
    }
  };


  //load actual data of that page 
  const getUserInventory = async () => {
    setLoading(true);
    const apiUrl = `${process.env.REACT_APP_URL}${UrlRoute.MANAGEINVENTORY_URL}/${userId}`;

    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      setInventory(response.data);
      // getUserInfo();
      setLoading(false);
    } else {
      setLoading(false);
        toast.error("Something went wrong!");
    }
  };


  // filter for inventory seperate out product,contract,archieve,New launch
  let typeProduct = inventory.filter(
    (member) => member.itemType === "PRODUCT" && member.active === true
  );

  let typeContract = inventory.filter(
    (member) => member.itemType === "CONTRACT" && member.active === true
  );

  let typeNewProductLaunch = inventory.filter(
    (member) => member.itemType === "NEW_LAUNCH" && member.active === true
  );

  let typeArchive = inventory.filter((member) => member.active === false);


 //Inactive items that goes to archive
  const handleDelete = async (getId) => {
    setLoading(true)
    setOpenRemoveInventoryItemDialog(false);
    const apiUrl = `${process.env.REACT_APP_URL}items/activate/${userId}/${getId}/false`;

    const requestBody = {
     comment:isActiveComment
    }

    const response = await axios.put(apiUrl,requestBody,{
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status == 200) {
      getUserInventory();
      setLoading(false);
    } else {
      setLoading(false);
      toast.error("Something went wrong!");
    }
  };

  const handleRestore = async (getArchiveId) => {
    setLoading(true)
    setOpenRestoreItemDialog(false);
    const apiUrl = `${process.env.REACT_APP_URL}items/activate/${userId}/${getArchiveId}/true`;

    const requestBody = {
      comment:isInActiveComment
     }

    const response = await axios.put(apiUrl,requestBody, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });

    console.log(response)
    if (response.status === 200) {
      toast.success("product restore successfully")
      getUserInventory();
      setLoading(false);
    } else {
      setLoading(false);
      toast.error("Something went wrong!");
    }
  }

  // const handleInventoryActive = async (getArchiveId) => {
  //   setLoading(true);
  //   if (searchedUser.allowedNoOfProducts - searchedUser.activeNoOfProducts <= 0) {
  //     setLoading(false);
  //     handleUpgradePackageDialogClickOpen();
  //   } else {
  //     handleRestore(getArchiveId)
  //   }
  // };

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <MainLayout>
        <Container
          maxWidth="md"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 0.5,
            overflowY: "auto",
            width: "100%",
            p: 0,
          }}
        >
          {" "}
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Paper elevation={2} className="inventory-container">
                <div className="tabs-container">
                  <Box container>
                    <Box item xs={12}>
                      <Tabs
                        value={selectedTab}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons={false}
                      >
                        <Tab label="Product" />
                        <Tab label="Repair & Contract" />
                        <Tab label="New Launch" />
                        <Tab label="Archive" />
                      </Tabs>
                    </Box>
                  </Box>
                </div>
                <div className="content-container">
                  {selectedTab === 0 && (
                    <Product
                      typeProduct={typeProduct}
                      handleRemoveProductDialogClickOpen={
                        handleRemoveInventoryItemDialogClickOpen
                      }
                    />
                  )}
                  {selectedTab === 1 && (
                    <Contract
                      typeContract={typeContract}
                      handleRemoveContractDialogClickOpen={
                        handleRemoveInventoryItemDialogClickOpen
                      }
                    />
                  )}
                  {selectedTab === 2 && (
                    <NewLaunch
                      typeNewProductLaunch={typeNewProductLaunch}
                      handleRemoveNewProductLaunchDialogClickOpen={
                        handleRemoveInventoryItemDialogClickOpen
                      }
                    />
                  )}
                  {selectedTab === 3 && (
                    <Archive
                      typeArchive={typeArchive}
                      handleRestoreArchieveItemDialogClickOpen = {
                        handleRestoreItemDialogClickOpen
                      }
                    />
                  )}
                </div>
              </Paper>

               {/* dialog for iN activating Items */}
              <Dialog
                open={openRemoveInventoryItemDialog}
                onClose={handleRemoveInventoryItemDialogClickClose}
                maxWidth="xs"
              >
                <DialogTitle style={{ backgroundColor: "#00B9F1", color: "white" }}>InActivate Item</DialogTitle>
                <DialogContent
                 sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px" // Adds consistent spacing between children
                }}>
                  <Typography variant="body1" color="text.primary" >
                    Are you sure you want to remove it?
                  </Typography>
                  <Box style={{marginTop:"12px"}}>
                    <TextField fullWidth
                      label="Comment"
                      name="Comment"
                      value={isActiveComment}
                      onChange={(event) => {
                        const inputValue = event.target.value;
                        const formattedValue = inputValue
                          .split(" ")
                          .map((word) => {
                            if (word === word.toUpperCase()) {
                              return word;
                            } else {
                              return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                            }
                          })
                          .join(" ");
                        setIsActiveComment(formattedValue);
                      }}
                      autoComplete="off"
                      id="Comment"
                      variant="outlined"
                    />
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleRemoveInventoryItemDialogClickClose}
                    className="alert_btn"
                    color="primary"
                    disableRipple
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      handleDelete(getProductId);
                    }}
                    className="alert_btn"
                    color="primary"
                    disableRipple
                  >
                    Remove
                  </Button>
                </DialogActions>
              </Dialog>

           {/* dialog for Rstoring Items */}
              <Dialog
                open={openRestoreItemDialog}
                onClose={restoreDialogClose}
                maxWidth="xs"
              >
                <DialogTitle  style={{ backgroundColor: "#00B9F1", color: "white" }}>Restore Item</DialogTitle>
                <DialogContent sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px" // Adds consistent spacing between children
                }}>
                  <Typography variant="body1" color="text.primary">
                    Are you sure you want to Restore item ?
                  </Typography>
                  <Box>
                    <TextField fullWidth
                      label="Comment"
                      name="Comment"
                      value={isInActiveComment}
                      onChange={(event) => {
                        const inputValue = event.target.value;
                        const formattedValue = inputValue
                          .split(" ")
                          .map((word) => {
                            if (word === word.toUpperCase()) {
                              return word;
                            } else {
                              return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                            }
                          })
                          .join(" ");

                        setIsInActiveComment(formattedValue);
                      }}
                      autoComplete="off"
                      id="Comment"
                      variant="outlined"
                    />
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={restoreDialogClose}
                    className="alert_btn"
                    color="primary"
                    disableRipple
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      handleRestore(getArchiveId);
                    }}
                    className="alert_btn"
                    color="primary"
                    disableRipple
                  >
                    Remove
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog
                open={openSubscriptionDialog}
                onClose={handleSubscriptionDialogClickClose}
                maxWidth="xs"
              >
                <DialogTitle>MeddyConnect</DialogTitle>
                <DialogContent sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px" // Adds consistent spacing between children
                }}>
                  <Typography variant="body1" color="text.primary">
                    You have't subscribed to any plan, please complete your
                    payment to use this feature.
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleSubscriptionDialogClickClose}
                    className="alert_btn"
                    color="primary"
                    disableRipple
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleBuy}
                    className="alert_btn"
                    color="primary"
                    disableRipple
                  >
                    Buy
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog
                open={openUpgradePackageDialog}
                onClose={handleUpgradePackageDialogClickClose}
              >
                <DialogTitle>MeddyConnect</DialogTitle>
                <DialogContent>
                  <Typography variant="body1" color="text.primary">
                    You have reached the limit of active items, your
                    subscription allow {searchedUser.allowedNoOfProducts} active
                    items only.
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleUpgradePackageDialogClickClose}
                    className="alert_btn"
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleBuy}
                    className="alert_btn"
                    color="primary"
                  >
                    Upgrade
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )}
        </Container>
      </MainLayout>
    </>
  );
};

export default ManageProducts;
