import React, { useState, useEffect } from 'react';
import { Box, Typography, Container, Card, CardContent, Avatar } from '@mui/material';
import MainLayout from "./MainLayout";
import { useAuth } from '../context/AuthContext';
import IdentityDocument from "./IdentityDocument";
import ProfessionalDocument from "./ProfessionalDocument";

const UserDocumentVerification = () => {
  const { searchedUser } = useAuth();
  const [identityFrontDocId, setIdentityFrontDocId] = useState(null);
  const [identityBackDocId, setIdentityBackDocId] = useState(null);
  const [professionalDocId, setProfessionalDocId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userType, setUserType] = useState("");
  const [userName, setUserName] = useState("");

  useEffect(() => {
    if (searchedUser) {

      const documents = searchedUser.identities;

      // Filter documents for 'FRONT' and 'BACK' for Identity, and other for Professional
      const identityDocs = documents.filter(doc => doc.documentType === "ID_CARD_DOCUMENT_FRONT" || doc.documentType === "ID_CARD_DOCUMENT_BACK");
      const professionalDocs = documents.filter(doc => doc.documentType !== "ID_CARD_DOCUMENT_FRONT" && doc.documentType !== "ID_CARD_DOCUMENT_BACK");

      // Sort by the latest creation date
      const sortedIdentityDocs = identityDocs.sort((a, b) => new Date(b.created) - new Date(a.created));
      const sortedProfessionalDocs = professionalDocs.sort((a, b) => new Date(b.created) - new Date(a.created));

      // Get the latest documents
      const latestFrontDoc = sortedIdentityDocs.find(doc => doc.documentType === "ID_CARD_DOCUMENT_FRONT");
      const latestBackDoc = sortedIdentityDocs.find(doc => doc.documentType === "ID_CARD_DOCUMENT_BACK");
      const latestProfessionalDoc = sortedProfessionalDocs[0];

      setIdentityFrontDocId(latestFrontDoc?.id || null);
      setIdentityBackDocId(latestBackDoc?.id || null);
      setProfessionalDocId(latestProfessionalDoc?.id || null);
      setUserId(searchedUser.userId);
      setUserType(searchedUser.userType);
      setUserName(searchedUser.name);

    }
  }, [searchedUser])

  return (
    <>
      <MainLayout>
        <Container
          maxWidth="md"
          disableGutters
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            alignItems: 'center',
            // height: '100vh',
            overflowY: 'auto',
            width: '100%',
            p: 1
          }}
        >

          {searchedUser && (
            <>
              <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>

                {/* User Info Card */}
                <Card sx={{ width: '100%', boxShadow: 6 }}>
                  <CardContent
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', sm: 'row' }, // Stack vertically on small screens
                      alignItems: 'center',
                      gap: 2,
                      textAlign: { xs: 'center', sm: 'left' }
                    }}
                  >
                    <Avatar
                      src={searchedUser.picture}
                      sx={{ width: 80, height: 80 }}
                    />
                    <Box sx={{ flex: 1 }}>
                      <Typography variant="h6" component="div">
                        {searchedUser.name}
                      </Typography>
                      <Typography variant="body1">
                        User Type: {searchedUser.userType}
                      </Typography>
                      <Typography variant="body1">
                        Mobile: {searchedUser.contactNo}
                      </Typography>
                      <Typography variant="body1">
                        Address: {searchedUser.address}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>

                {/* Document Sections */}

                <IdentityDocument
                  identityFrontDocId={identityFrontDocId}
                  identityBackDocId={identityBackDocId}
                  userId={userId}
                  userName={userName}
                  setIdentityFrontDocId={setIdentityFrontDocId}
                  setIdentityBackDocId={setIdentityBackDocId}
                />

                <ProfessionalDocument
                  professionalDocId={professionalDocId}
                  userId={userId}
                  userType={userType}
                  userName={userName}
                  setProfessionalDocId={setProfessionalDocId}
                />

              </Box>
            </>
          )}

        </Container>
      </MainLayout>
    </>
  )
}

export default UserDocumentVerification
