import imageCompression from 'browser-image-compression'
import { ConvertImageToBase64 } from '../utils/ImageToBase64';
import { toast } from 'react-toastify';

const options = {
    maxSizeMB: 0.5,
}

export const fileCompressor = async (imageFile) => {
    try {
        const size = imageFile.size / 1024 / 1024;
        if (size > 0.5) {
            return await ConvertImageToBase64(await imageCompression(imageFile, options));
        } else {
            return await ConvertImageToBase64(imageFile);
        }
    } catch (error) {
        toast.error("Something went wrong, please try again!!!");
        return null;
    }
}