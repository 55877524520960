import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, CircularProgress, IconButton, InputLabel, MenuItem, Select, FormControl } from '@mui/material';
import { CloudUpload as CloudUploadIcon, Cancel as CancelIcon } from '@mui/icons-material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import UrlRoute from "../API/UrlRoute";
import { useAuth } from '../context/AuthContext';
import { fileCompressor } from "../components/FileCompressor";
import { toast } from 'react-toastify';

const documents = [
    { id: 1, documentname: 'Medical Registration Certificate', documenttype: 'MEDICAL_REGISTRATION_NUMBER', usertype: "DOCTOR" },
    { id: 2, documentname: 'Shop Establishment Certificate', documenttype: 'SHOP_ESTABLISHMENT_CERTIFICATE', usertype: "VENDOR" },
    { id: 3, documentname: 'GST Certificate', documenttype: 'GST_NUMBER', usertype: "VENDOR" },
    { id: 4, documentname: 'Office ID Card', documenttype: 'PROFESSIONAL_ID_DOCUMENT', usertype: "STAFF" },
    { id: 5, documentname: 'College ID Card', documenttype: 'STUDENT_ID_DOCUMENT', usertype: "STUDENT" },
    { id: 6, documentname: 'Medical Association Certificate', documenttype: 'ASSOCIATION_CERTIFICATE', usertype: "MEDICAL_ASSOCIATION" },
    { id: 7, documentname: 'Shop Establishment Certificate', documenttype: 'SHOP_ESTABLISHMENT_CERTIFICATE', usertype: "HOSPITAL" },
    { id: 8, documentname: 'GST Certificate', documenttype: 'GST_NUMBER', usertype: "HOSPITAL" },
];

const ProfessionalDocument = ({ professionalDocId, userId, userType, userName, setProfessionalDocId }) => {
    const [loading, setLoading] = useState(true);
    const [professionalDocData, setProfessionalDocData] = useState(null);
    const navigate = useNavigate();
    const { authToken } = useAuth();

    // State for document details
    const [documentType, setDocumentType] = useState('');
    const [documentNumber, setDocumentNumber] = useState('');
    const [comment, setComment] = useState('');
    const [verificationStatus, setVerificationStatus] = useState('');

    // State for image selection and compression
    const [selectedImage, setSelectedImage] = useState(null);
    const [compressedImage, setCompressedImage] = useState({ contentType: null, content: null });

    const documentsOptions = documents.filter(option => option.usertype === userType);

    const fetchProfessionalDoc = async () => {
        try {
            if (professionalDocId && userId) {

                const apiUrl = `${process.env.REACT_APP_URL}${UrlRoute.USER_VERIFICATION_DOCUMENT_URL}/${userId}/${professionalDocId}`;

                const response = await axios.get(apiUrl, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                        'Content-Type': 'application/json',
                    },
                });
                setProfessionalDocData(response.data);
                setDocumentType(response.data.documentType || '');
                setDocumentNumber(response.data.documentNumber || '');
                setComment(response.data.comment || '');
                setVerificationStatus(response.data.verificationStatus || '');
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/');
            } else {
                toast.error("Error fetching professional document:");
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProfessionalDoc();
    }, [professionalDocId]);

    const handleApprove = async () => {
        setLoading(true);
        try {
            const apiUrl = `${process.env.REACT_APP_URL}${UrlRoute.USER_VERIFICATION_DOCUMENT_URL}/${userId}/${professionalDocId}`;

            const requestBody = {
                documentType,
                documentNumber,
                comment: 'Your document has been successfully verified',
                verificationStatus: 'VERIFIED',
            };

            const response = await axios.put(apiUrl, requestBody, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    'Content-Type': 'application/json',
                },
            });

            toast.success("Document approved successfully !!!");
            fetchProfessionalDoc();
        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/');
            } else {
                toast.error("Error approving document:");
            }
        } finally {
            setLoading(false);
        }
    };

    const handleReject = async () => {
        setLoading(true);
        try {
            const apiUrl = `${process.env.REACT_APP_URL}${UrlRoute.USER_VERIFICATION_DOCUMENT_URL}/${userId}/${professionalDocId}`;

            const requestBody = {
                documentType,
                documentNumber,
                comment: 'Your document has been rejected',
                verificationStatus: 'VERIFICATION_FAILED',
            };

            const response = await axios.put(apiUrl, requestBody, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    'Content-Type': 'application/json',
                },
            });

            toast.success("Document rejected successfully !!!");
            fetchProfessionalDoc();
        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/');
            } else {
                console.error('', error);
                toast.error("Error approving document:");
            }
        } finally {
            setLoading(false);
        }
    };

    const handleImageChange = async (event) => {
        try {
            const img = event.target.files[0];

            if (img) {
                let fileName = img.name;
                let idxDot = fileName.lastIndexOf(".") + 1;
                let extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
                if (extFile === "jpg" || extFile === "jpeg" || extFile === "png") {
                    // Show loader while processing
                    setLoading(true);

                    let url = URL.createObjectURL(img);
                    let imageData = await fileCompressor(img);

                    if (imageData) {
                        let contentType =
                            extFile.toLowerCase() === "png" ? "image/png" : "image/jpeg";

                        setCompressedImage({ contentType: contentType, content: imageData });
                        setSelectedImage(url);
                    } else {
                        toast.error("An error occurred while compressing the image");
                    }

                    // Hide loader after processing
                    setLoading(false);
                } else {
                    toast.error("Please upload valid(jpeg/png) image!!!");
                }
            }

        } catch (error) {
            toast.error("An error occurred while processing the image");
            // Hide loader in case of error
            setLoading(false);
        }

    };

    const handleRemoveImage = () => {
        setCompressedImage({ contentType: null, content: null });
        setSelectedImage(null);
    };

    const handleSubmit = async () => {
        if (!documentType) {
            toast.error("Please enter the document type");
            return;
        }

        if (!selectedImage) {
            toast.error("Please select an image");
            return;
        }

        if (!compressedImage.content) {
            toast.error("Document image cannot be empty");
            return;
        }

        setLoading(true);
        try {

            const apiUrl = `${process.env.REACT_APP_URL}${UrlRoute.USER_VERIFICATION_DOCUMENT_URL}/${userId}`;

            const requestBody = {
                documentType,
                contentType: compressedImage.contentType,
                content: compressedImage.content,
                comment: `Uploading document on behalf of ${userName}`,
            };

            const response = await axios.post(apiUrl, requestBody, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                setProfessionalDocId(response.data.id);
                toast.success("Document uploaded successfully !!!");
                handleRemoveImage();
            }

        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/');
            } else {
                toast.error("An error occurred while uploading the document");
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Typography variant="h6" gutterBottom>
                        Professional Document
                    </Typography>
                    {professionalDocData ? (
                        <Box sx={{ width: '100%' }}>
                            <Typography variant="subtitle1" gutterBottom>
                                Status : {verificationStatus && verificationStatus}
                            </Typography>
                            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>

                                <Box
                                    sx={{
                                        width: { xs: '100%', sm: '80%' },
                                        height: 'auto',
                                        maxHeight: '600px',
                                        overflow: 'hidden',
                                        borderRadius: 2,
                                        boxShadow: 3,
                                    }}
                                >
                                    <img
                                        src={`data:${professionalDocData.contentType};base64,${professionalDocData.content}`}
                                        alt={`Professional Document Front`}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',
                                        }}
                                    />
                                </Box>

                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: { xs: 'column', sm: 'row' },
                                    gap: 2,
                                    mt: 2,
                                    width: '100%',
                                    justifyContent: { sm: 'space-between' },
                                }}
                            >
                                <Button
                                    variant="contained"
                                    color="success"
                                    sx={{
                                        width: { xs: '100%', sm: '48%' }, textTransform: 'capitalize', fontSize: "16px"
                                    }}
                                    onClick={handleApprove}
                                >
                                    Approve Professional
                                </Button>
                                <Button
                                    variant="contained"
                                    color="error"
                                    sx={{
                                        width: { xs: '100%', sm: '48%' }, textTransform: 'capitalize', fontSize: "16px"
                                    }}
                                    onClick={handleReject}
                                >
                                    Reject Professional
                                </Button>
                            </Box>
                        </Box>
                    ) : (
                        <>
                            <Box sx={{ width: '100%' }}>

                                <FormControl fullWidth>
                                    <InputLabel id="document-select-label">
                                        Document Type
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        label="Document Type"
                                        labelId="document-select-label"
                                        id="document-type-picker"
                                        value={documentType}
                                        onChange={(e) => setDocumentType(e.target.value)}
                                    >
                                        <MenuItem value="">Select Document</MenuItem>
                                        {
                                            documentsOptions.map((item) => {
                                                return (
                                                    <MenuItem value={item.documenttype} key={item.id}>{item.documentname}</MenuItem>
                                                );
                                            })}
                                    </Select>
                                </FormControl>

                                <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center', mt: 2 }}>

                                    <Box
                                        sx={{
                                            width: { xs: '100%', sm: '80%' },
                                            height: "400px",
                                            overflow: 'hidden',
                                            position: 'relative',
                                            cursor: 'pointer',
                                            borderRadius: 2,
                                            boxShadow: 3,
                                        }}
                                    >
                                        {/* Image Picker */}
                                        {!selectedImage ? (
                                            <>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={handleImageChange}
                                                    style={{
                                                        position: 'absolute',
                                                        width: '100%',
                                                        height: '100%',
                                                        opacity: 0,
                                                        cursor: 'pointer',
                                                        zIndex: 2,
                                                    }}
                                                />
                                                <CloudUploadIcon sx={{
                                                    fontSize: 85,
                                                    color: 'grey',
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    zIndex: 1,
                                                }}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <img
                                                    src={selectedImage}
                                                    alt="Selected"
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        // objectFit: 'cover',
                                                    }}
                                                />
                                                <IconButton
                                                    sx={{
                                                        position: 'absolute',
                                                        top: 5,
                                                        right: 5,
                                                        backgroundColor: 'white',
                                                        color: 'red',
                                                        '&:hover': { backgroundColor: 'lightgrey' },
                                                    }}
                                                    size="small"
                                                    onClick={handleRemoveImage}
                                                >
                                                    <CancelIcon fontSize="small" />
                                                </IconButton>
                                            </>
                                        )}
                                    </Box>

                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: 1,
                                        mt: 2,
                                        width: '100%',
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        color="success"
                                        sx={{
                                            width: '100%', textTransform: 'capitalize', fontSize: "16px"
                                        }}
                                        onClick={handleSubmit}
                                    >
                                        Submit
                                    </Button>
                                </Box>
                            </Box>
                        </>
                    )}
                </>
            )}
        </>
    )
}

export default ProfessionalDocument
