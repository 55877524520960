export const ConvertImageToBase64 = (myFile) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      try {
        if (fileReader && myFile) {
          // console.log(myFile, "image file", fileReader);
          fileReader.readAsDataURL(myFile);
          fileReader.onload = () => {
            if (fileReader.result) {
              var strImage = fileReader.result.toString().split(",")[1];
              resolve(strImage);
            } else {
              throw new Error("Error Converting Image to Base64");
            }
          };
        }
      } catch (error) {
        fileReader.onerror = (error) => {
          console.log(error, "error converting image to base64");
        };
        console.log(error, "error converting image to base64");
        reject(error);
      }
    });
  };
  