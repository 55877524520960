import {
    Button,Box, Typography,
    Card, CardActionArea, CardMedia, CardContent, CardActions
} from '@mui/material';
import "./contract.css";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import ImageNotAvailable from "../../../assets/imagenotavailable.jpg"
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import PlaceIcon from '@mui/icons-material/Place';
import Constants from "../../../MeddyConnectConstant";

const Contract = ({ typeContract, handleRemoveContractDialogClickOpen }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleEditContract = (contractId) => {
        navigate('/editsellcontract', { state: { data: contractId } });
    };

    const handleShowInterested = (contractId) => {
        navigate(`${Constants.SHOW_INTERESTED_USER}/${contractId}`);
    };

    return (
        <>
            {fullScreen ? (
                <Box display="flex" flexDirection="column" gap={2}>
                    {typeContract && typeContract.length > 0 ? (
                        typeContract.map((item) => {
                            return (
                                <Box flex={1} key={item.id}>
                                    <Card className="inventory-contract-card" style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929" }}>
                                        <CardActionArea >
                                            {item.itemImages && item.itemImages[0] && item.itemImages[0].imageLink ?

                                                <CardMedia
                                                    component="img"
                                                    height="180"
                                                    image={item.itemImages[0].imageLink.href}
                                                    style={{ objectFit: "fill" }}
                                                    alt="inventory contract picture"

                                                />
                                                :
                                                <CardMedia
                                                    component="img"
                                                    height="180"
                                                    image={ImageNotAvailable}
                                                    style={{ objectFit: "fill" }}
                                                    alt="inventory contract picture"

                                                />
                                            }
                                            <CardContent>
                                                <Box display="flex" flexDirection="column" gap={2} >
                                                    <Box flex={1}>
                                                        <Typography variant="h6" align="left" color="text.primary" className="text-capital inventorycontract-title" >
                                                            {item.title}
                                                        </Typography>
                                                    </Box>
                                                    <Box flex={1}>
                                                        <Typography variant="subtitle1" align="left" color="text.primary" className='repair_contract-description'>
                                                            {item.description}
                                                        </Typography>
                                                    </Box>
                                                    <Box flex={1}>
                                                        <Typography variant="subtitle2" align="left" color="text.primary" className="text-capital">
                                                            Posted on: {moment(item.created).format("D-MMM-YYYY")}
                                                        </Typography>
                                                    </Box>
                                                    <Box flex={1}>
                                                        <Typography variant="body2" className="text-capital" color="text.primary">
                                                            <PlaceIcon color="primary" style={{ fontSize: fullScreen ? "20px" : "24px", verticalAlign: "middle", marginLeft: "-4px" }} />
                                                            {item.city && item.state && item.country ? `${item.city}, ${item.state}, ${item.country}` :
                                                                (item.city && item.state ? `${item.city}, ${item.state}` :
                                                                    (item.city && item.country ? `${item.city}, ${item.country}` :
                                                                        (item.state && item.country ? `${item.state}, ${item.country}` :
                                                                            item.city || item.state || item.country
                                                                        )
                                                                    )
                                                                )
                                                            }
                                                        </Typography>
                                                    </Box>

                                                </Box>
                                            </CardContent>
                                        </CardActionArea>

                                        <CardActions style={{ padding: "0px 8px 8px 8px" }}>
                                            <Box display="flex" flexDirection="column" gap={2}>
                                                <Box flex={1}>
                                                    <Button variant="contained" fullWidth className="inventorycontractcard-btn" color="primary"
                                                        onClick={() => handleShowInterested(item.id)}
                                                    >
                                                        Show interested
                                                    </Button>
                                                </Box>
                                                <Box flex={1} >
                                                    <Button variant="contained" fullWidth className="inventorycontractcard-btn" color="primary"
                                                        onClick={() => { handleEditContract(item.id) }}>
                                                        Edit
                                                    </Button>
                                                </Box>
                                                <Box flex={1}>
                                                    <Button variant="contained" fullWidth className="inventorycontractcard-btn repair_contract-delete-btn"
                                                        onClick={() => { handleRemoveContractDialogClickOpen(item.id) }}>
                                                        Delete
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </CardActions>

                                    </Card>
                                </Box>
                            );
                        })
                    ) : (
                        <Box display="flex" flexDirection="column"  style={{ marginTop: "10px" }}>
                            <Box flex={1}>
                                <Typography align="center" color="text.primary" variant="h6" gutterBottom>
                                    Your inventory is currently empty.
                                </Typography>
                            </Box>
                        </Box>
                    )

                    }

                </Box>
            )
                :
                (
                    <>
                        {typeContract && typeContract.length > 0 ? (
                            typeContract.map((item) => {
                                return (
                                    <div className="repair_contract-card" key={item.id} style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929" }}>

                                        {item.itemImages && item.itemImages[0] && item.itemImages[0].imageLink ?
                                            <div className='repair_contract-img-container'>
                                                <img src={item.itemImages[0].imageLink.href} alt="inventory contract picture" className='repair_contract-img' />
                                            </div>
                                            :
                                            <div className='repair_contract-img-container'>
                                                <img src={ImageNotAvailable} alt="inventory contract picture" className='repair_contract-img' />
                                            </div>
                                        }

                                        <div className="repair_contract-info">
                                            <Typography variant="h6" align="left" color="text.primary" className="text-capital inventorycontract-title " >
                                                {item.title}
                                            </Typography>
                                            <Typography variant="subtitle1" align="left" color="text.primary" className='repair_contract-description'>
                                                {item.description}
                                            </Typography>
                                            <Typography variant="subtitle2" align="left" color="text.primary" className="text-capital">
                                                Posted on: {moment(item.created).format("D-MMM-YYYY")}
                                            </Typography>
                                            <Typography variant="body2" className="text-capital" color="text.primary">
                                                <PlaceIcon color="primary" style={{ fontSize: fullScreen ? "20px" : "24px", verticalAlign: "middle", marginLeft: "-4px" }} />
                                                {item.city && item.state && item.country ? `${item.city}, ${item.state}, ${item.country}` :
                                                    (item.city && item.state ? `${item.city}, ${item.state}` :
                                                        (item.city && item.country ? `${item.city}, ${item.country}` :
                                                            (item.state && item.country ? `${item.state}, ${item.country}` :
                                                                item.city || item.state || item.country
                                                            )
                                                        )
                                                    )
                                                }
                                            </Typography>

                                            <div className='repair_contract-btn-container'>
                                                <Button variant="contained" className="repair_contract-button" color="primary" onClick={() => handleShowInterested(item.id)}>Show interested</Button>
                                                <Button variant="contained" className="repair_contract-button" color="primary" onClick={() => { handleEditContract(item.id) }}>Edit</Button>
                                                <Button variant="contained" className="repair_contract-button repair_contract-delete-btn" onClick={() => { handleRemoveContractDialogClickOpen(item.id) }}>Delete</Button>
                                            </div>

                                        </div>

                                    </div>
                                );
                            })
                        ) : (
                            <Box container style={{ marginTop: "10px" }}>
                                <Box flex={1}>
                                    <Typography align="center" color="text.primary" variant="h6" gutterBottom>
                                        Your inventory is currently empty.
                                    </Typography>
                                </Box>
                            </Box>
                        )
                        }
                    </>
                )
            }

        </>
    )
}

export default Contract