import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Typography,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  TextField,
  Paper,
  Button,
  Autocomplete,
  DialogTitle,
  DialogActions,
  Dialog,
  DialogContent,
  Container,
  CircularProgress,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import VerificationAlertDialog from "../../../utils/VerificationAlertDialog";
import { toast } from "react-toastify";
import axios from "axios";
import useGetData from "../../../API/useGetData";
import { useNavigate } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import { useAuth } from "../../../context/AuthContext";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import UrlRoute from "../../../API/UrlRoute";
import { fileCompressor } from "../../../components/FileCompressor";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import "./showcaseproduct.css";
import Constants from "../../../MeddyConnectConstant";
import MainLayout from "../../MainLayout";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const ShowCaseProduct = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { authToken, searchedUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
  const userId = searchedUser?.userId;
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [productLink, setProductLink] = useState("");
  const [specialityData, setSpecialityData] = useState([]);
  const [selectedPdfFiles, setSelectedPdfFiles] = useState([]);
  const [brandName, setBrandName] = useState(null);
  const [brandInputValue, setBrandInputValue] = useState("");
  const [brandOptions, setBrandOptions] = useState([]);
  const [showSelectedImages, setShowSelectedImages] = useState([]);
  const [compressedImages, setCompressedImages] = useState([]);
  const [specialityName, setSpecialityName] = useState(null);
  const [specialityInputValue, setSpecialityInputValue] = useState("");
  const [specialityOptions, setSpecialityOptions] = useState([]);
  const [subCategory, setSubCategory] = useState("");
  const [subCategoryChoices, setSubCategoryChoices] = useState("");
  const [category, setCategory] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [stateChoices, setStateChoices] = useState("");
  const [cityChoices, setCityChoices] = useState("");
  const [countryChoices, setCountryChoices] = useState("");
  const navigate = useNavigate();
  const [comment, setComment] = useState("");
  const { getProfession, getCountry, getStates, getCity } = useGetData();

  // Services
  // const { currentUser, getUserInfo } = useContext(YContext);
  const { getBrandNames, getSpecialities, getProductCategories } = useGetData();

  // Dialogs
  const [openBuyPackageDialog, setOpenBuyPackageDialog] = React.useState(false);
  const [openUpgradePackageDialog, setOpenUpgradePackageDialog] =
    React.useState(false);

  const [openAlertVerificationDialog, setOpenAlertVerificationDialog] =
    useState(false);

  const handleBuyPackageDialogClickOpen = () => {
    setOpenBuyPackageDialog(true);
  };

  const handleBuyPackageDialogClickClose = () => {
    setOpenBuyPackageDialog(false);
  };

  const handleCancel = () => {
    navigate(Constants.HOME_SCREEN);
  };

  const handleBuy = () => {
    navigate(Constants.SUBSCRIBE_PACKAGE_DETAILS);
  };

  const handleUpgradePackageDialogClickOpen = () => {
    setOpenUpgradePackageDialog(true);
  };

  const handleUpgradePackageDialogClickClose = () => {
    setOpenUpgradePackageDialog(false);
  };

  const handleAlertVerificationDialogClickOpen = () => {
    setOpenAlertVerificationDialog(true);
  };

  const handleAlertVerificationDialogClickClose = () => {
    setOpenAlertVerificationDialog(false);
  };


  useEffect(() => {
    getBrandNamesData("");
    getCategories();
  }, []);

  useEffect(() => {
    getSpecialitiesData("");
  }, [specialityData]);

  useEffect(() => {
    if (searchedUser.identityVerified === true) {
        getReferenceData();
    } else {
        handleAlertVerificationDialogClickOpen();
    }
}, [searchedUser.identityVerified]);

  const getBrandNamesData = async (brand) => {
    const brandOptions = await getBrandNames(brand);
    if (brandOptions) {
      setBrandName(null);
      setBrandOptions(brandOptions);
    }
  };

  const getReferenceData = async () => {
    const countryOptions = await getCountry();
    if (countryOptions) {
      setCountry("");
      setCountryChoices(countryOptions);
    }
  };

  const getSpecialitiesData = async (speciality) => {
    const specialityOptions = await getSpecialities(speciality);
    if (specialityOptions) {
      setSpecialityOptions(specialityOptions);
    }
  };

  const getCategories = async () => {
    const categoriesOptions = await getProductCategories();
    if (categoriesOptions) {
      setCategoryOptions(categoriesOptions);
    }
  };

  const handleAddSpecialities = (newSpeciality) => {
    if (
      newSpeciality &&
      newSpeciality.trim() !== "" &&
      !specialityData.some((item) => item.suitableFor === newSpeciality)
    ) {
      setSpecialityData((prevSpecialityData) => [
        ...prevSpecialityData,
        { suitableFor: newSpeciality },
      ]);
      setSpecialityInputValue("");
      setSpecialityName(null);
    }
  };

  const handleSpecialityDelete = (specialityname) => {
    setSpecialityData(
      specialityData.filter((chips) => chips.suitableFor !== specialityname)
    );
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
    handleCategoryWiseFilter(event.target.value);
    setSubCategory("");
  };

  const handleCategoryWiseFilter = (category) => {
    let selectedCategory = categoryOptions.filter(
      (item) => item.categoryLink?.rel === category
    );
    let selectedSubCategory = selectedCategory.map((item) => {
      return item.subcategoryLink;
    });
    setSubCategoryChoices(selectedSubCategory);
  };

  const handleSubCategoryChange = (event) => {
    setSubCategory(event.target.value);
  };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);
    const images = [];
    const pdfs = [];

    if (
      selectedFilesArray.length > 5 ||
      selectedFilesArray.some(
        (file) => file.type === "application/pdf" && file.size > 4 * 1024 * 1024
      )
    ) {
      if (selectedFilesArray.length > 5) {
        toast.error("You can not upload more than five files");
      } else {
        toast.error("PDF file size should be 4 MB or less");
      }
      return;
    }

    selectedFilesArray.forEach((file) => {
      if (file.type.startsWith("image/")) {
        images.push(file);
      } else if (file.type === "application/pdf") {
        pdfs.push(file);
      }
    });

    handleImageProcessing(images);
    setSelectedPdfFiles((prevPdfFiles) => [...prevPdfFiles, ...pdfs]);
  };

  const handleImageProcessing = async (images) => {
    setLoading(true);
    const compressedImagesArray = [];

    const showImages = images.map((file) => URL.createObjectURL(file));
    setShowSelectedImages((prevImages) => prevImages.concat(showImages));

    for (const image of images) {
      try {
        const compressedImage = await fileCompressor(image);
        const imageData = {
          type: image.type,
          compressedImage: compressedImage,
        };
        compressedImagesArray.push(imageData);
      } catch (error) {
        setLoading(false);
        console.error("Error compressing image:", error);
      }
    }

    setCompressedImages((prevCompressedImages) => [
      ...prevCompressedImages,
      ...compressedImagesArray,
    ]);
    setLoading(false);
  };

  const handleImageDelete = (id) => {
    const newImages = showSelectedImages.filter((photo, index) => index !== id);
    setShowSelectedImages(newImages);

    const fileCompressedArray = compressedImages.filter(
      (photo, index) => index !== id
    );
    setCompressedImages(fileCompressedArray);
  };

  const handlePdfFileDelete = (id) => {
    const pdfFiles = selectedPdfFiles.filter((photo, index) => index !== id);
    setSelectedPdfFiles(pdfFiles);
  };

  function base64ToBlob(base64String) {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray]);
  }

  const createNewProductLaunchData = async () => {
    try {
      setIsApiCallInProgress(true);
      setLoading(true);

      const apiUrl = `${process.env.REACT_APP_URL}${UrlRoute.SELL_URL}/${userId}`;

      const requestBody = {
        itemType: "NEW_LAUNCH",
        title: title,
        description: description,
        productLink: productLink,
        category: category,
        product: subCategory,
        brand: brandInputValue,
        itemSuitabilities: specialityData,
        country: "India",
        comment: comment
      };

      // Create the product launch
      const productLaunchResponse = await axios.post(apiUrl, requestBody, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      });

      if (productLaunchResponse.status === 200) {
        const productId = productLaunchResponse.data.id; // Assuming the response contains the created product ID
        toast.success("Product created successfully !!!");

        // Handle image uploads if any
        if (showSelectedImages.length !== 0) {
          const photos = new FormData();

          compressedImages.forEach((imageData, index) => {
            try {
              const blob = base64ToBlob(imageData.compressedImage);
              photos.append("images", blob, `image_${index}.${imageData.type}`);
            } catch (error) {
              console.error("Error converting image to blob:", error);
            }
          });

          const imageUploadResponse = await axios.post(
            `${process.env.REACT_APP_URL}${UrlRoute.SELL_URL}/${userId}/upload-image/${productId}`,
            photos,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );

          if (imageUploadResponse.status === 200) {
            toast.success("Product images uploaded successfully !!!");
          } else {
            toast.error(
              "Product created successfully, but there was an error uploading images. Please upload them again in Edit Product!"
            );
          }
        }

        // Handle PDF uploads if any
        if (selectedPdfFiles.length !== 0) {
          const formData = new FormData();

          for (let i = 0; i < selectedPdfFiles.length; i++) {
            formData.append("attachments", selectedPdfFiles[i]);
          }

          const attachmentUploadResponse = await axios.post(
            `${process.env.REACT_APP_URL}${UrlRoute.SELL_URL}/${userId}/upload-attachment/${productId}`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );

          if (attachmentUploadResponse.status === 200) {
            toast.success("Product PDF files uploaded successfully !!!");
          } else {
            toast.error(
              "Product created successfully, but there was an error uploading attachments. Please upload them again in Edit Product!"
            );
          }
        }

        navigate(Constants.HOME_PAGE);; // Redirect after success
      } else {
        throw new Error("Product creation failed");
      }
    } catch (error) {
      console.error("Error during product launch creation:", error);
      toast.error("Something went wrong!");
    } finally {
      setLoading(false);
      setIsApiCallInProgress(false);
    }
  };

  const validateForm = () => {
    let valid = true;

    if (title.trim() === "") {
      toast.error("Product Title cannot be empty");
      valid = false;
    }

    if (category.trim() === "") {
      toast.error("Product Category cannot be empty");
      valid = false;
    }

    if (subCategory.trim() === "") {
      toast.error("Product Name cannot be empty");
      valid = false;
    }

    if (brandInputValue.trim() === "") {
      toast.error("Brand cannot be empty");
      valid = false;
    }

    if (description.trim() === "") {
      toast.error("Description cannot be empty");
      valid = false;
    }

    if (productLink.trim() !== "") {
      // Check for a valid URL format
      const urlRegex =
        /^(http[s]?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}(\/\S*)?$/;

      if (!urlRegex.test(productLink)) {
        toast.error("Please enter a valid website link", {
          appearance: "error",
        });
        valid = false;
      }
    }

    if (showSelectedImages.length > 5) {
      toast.error("You can not upload more than five images", {
        appearance: "error",
      });
      valid = false;
    }

    if (selectedPdfFiles.length > 5) {
      toast.error("You can not upload more than five attachments", {
        appearance: "error",
      });
      valid = false;
    }

    if (comment === "") {
      toast.error("please add a comment");
      valid = false;
    }

    return valid;
  };

  const handleNewProductLaunchSubmit = () => {
    if (validateForm()) {
      createNewProductLaunchData();
    }
  };

  return (
    <>
      <MainLayout>
        <Container
          maxWidth="md"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 0.5,
            overflowY: "auto",
            width: "100%",
            p: 0,
          }}
        >
          {" "}
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Paper elevation={2} className="showcase-product-paper-container">
                <Box display="flex" flexDirection="column" gap={2}>
                  <Box flex={1}>
                    <TextField
                      fullWidth
                      label="Product Title"
                      name="producttitle"
                      required
                      value={title}
                      onChange={(event) => {
                        const inputValue = event.target.value;
                        const formattedValue = inputValue
                          .split(" ")
                          .map((word) => {
                            if (word === word.toUpperCase()) {
                              return word;
                            } else {
                              return (
                                word.charAt(0).toUpperCase() +
                                word.slice(1).toLowerCase()
                              );
                            }
                          })
                          .join(" ");

                        setTitle(formattedValue);
                      }}
                      autoComplete="off"
                      id="producttitle"
                      variant="outlined"
                    />
                  </Box>
                  <Box flex={1}>
                    <FormControl fullWidth>
                      <InputLabel
                        id="category-select-label"
                        htmlFor="category-picker"
                      >
                        Product Category
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="category-label"
                        id="category-picker"
                        label="Select Category"
                        value={category}
                        onChange={handleCategoryChange}
                      >
                        <MenuItem value={""}>Select Category</MenuItem>
                        {categoryOptions.map((choice, index) => {
                          return (
                            <MenuItem
                              value={choice.categoryLink?.rel}
                              key={index}
                            >
                              {choice.categoryLink?.rel}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box flex={1}>
                    <FormControl fullWidth>
                      <InputLabel id="subCategory-label">
                        Product Name
                      </InputLabel>
                      <Select
                        labelId="subCategory-label"
                        id="subCategory"
                        value={subCategory}
                        label="Product Name"
                        onChange={handleSubCategoryChange}
                        disabled={!category}
                      >
                        {subCategoryChoices &&
                          subCategoryChoices[0].map((option, index) => (
                            <MenuItem key={index} value={option.rel}>
                              {option.rel}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box flex={1}>
                    <Autocomplete
                      freeSolo
                      id="brandname"
                      disableClearable
                      options={brandOptions.map((option) => option)}
                      value={brandName}
                      onChange={(event, newValue) => {
                        setBrandName(newValue);
                      }}
                      inputValue={brandInputValue}
                      onInputChange={(event, newInputValue) => {
                        const capitalizedBrandInput = newInputValue
                          .toLowerCase()
                          .split(" ")
                          .map((word) => {
                            return word.charAt(0).toUpperCase() + word.slice(1);
                          })
                          .join(" ");

                        setBrandInputValue(capitalizedBrandInput);

                        if (
                          !brandOptions.find(
                            (option) => option === capitalizedBrandInput
                          )
                        ) {
                          getBrandNamesData(capitalizedBrandInput);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Brand Name"
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Box flex={1}>
                    <TextField
                      fullWidth
                      label="Product Description"
                      name="description"
                      value={description}
                      onChange={(event) =>
                        setDescription(
                          event.target.value
                            .toLowerCase()
                            .split(" ")
                            .map((word) => {
                              return (
                                word.charAt(0).toUpperCase() + word.slice(1)
                              );
                            })
                            .join(" ")
                        )
                      }
                      autoComplete="off"
                      multiline
                      minRows={3}
                      id="description"
                      variant="outlined"
                    />
                  </Box>
                  <Box flex={1}>
                    <TextField
                      fullWidth
                      label="Website Link"
                      name="websitelink"
                      required
                      value={productLink}
                      onChange={(event) => setProductLink(event.target.value)}
                      autoComplete="off"
                      id="websitelink"
                      variant="outlined"
                    />
                  </Box>
                  <Box flex={1}>
                    <Autocomplete
                      freeSolo
                      id="specialityname"
                      disableClearable
                      options={specialityOptions.map((option) => option)}
                      value={specialityName}
                      onChange={(event, newValue) => {
                        setSpecialityName(newValue);
                        if (
                          !specialityData.some(
                            (item) => item.suitableFor === newValue
                          )
                        ) {
                          handleAddSpecialities(newValue);
                        }
                      }}
                      inputValue={specialityInputValue}
                      onInputChange={(event, newInputValue) => {
                        const capitalizedInput = newInputValue
                          .toLowerCase()
                          .split(" ")
                          .map((word) => {
                            return word.charAt(0).toUpperCase() + word.slice(1);
                          })
                          .join(" ");

                        setSpecialityInputValue(capitalizedInput);

                        if (!specialityOptions.includes(capitalizedInput)) {
                          getSpecialitiesData(capitalizedInput);
                        }
                      }}
                      onBlur={() => {
                        if (
                          !specialityOptions.find(
                            (option) => option === specialityInputValue
                          )
                        ) {
                          handleAddSpecialities(specialityInputValue);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Suitable For"
                          slotProps={{
                            ...params.InputProps,
                            type: "search",
                          }}
                        />
                      )}
                    />
                  </Box>
                  {specialityData.length > 0 && (
                    <Box container direction="column">
                      <Box
                        container
                        direction="row"
                        style={{ padding: "10px", marginTop: "10px" }}
                      >
                        <Box>
                          <Paper
                            elevation={0}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              flexWrap: "wrap",
                              listStyle: "none",
                              backgroundColor: "transparent",
                              p: 0.5,
                              m: 0,
                            }}
                            component="ul"
                          >
                            {specialityData.map((data, index) => {
                              return (
                                <ListItem key={index}>
                                  <Chip
                                    variant="outlined"
                                    color="primary"
                                    style={{
                                      backgroundColor:
                                        theme.palette.mode === "light"
                                          ? "#fff"
                                          : "#292929",
                                      color:
                                        theme.palette.mode === "light"
                                          ? "#000"
                                          : "#fff",
                                    }}
                                    label={data.suitableFor}
                                    onDelete={() =>
                                      data.suitableFor === ""
                                        ? undefined
                                        : handleSpecialityDelete(
                                            data.suitableFor
                                          )
                                    }
                                  />
                                </ListItem>
                              );
                            })}
                          </Paper>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>

                {/* ############################  upload document section ######################################### */}

                <Box
                  flex={1}
                  style={{ marginTop: "20px", marginBottom: "20px" }}
                >
                  <Typography
                    color="text.primary"
                    gutterBottom
                    className="new-product-launch-label"
                  >
                    Attachments
                  </Typography>
                </Box>

                {(showSelectedImages.length < 5 ||
                  selectedPdfFiles.length < 5) && (
                  <Box
                    container
                    direction="column"
                    justifyContent="center"
                    align="center"
                    className="showcase-product-upload-Container"
                  >
                    <Box>
                      <IconButton component="label" style={{ padding: "0px" }}>
                        <input
                          hidden
                          accept="image/*,.pdf"
                          multiple
                          type="file"
                          onChange={handleFileChange}
                        />
                        <CloudUploadIcon
                          color="primary"
                          className="showcase-product-upload-Icon"
                        />
                      </IconButton>
                    </Box>
                    <Box item>
                      <Typography
                        variant="subtitle1"
                        color="text.primary"
                        align="center"
                        gutterBottom
                      >
                        Upload Documents
                      </Typography>
                    </Box>
                  </Box>
                )}

                <div className="contract-gallery">
                  {showSelectedImages.map((photo, index) => {
                    return (
                      <div key={photo} style={{ position: "relative" }}>
                        <img
                          src={photo}
                          alt="upload"
                          height="100%"
                          width="100%"
                        />
                        <CloseIcon
                          style={{
                            position: "absolute",
                            top: "6px",
                            right: "6px",
                            fontSize: fullScreen ? "18px" : "22px",
                            color: "red",
                            cursor: "pointer",
                          }}
                          onClick={() => handleImageDelete(index)}
                        />
                      </div>
                    );
                  })}
                </div>

                {selectedPdfFiles.map((item, index) => {
                  return (
                    <div
                      className="showcase-product-upload-fileInfo"
                      key={item.name}
                    >
                      <PictureAsPdfIcon className="showcase-product-upload-fileIcon" />
                      <Typography
                        variant="subtitle1"
                        className="showcase-product-file-name"
                      >
                        {item.name}
                      </Typography>
                      <ClearIcon
                        className="showcase-product-upload-fileIcon"
                        onClick={() => handlePdfFileDelete(index)}
                      />
                    </div>
                  );
                })}
                <Box>
                  <TextField
                    label="Add a comment"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    sx={{ margin: 0 }}
                  />
                </Box>
              </Paper>

              {!isApiCallInProgress && (
                <Box
                  container
                  direction="row"
                  style={{ padding: fullScreen ? "0px 6px" : "0px 10px" }}
                >
                  <Box flex={1}>
                    <Button
                      variant="contained"
                      fullWidth
                      className="showcase-product-button"
                      color="primary"
                      onClick={handleNewProductLaunchSubmit}
                    >
                      Showcase Product
                    </Button>
                  </Box>
                </Box>
              )}

              <div>
                <Dialog
                  open={openBuyPackageDialog}
                  // onClose={handleBuyPackageDialogClickClose}
                >
                  <DialogTitle>MeddyConnect</DialogTitle>
                  <DialogContent>
                    <Typography
                      variant="subtitle1"
                      color="text.primary"
                      align="left"
                    >
                      To showcase your product, you need to purchase one of our
                      packages.
                    </Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCancel} color="primary">
                      Cancel
                    </Button>
                    <Button onClick={handleBuy} color="primary">
                      Buy
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>

              <div>
                <Dialog
                  open={openUpgradePackageDialog}
                  // onClose={handleUpgradePackageDialogClickClose}
                >
                  <DialogTitle>MeddyConnect</DialogTitle>
                  <DialogContent>
                    <Typography
                      variant="subtitle1"
                      color="text.primary"
                      align="left"
                      className="text-capital"
                    >
                      You have exceeded your limit for showcasing products. To
                      showcase additional products, please upgrade your current
                      package.
                    </Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCancel} color="primary">
                      Cancel
                    </Button>
                    <Button onClick={handleBuy} color="primary">
                      Upgrade
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>

              {/* Verification Alert Dialog */}
              <VerificationAlertDialog
                openAlertVerificationDialog={openAlertVerificationDialog}
                handleAlertVerificationDialogClickClose={
                  handleAlertVerificationDialogClickClose
                }
                message="Currently, you cannot post a job because you are not a verified user."
              />
            </>
          )}
        </Container>
      </MainLayout>
    </>
  );
};

export default ShowCaseProduct;
