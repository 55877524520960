import React, { useState, useEffect } from "react";
import {
  Box,
  Select,
  FormLabel,
  Chip,
  TextField,
  Paper,
  Button,
  Autocomplete,
  Typography,
  FormControlLabel,
  InputLabel,
  MenuItem,
  FormControl,
  Radio,
  RadioGroup,
  IconButton,
  Container,
  CircularProgress,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import "./sellproductpage.css";
import { toast } from "react-toastify";
import useGetData from "../../../API/useGetData";
import { fileCompressor } from "../../../components/FileCompressor";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { DateField } from "@mui/x-date-pickers/DateField";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import Constants from "../../../MeddyConnectConstant";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import axios from "axios";
import MainLayout from "../../MainLayout";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const Updatesellproduct = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
  const location = useLocation();
  const [updateProductId, setUpdateProductId] = useState(location.state?.data);
  const [condition, setCondition] = React.useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [stateChoices, setStateChoices] = useState("");
  const [cityChoices, setCityChoices] = useState("");
  const [countryChoices, setCountryChoices] = useState("");
  const [value, setValue] = React.useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [numberOfItems, setNumberOfItems] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [subCategoryChoices, setSubCategoryChoices] = useState([]);
  const [category, setCategory] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [brandName, setBrandName] = useState(null);
  const [brandInputValue, setBrandInputValue] = useState("");
  const [brandOptions, setBrandOptions] = useState([]);
  const [specialityData, setSpecialityData] = useState([]);
  const [specialityName, setSpecialityName] = useState(null);
  const [specialityInputValue, setSpecialityInputValue] = useState("");
  const [specialityOptions, setSpecialityOptions] = useState([]);
  const navigate = useNavigate();
  const [manufacturingYear, setManufacturingYear] = useState(new Date());
  const [price, setPrice] = useState("");
  const [pincodes, setPincodes] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const [compressedImages, setCompressedImages] = useState([]);
  const [selectedImagesCount, setSelectedImagesCount] = useState(0);
  const [showUploadContainer, setShowUploadContainer] = useState(true);
  const { authToken, searchedUser } = useAuth();
  const [comment, setComment] = useState("");
  const userId = searchedUser?.userId;
  // Services

  const {
    getBrandNames,
    getSpecialities,
    getCountry,
    getStates,
    getCity,
    getProductCategories,
  } = useGetData();

  const handleChange = (event) => {
    setCondition(event.target.value);
  };

  const handleRadioButtonChange = (event) => {
    setValue(event.target.value);
  };

  const currentYear = new Date().getFullYear();


  useEffect(() => {
    getReferenceData();
    getCategories();
    getBrandNamesData("");
    getSellProduct(updateProductId);
  }, []);

  useEffect(() => {
    getSpecialitiesData("");
  }, [specialityData]);

  // for getting ProductId

  useEffect(() => {
    if (category) {
      handleCategoryWiseFilter(category);
    }
  }, [category, categoryOptions]);

  useEffect(() => {
    // Show the container if either the image count or the PDF count is less than 5
    if (selectedImagesCount < 5) {
      setShowUploadContainer(true);
    } else {
      setShowUploadContainer(false);
    }
  }, [selectedImagesCount]);

  const getReferenceData = async () => {
    const countryOptions = await getCountry();
    if (countryOptions) {
      //   setCountry("");
      setCountryChoices(countryOptions);
    }
  };

  const getStatesData = async (country) => {
    if (country && country !== "") {
      let stateOptions = [];
      stateOptions = await getStates(country);
      if (stateOptions) {
        // setState("");
        setStateChoices(stateOptions);
      }
    }
  };

  const getCityData = async (country, state) => {
    if (country && country !== "" && state && state !== "") {
      let cityOptions = [];
      cityOptions = await getCity(country, state);
      if (cityOptions) {
        // setCity("");
        setCityChoices(cityOptions);
      }
    }
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
    handleCategoryWiseFilter(event.target.value);
  };

  const handleCategoryWiseFilter = (category) => {
    let selectedCategory = categoryOptions.find(
      (item) => item.categoryLink?.rel === category
    );
    let selectedSubCategory = selectedCategory
      ? selectedCategory.subcategoryLink
      : [];
    setSubCategoryChoices(selectedSubCategory);
  };

  const handleSubCategoryChange = (event) => {
    setSubCategory(event.target.value);
  };

  const getCategories = async () => {
    const categoriesOptions = await getProductCategories();
    if (categoriesOptions) {
      setCategoryOptions(categoriesOptions);
    }
  };

  const getBrandNamesData = async (brand) => {
    const brandOptions = await getBrandNames(brand);
    if (brandOptions) {
      setBrandName(null);
      setBrandOptions(brandOptions);
    }
  };

  const getSpecialitiesData = async (speciality) => {
    const specialityOptions = await getSpecialities(speciality);
    if (specialityOptions) {
      setSpecialityOptions(specialityOptions);
    }
  };

  const handleAddSpecialities = (newSpeciality) => {
    if (
      newSpeciality &&
      newSpeciality.trim() !== "" &&
      !specialityData.some((item) => item.suitableFor === newSpeciality)
    ) {
      setSpecialityData((prevSpecialityData) => [
        ...prevSpecialityData,
        { suitableFor: newSpeciality },
      ]);
      setSpecialityInputValue("");
      setSpecialityName(null);
    }
  };

  const handleSpecialityDelete = (specialityname) => {
    setSpecialityData(
      specialityData.filter((chips) => chips.suitableFor !== specialityname)
    );
  };
  const getSellProduct = async (updateProductId) => {
    const apiUrl = `${process.env.REACT_APP_URL}items/${userId}/${updateProductId}`;
    setLoading(true);

    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      console.log(response.data);
      setValue(response.data.isUsed);
      setTitle(response.data.title);
      setCategory(response.data.category);
      setSubCategory(response.data.product);
      setBrandInputValue(response.data.brand);
      setDescription(response.data.description);
      setPrice(response.data.price);
      setManufacturingYear(
        response.data.manufacturingYear
          ? new Date(response.data.manufacturingYear, 1, 1)
          : null
      );
      setCondition(response.data.workingCondition);
      setNumberOfItems(
        response.data.noOfItems !== null ? String(response.data.noOfItems) : ""
      );
      setSpecialityData(response.data.itemSuitabilities);
      setSelectedImages(response.data.itemImages);
      setPincodes(response.data.pinCode);
      setCountry(response.data.country);
      getStatesData(response.data.country);
      setState(response.data.state);
      getCityData(response.data.country, response.data.state);
      setCity(response.data.city);
      setComment(response.data.comment);
      setLoading(false);
    } else {
      toast.error("Something went wrong!");
      setLoading(false);
    }
  };

  const handleImageChange = async (e) => {
    setLoading(true);
    const files = e.target.files;
    const selectedImagesArray = Array.from(files);
    const compressedImagesArray = [];

    if (selectedImagesArray.length > 5) {
      setLoading(false);
      toast.error("You can not upload more than five images");
      return;
    }

    const showImages = selectedImagesArray.map((file) =>
      URL.createObjectURL(file)
    );
    setSelectedImages((prevImages) => prevImages.concat(showImages));

    setSelectedImagesCount(
      (prevCount) => prevCount + selectedImagesArray.length
    );

    for (const image of selectedImagesArray) {
      try {
        const compressedImage = await fileCompressor(image);
        const imageData = {
          type: image.type,
          compressedImage: compressedImage,
        };
        compressedImagesArray.push(imageData);
      } catch (error) {
        setLoading(false);
        console.error("Error compressing image:", error);
      }
    }

    setCompressedImages((prevCompressedImages) => [
      ...prevCompressedImages,
      ...compressedImagesArray,
    ]);
    setLoading(false);
  };

  const handleImageDelete = (id) => {
    const newImages = selectedImages.filter((photo, index) => index !== id);
    setSelectedImages(newImages);

    const fileCompressedArray = compressedImages.filter(
      (photo, index) => index !== id
    );
    setCompressedImages(fileCompressedArray);

    // Update the image count after deleting a file
    setSelectedImagesCount((prevCount) => prevCount - 1);
  };

  const handleDeleteImageFromApi = async (productId, imgId) => {
    setLoading(true);

    const apiUrl = `${process.env.REACT_APP_URL}items/image/${userId}/${productId}/${imgId}`;

    const response = await axios.delete(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      const newImages = selectedImages.filter(
        (element, index) => element.id !== imgId
      );
      setSelectedImages(newImages);
      setLoading(false);
    } else {
      setLoading(false);
      toast.error("Something went wrong!");
    }
  };

  // Function to handle delete action
  const handleDelete = (item, index, updateProductId) => {
    if (item.id) {
      handleDeleteImageFromApi(updateProductId, item.id);
    } else {
      handleImageDelete(index);
    }
  };

  function base64ToBlob(base64String) {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray]);
  }

  const updateSellProduct = async (productId) => {
    setIsApiCallInProgress(true);
    setLoading(true);

    const manufacturingYearInNumber = manufacturingYear
      ? manufacturingYear.getFullYear()
      : null;

    const apiUrl = `${process.env.REACT_APP_URL}items/${userId}/${productId}`;

    const requestBody = {
      id: productId,
      itemType: "PRODUCT",
      isUsed: value,
      title: title,
      category: category,
      product: subCategory,
      brand: brandInputValue,
      description: description,
      price: price,
      manufacturingYear: manufacturingYearInNumber,
      workingCondition: condition,
      noOfItems: numberOfItems,
      itemSuitabilities: specialityData,
      pinCode: pincodes,
      country: country,
      state: state,
      city: city,
      comment: comment,
    };
    const response = await axios.put(apiUrl, requestBody, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      toast.success("Product updated successfully !!!");
      if (compressedImages.length !== 0) {
        const photos = new FormData();
        // Iterate over the compressedImages array
        compressedImages.forEach((imageData, index) => {
          try {
            const blob = base64ToBlob(imageData.compressedImage); // Convert base64 string to blob

            // Append the blob with the correct MIME type and filename
            photos.append(
              "images",
              blob,
              `image_${index}.${imageData.type}`,
              `image/${imageData.type}`
            );
          } catch (error) {
            console.error("Error converting image:", error);
          }
        });

        const uploadImageurl = `${process.env.REACT_APP_URL}items/upload-image/${userId}/${productId}`;
        const response = await axios.post(uploadImageurl, photos, {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.status === 200) {
          setLoading(false);
          setIsApiCallInProgress(false);
          toast.success("Product images updated successfully !!!");
          navigate(Constants.INVENTORY);
        } else {
          setLoading(false);
          setIsApiCallInProgress(false);
          toast.error(
            "Product Updated Successfully, but there's an error uploading images, Please upload again in Edit Product.!"
          );
          navigate(Constants.INVENTORY);
        }
      } else {
        setLoading(false);
        setIsApiCallInProgress(false);
        navigate(Constants.INVENTORY);
      }
    } else {
      setLoading(false);
      setIsApiCallInProgress(false);
      toast.error("Something went wrong!");
    }
  };

  const handleUpdateProductSubmit = () => {
    if (validateUpdateProductForm()) {
      updateSellProduct(updateProductId);
    }
  };

  const validateUpdateProductForm = () => {
    let valid = true;

    if (title.trim() === "") {
      toast.error("Product Title cannot be empty");
      valid = false;
    }

    if (brandInputValue.trim() === "") {
      toast.error("Brand cannot be empty");
      valid = false;
    }

    if (description.trim() === "") {
      toast.error("Description cannot be empty");
      valid = false;
    }

    if (String(price).trim() === "") {
      toast.error("Price cannot be empty");
      valid = false;
    } else if (!/^[0-9]*$/.test(price)) {
      toast.error("Price must contain only numbers");
      valid = false;
    }

    if (category.trim() === "") {
      toast.error("Product Category cannot be empty");
      valid = false;
    }

    if (subCategory.trim() === "") {
      toast.error("Product Name cannot be empty");
      valid = false;
    }

    if (!condition) {
      toast.error("Please select the working condition");
      valid = false;
    }

    if (numberOfItems.trim() !== "") {
      if (!/^[0-9]*$/.test(numberOfItems)) {
        toast.error("Number of Items must contain only numbers");
        valid = false;
      }
    }

    if (country.trim() === "") {
      toast.error("Country cannot be empty");
      valid = false;
    }

    if (compressedImages.length > 5) {
      toast.error("You can not upload more than five images");
      valid = false;
    }

    if (comment.trim() === "") {
      toast.error("Comment cannot be empty");
      valid = false;
    }

    return valid;
  };

  return (
    <>
      <MainLayout>
        <Container
          maxWidth="md"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 0.5,
            overflowY: "auto",
            width: "100%",
            p: 0,
          }}
        >
          {" "}
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Paper elevation={2} className="sellproduct-paper-container">
                <Box display="flex" flexDirection="column" gap={2}>
                  <Box flex={1}>
                    <FormControl>
                      <FormLabel
                        id="sell-product-radio-buttons-group"
                        style={{
                          color:
                            theme.palette.mode === "light" ? "#000000" : "#fff",
                        }}
                        className="sell-product-label"
                      >
                        Product is ?
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="sell-product-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={value}
                        onChange={handleRadioButtonChange}
                      >
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="New"
                          disabled
                        />
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="Used"
                          disabled
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box flex={1}>
                    <TextField
                      fullWidth
                      required
                      label="Product Title"
                      name="title"
                      value={title}
                      onChange={(event) => {
                        const inputValue = event.target.value;
                        const formattedValue = inputValue
                          .split(" ")
                          .map((word) => {
                            if (word === word.toUpperCase()) {
                              return word;
                            } else {
                              return (
                                word.charAt(0).toUpperCase() +
                                word.slice(1).toLowerCase()
                              );
                            }
                          })
                          .join(" ");

                        setTitle(formattedValue);
                      }}
                      autoComplete="off"
                      id="title"
                      variant="outlined"
                    />
                  </Box>
                  <Box flex>
                    <FormControl fullWidth>
                      <InputLabel
                        id="category-select-label"
                        htmlFor="category-picker"
                      >
                        Product Category
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="category-label"
                        id="category-picker"
                        label="Select Category"
                        value={category}
                        onChange={handleCategoryChange}
                      >
                        <MenuItem value={""}>Select Category</MenuItem>
                        {categoryOptions.map((choice, index) => {
                          return (
                            <MenuItem
                              value={choice.categoryLink?.rel}
                              key={index}
                            >
                              {choice.categoryLink?.rel}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box flex={1}>
                    <FormControl fullWidth>
                      <InputLabel id="subCategory-label">
                        Product Name
                      </InputLabel>
                      <Select
                        labelId="subCategory-label"
                        id="subCategory"
                        value={subCategory}
                        label="Product Name"
                        onChange={handleSubCategoryChange}
                        disabled={!category}
                      >
                        {subCategoryChoices &&
                          subCategoryChoices.map((option, index) => (
                            <MenuItem key={index} value={option.rel}>
                              {option.rel}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box flex={1}>
                    <Autocomplete
                      freeSolo
                      id="brandname"
                      disableClearable
                      options={brandOptions.map((option) => option)}
                      value={brandName}
                      onChange={(event, newValue) => {
                        setBrandName(newValue);
                      }}
                      inputValue={brandInputValue}
                      onInputChange={(event, newInputValue) => {
                        const capitalizedBrandInput = newInputValue
                          .toLowerCase()
                          .split(" ")
                          .map((word) => {
                            return word.charAt(0).toUpperCase() + word.slice(1);
                          })
                          .join(" ");

                        setBrandInputValue(capitalizedBrandInput);

                        if (
                          !brandOptions.find(
                            (option) => option === capitalizedBrandInput
                          )
                        ) {
                          getBrandNamesData(capitalizedBrandInput);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Brand Name"
                          slotProps={{
                            input: {
                              ...params.InputProps,
                              type: "search",
                            },
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Box flex={1}>
                    <TextField
                      fullWidth
                      label="Product Description"
                      name="description"
                      value={description}
                      onChange={(event) =>
                        setDescription(
                          event.target.value
                            .toLowerCase()
                            .split(" ")
                            .map((word) => {
                              return (
                                word.charAt(0).toUpperCase() + word.slice(1)
                              );
                            })
                            .join(" ")
                        )
                      }
                      autoComplete="off"
                      multiline
                      minRows={3}
                      id="description"
                      variant="outlined"
                    />
                  </Box>
                  <Box flex={1}>
                    <TextField
                      fullWidth
                      label="Price"
                      name="price"
                      type="tel"
                      value={price}
                      onChange={(event) => setPrice(event.target.value)}
                      autoComplete="off"
                      id="price"
                      variant="outlined"
                    />
                  </Box>
                  <Box flex={1}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          sx={{ width: "100% " }}
                          label="Manufacturing Year"
                          openTo="year"
                          views={["year"]}
                          value={manufacturingYear}
                          onChange={(newValue) =>
                            setManufacturingYear(newValue)
                          }
                          disableFuture
                          minDate={new Date(currentYear - 60, 0, 1)} // Previous year
                          maxDate={new Date(currentYear, 11, 31)} // Current year
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Box>
                  <Box flex={1}>
                    <TextField
                      label="Working Condition"
                      select
                      value={condition}
                      onChange={handleChange}
                      fullWidth
                    >
                      <MenuItem value="WORKING">Working</MenuItem>
                      <MenuItem value="NON_WORKING">Not Working</MenuItem>
                      <MenuItem value="PARTIALLY_WORKING">
                        Partially Working
                      </MenuItem>
                    </TextField>
                  </Box>
                  <Box flex={1}>
                    <TextField
                      fullWidth
                      label="No of Items"
                      name="items"
                      value={numberOfItems}
                      onChange={(event) => setNumberOfItems(event.target.value)}
                      autoComplete="off"
                      id="items"
                      variant="outlined"
                    />
                  </Box>
                  <Box flex={1}>
                    <Autocomplete
                      freeSolo
                      id="specialityname"
                      disableClearable
                      options={specialityOptions.map((option) => option)}
                      value={specialityName}
                      onChange={(event, newValue) => {
                        setSpecialityName(newValue);
                        if (
                          !specialityData.some(
                            (item) => item.suitableFor === newValue
                          )
                        ) {
                          handleAddSpecialities(newValue);
                        }
                      }}
                      inputValue={specialityInputValue}
                      onInputChange={(event, newInputValue) => {
                        const capitalizedInput = newInputValue
                          .toLowerCase()
                          .split(" ")
                          .map((word) => {
                            return word.charAt(0).toUpperCase() + word.slice(1);
                          })
                          .join(" ");

                        setSpecialityInputValue(capitalizedInput);

                        if (!specialityOptions.includes(capitalizedInput)) {
                          getSpecialitiesData(capitalizedInput);
                        }
                      }}
                      onBlur={() => {
                        if (
                          !specialityOptions.find(
                            (option) => option === specialityInputValue
                          )
                        ) {
                          handleAddSpecialities(specialityInputValue);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Suitable For"
                          slotProps={{
                            ...params.InputProps,
                            type: "search",
                          }}
                        />
                      )}
                    />
                  </Box>
                  {specialityData.length > 0 && (
                    <Box container direction="column">
                      <Box
                        container
                        direction="row"
                        style={{ padding: "10px", marginTop: "10px" }}
                      >
                        <Box item>
                          <Paper
                            elevation={0}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              flexWrap: "wrap",
                              listStyle: "none",
                              backgroundColor: "transparent",
                              p: 0.5,
                              m: 0,
                            }}
                            component="ul"
                          >
                            {specialityData.map((data, index) => {
                              return (
                                <ListItem key={index}>
                                  <Chip
                                    variant="outlined"
                                    color="primary"
                                    style={{
                                      backgroundColor:
                                        theme.palette.mode === "light"
                                          ? "#fff"
                                          : "#292929",
                                      color:
                                        theme.palette.mode === "light"
                                          ? "#000"
                                          : "#fff",
                                    }}
                                    label={data.suitableFor}
                                    onDelete={() =>
                                      data.suitableFor === ""
                                        ? undefined
                                        : handleSpecialityDelete(
                                            data.suitableFor
                                          )
                                    }
                                  />
                                </ListItem>
                              );
                            })}
                          </Paper>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>

                {/* ############################  product upload images section ######################################### */}

                <Box flex={1} style={{ marginTop: "10px" }}>
                  <Typography
                    color="text.primary"
                    gutterBottom
                    className="sell-product-label"
                  >
                    Product Images
                  </Typography>
                </Box>

                {showUploadContainer && (
                  <Box
                    container
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    className="sell-product-upload-container"
                  >
                    <Box flex={1}>
                      <IconButton component="label" style={{ padding: "0px" }}>
                        <input
                          hidden
                          accept="image/*"
                          multiple
                          type="file"
                          onChange={handleImageChange}
                        />
                        <CloudUploadIcon
                          color="primary"
                          className="sell-product-upload-Icon"
                        />
                      </IconButton>
                    </Box>
                    <Box flex={1}>
                      <Typography
                        variant="subtitle1"
                        color="text.primary"
                        align="center"
                        gutterBottom
                      >
                        Upload Images
                      </Typography>
                    </Box>
                  </Box>
                )}

                <div className="gallery">
                  {selectedImages.map((item, index) => {
                    return (
                      <div key={index} style={{ position: "relative" }}>
                        <img
                          src={item.imageLink ? item.imageLink?.href : item}
                          alt="upload"
                          height="100%"
                          width="100%"
                        />
                        <CloseIcon
                          style={{
                            position: "absolute",
                            top: "6px",
                            right: "6px",
                            fontSize: fullScreen ? "18px" : "22px",
                            color: "red",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            handleDelete(item, index, updateProductId)
                          }
                        />
                      </div>
                    );
                  })}
                </div>

                <Box
                  display="flex"
                  flexDirection="column"
                  gap={2}
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  <Box flex={1}>
                    <Typography
                      align="left"
                      color="text.primary"
                      className="sell-product-label"
                      style={{ marginTop: "10px", marginBottom: "-6px" }}
                    >
                      Can be sold at
                    </Typography>
                  </Box>
                  <Box flex={1}>
                    <FormControl fullWidth>
                      <InputLabel
                        id="country-select-label"
                        htmlFor="country-picker"
                      >
                        Country
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="country-label"
                        id="country-picker"
                        label="Select Country"
                        value={country}
                        onChange={(event) => {
                          setCountry(event.target.value);
                          setStateChoices([]);
                          setCityChoices([]);
                          getStatesData(event.target.value);
                        }}
                      >
                        <MenuItem value={""} selected>
                          Select Country
                        </MenuItem>
                        {Array.isArray(countryChoices) &&
                          countryChoices.map((choice, index) => {
                            return (
                              <MenuItem value={choice} key={index}>
                                {choice}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box flex={1}>
                    <FormControl fullWidth>
                      <InputLabel
                        id="state-select-label"
                        htmlFor="state-picker"
                      >
                        State
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="state-label"
                        id="state-picker"
                        label="Select State"
                        value={state}
                        onChange={(event) => {
                          setState(event.target.value);
                          setCityChoices([]);
                          getCityData(country, event.target.value);
                        }}
                      >
                        <MenuItem value={""}>Select State</MenuItem>
                        {Array.isArray(stateChoices) &&
                          stateChoices.map((choice, index) => {
                            return (
                              <MenuItem value={choice} key={index}>
                                {choice}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box flex={1}>
                    <FormControl fullWidth>
                      <InputLabel
                        id="demo-dialog-select-label"
                        htmlFor="city-picker"
                      >
                        City
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="city-label"
                        id="city-picker"
                        label="Select City"
                        value={city}
                        onChange={(event) => setCity(event.target.value)}
                      >
                        <MenuItem value={""}>Select City</MenuItem>
                        {Array.isArray(cityChoices) &&
                          cityChoices.map((choice, index) => {
                            return (
                              <MenuItem value={choice} key={index}>
                                {choice}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box flex={1}>
                    <TextField
                      fullWidth
                      label="Pincode"
                      name="pincode"
                      value={pincodes}
                      onChange={(event) => setPincodes(event.target.value)}
                      autoComplete="off"
                      id="pincode"
                      variant="outlined"
                    />
                  </Box>
                  <Box flex={1}>
                    <TextField
                      label="Add a comment"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      sx={{ margin: 0 }}
                    />
                  </Box>
                </Box>
              </Paper>

              {!isApiCallInProgress && (
                <Box display="flex" flexDirection="row" justifyContent="center">
                  <Box flex={1}>
                    <Button
                      variant="contained"
                      fullWidth
                      className="sellproduct-button"
                      color="primary"
                      onClick={handleUpdateProductSubmit}
                    >
                      Update Product
                    </Button>
                  </Box>
                </Box>
              )}
            </>
          )}
        </Container>
      </MainLayout>
    </>
  );
};

export default Updatesellproduct;
