import React, { useState, useEffect } from 'react';
import { Typography, Container, Box, Button, CircularProgress, Card, CardContent, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Slide, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import UrlRoute from "../../../API/UrlRoute";
import PlaceIcon from '@mui/icons-material/Place';
import MainLayout from "../../MainLayout";
import { useAuth } from '../../../context/AuthContext';
import { toast } from 'react-toastify';
import axios from 'axios';
import { format, parseISO } from 'date-fns';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ManageJobs = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { authToken, searchedUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const userId = searchedUser?.userId;

  const [postedApplicationsByUser, setPostedApplicationsByUser] = useState([]);
  const [comment, setComment] = useState('');
  const [selectedJobId, setSelectedJobId] = useState(null);

  //Dialogs
  const [openJobDeleteAlertDialog, setOpenJobDeleteAlertDialog] = useState(false);

  const handleJobDeleteAlertDialogClickOpen = (id) => {
    setOpenJobDeleteAlertDialog(true);
    setSelectedJobId(id);
  };

  const handleJobDeleteAlertDialogClickClose = () => {
    setOpenJobDeleteAlertDialog(false);
    setSelectedJobId(null);
    setComment('');
  };

  const getPostedApplicationsByUser = async () => {
    setLoading(true);

    try {

      const apiUrl = `${process.env.REACT_APP_URL}${UrlRoute.GET_POSTED_JOBS_BY_USER_URL}/${userId}`;

      const response = await axios.get(apiUrl, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${authToken}`
        }
      });

      setPostedApplicationsByUser(response.data);
      toast.success("Posted jobs fetched successfully..");

    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/');
      } else {
        toast.error("Error fetching posted jobs:");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      getPostedApplicationsByUser();
    }
  }, [userId]);

  const handleEditClick = (id) => {
    navigate(`/editpostajob/${id}`);
  };

  const handleDeleteJob = async (jobId, comment) => {

    if (!comment.trim()) {
      toast.error("Comment is required to proceed with deletion.!");
      return;
    }

    setLoading(true);

    try {

      const apiUrl = `${process.env.REACT_APP_URL}${UrlRoute.JOBS_URL}/${userId}/${jobId}`;

      const response = await axios.delete(apiUrl, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
        data: { comment: comment },
      });

      if (response.data.response === true) {
        toast.success("Job deleted successfully!");
        setOpenJobDeleteAlertDialog(false);
        setSelectedJobId(null);
        setComment('');
        getPostedApplicationsByUser();
      } else {
        toast.error("Something went wrong!");
      }

    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/');
      } else {
        toast.error("An error occurred while deleting the job");
      }
    } finally {
      setLoading(false);
    }
  };


  return (
    <>
      <MainLayout>
        <Container
          maxWidth="md"
          disableGutters
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 0.5,
            overflowY: 'auto',
            width: '100%',
            p: 1
          }}
        >
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
                {postedApplicationsByUser.length === 0 ? (
                  <Typography variant="body1" align="center" sx={{ mt: 2 }}>
                    No posted jobs available.
                  </Typography>
                ) : (
                  postedApplicationsByUser.map((item) => (
                    <Card sx={{ width: '100%', boxShadow: 6 }} key={item.id}>
                      <CardContent>
                        <Box sx={{ flex: 1 }}>
                          <Typography variant="h6" component="div">
                            {item.title}
                          </Typography>
                          <Typography variant="body1">
                            {item.created ? format(parseISO(item.created), "MMM d, yyyy") : ""}
                          </Typography>
                          <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                            <PlaceIcon color="primary" />
                            <Typography variant="body1">
                              {item.city && item.state && item.country ? `${item.city}, ${item.state}, ${item.country}` : item.city || item.state || item.country}
                            </Typography>
                          </Box>
                        </Box>

                        {item.skills.length > 0 && (
                          <Box
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              margin: '5px 0'
                            }}
                          >
                            {item.skills.map((value, index) => (
                              <Box
                                key={index}
                                sx={{
                                  margin: '0 6px 6px 0',
                                }}
                              >
                                <Chip
                                  label={value}
                                  variant="outlined"
                                  sx={{
                                    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929",
                                    color: theme.palette.mode === "light" ? "#000" : "#fff",
                                  }}
                                />
                              </Box>
                            ))}
                          </Box>
                        )}

                        <Box sx={{ flex: 1 }}>
                          <Typography
                            variant="body2"
                            sx={{
                              display: '-webkit-box',
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: 'vertical',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}>
                            {item.description}
                          </Typography>
                        </Box>
                      </CardContent>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          padding: 2,
                        }}
                      >
                        <Button
                          variant="contained"
                          size="medium"
                          onClick={() => handleEditClick(item.id)}
                        >
                          Edit
                        </Button>

                        <Button
                          variant="contained"
                          size="medium"
                          onClick={() => handleJobDeleteAlertDialogClickOpen(item.id)}
                          sx={{ marginLeft: 1, backgroundColor: 'red' }}
                        >
                          Delete
                        </Button>
                      </Box>
                    </Card>
                  ))
                )}
              </Box>

              <Dialog
                open={openJobDeleteAlertDialog}
                onClose={handleJobDeleteAlertDialogClickClose}
                TransitionComponent={Transition}
              >
                <DialogTitle>MeddyConnect</DialogTitle>
                <DialogContent>
                  <Typography variant="subtitle1" color="text.primary" align="left" gutterBottom>
                    Are you sure you want to delete this job? Please provide a reason for deletion.
                  </Typography>
                  <Box>
                    <TextField
                      label="Add a comment"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      multiline
                      minRows={3}
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      sx={{ margin: 0 }}
                    />
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleJobDeleteAlertDialogClickClose} color="primary" >Cancel</Button>
                  <Button onClick={() => handleDeleteJob(selectedJobId, comment)} color="primary" >Delete</Button>
                </DialogActions>
              </Dialog>

            </>
          )}
        </Container>
      </MainLayout>
    </>
  )
}

export default ManageJobs