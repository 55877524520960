import React, { useState, useEffect } from 'react';
import {
    Typography, Container, Box, Button, CircularProgress, Card, CardContent, MenuItem, Select,
    FormControl, Dialog, DialogActions, DialogContent, DialogTitle, Slide, TextField
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import UrlRoute from "../../API/UrlRoute";
import MainLayout from "../MainLayout";
import { useAuth } from '../../context/AuthContext';
import { toast } from 'react-toastify';
import axios from 'axios';
import { format, parseISO } from 'date-fns';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const UserPackageDetails = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { authToken, searchedUser } = useAuth();
    const [loading, setLoading] = useState(false);
    const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
    const [activePackage, setActivePackage] = useState(null);
    const [packages, setPackages] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState('');
    const [comment, setComment] = useState('');
    const [dialogAction, setDialogAction] = useState(null);

    const userId = searchedUser?.userId;

    //Dialogs
    const [openPackageAlertDialog, setOpenPackageAlertDialog] = useState(false);

    useEffect(() => {
        if (userId) {
            getUserActivePackageDetails();
            getAllPackageDetails();
        }
    }, [])

    const getUserActivePackageDetails = async () => {
        setLoading(true);

        try {

            const apiUrl = `${process.env.REACT_APP_URL}${UrlRoute.GET_ORDER_URL}/${userId}/${UrlRoute.GET_ACTIVE_PACKAGE_URL}`;

            const response = await axios.get(apiUrl, {
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${authToken}`
                }
            });

            setActivePackage(response.data);
            toast.success("Active package details fetched successfully.");

        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/');
            } else {
                toast.error("Error fetching active package details.");
            }
        } finally {
            setLoading(false);
        }
    };

    const getAllPackageDetails = async () => {
        setLoading(true);

        try {

            const apiUrl = `${process.env.REACT_APP_URL}/${UrlRoute.GET_ALL_PACKAGES_URL}`;

            const response = await axios.get(apiUrl, {
                headers: {
                    "Content-type": "application/json",
                    // Authorization: `Bearer ${authToken}`
                }
            });

            setPackages(response.data.packages);
            toast.success("All package details fetched successfully.");

        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/');
            } else {
                toast.error("Error fetching all package details.");
            }
        } finally {
            setLoading(false);
        }
    };

    const handlePackageAlertDialogClickOpen = (action) => {
        setDialogAction(action);
        setOpenPackageAlertDialog(true);
    };

    const handlePackageAlertDialogClickClose = () => {
        setDialogAction(null);
        setOpenPackageAlertDialog(false);
        setComment('');
        setSelectedPackage('');
    };

    const handlePackageSelection = (event) => {
        setSelectedPackage(event.target.value);
    };

    const handleSubmit = async () => {
        if (dialogAction === 'delete') {
            // Delete API call
            await handleDeletePackage();
        } else if (dialogAction === 'add' || dialogAction === 'change') {
            // Add or Change API call
            await handleAddOrChangePackage();
        }
    };

    const handleAddOrChangePackage = async () => {

        if (!selectedPackage || !comment.trim()) {
            toast.error("Please select a package and provide a valid comment.");
            return;
        }

        setIsApiCallInProgress(true);
        setLoading(true);

        try {

            const apiUrl = `${process.env.REACT_APP_URL}${UrlRoute.GET_ORDER_URL}/${userId}/${UrlRoute.CHANGE_PACKAGE_URL}`;

            const requestBody = {
                packageCode: selectedPackage,
                comment: comment,
            };

            const response = await axios.put(apiUrl, requestBody, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                toast.success(dialogAction === 'add' ? "Package added successfully." : "Package changed successfully.");
                handlePackageAlertDialogClickClose();
                getUserActivePackageDetails();
            } else {
                toast.error("Something went wrong!");
            }

        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/');
            } else {
                toast.error("An error occurred while updating the package details.");
            }
        } finally {
            setLoading(false);
            setIsApiCallInProgress(false);
        }
    };

    const handleDeletePackage = async () => {

        if (!comment.trim()) {
            toast.error("Comment is required to proceed with deletion.!");
            return;
        }

        setIsApiCallInProgress(true);
        setLoading(true);

        try {

            const apiUrl = `${process.env.REACT_APP_URL}${UrlRoute.GET_ORDER_URL}/${userId}/${UrlRoute.GET_ACTIVE_PACKAGE_URL}`;

            const response = await axios.delete(apiUrl, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    'Content-Type': 'application/json',
                },
                data: { comment: comment },
            });

            if (response.status === 200) {
                toast.success("Package deleted successfully.!");
                handlePackageAlertDialogClickClose();
                getUserActivePackageDetails();
            } else {
                toast.error("Something went wrong!");
            }

        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/');
            } else {
                toast.error("Error deleting the package.");
            }
        } finally {
            setLoading(false);
            setIsApiCallInProgress(false);
        }
    };

    return (
        <>
            <MainLayout>
                <Container
                    maxWidth="md"
                    disableGutters
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 0.5,
                        overflowY: 'auto',
                        width: '100%',
                        p: 1
                    }}
                >
                    {loading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <>

                            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>

                                {/* User Info Card  */}
                                <Card sx={{ width: '100%', boxShadow: 6 }}>
                                    <CardContent
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            gap: 2,
                                            textAlign: 'left'
                                        }}
                                    >
                                        <Box sx={{ flex: 1 }}>
                                            <Typography variant="h5" component="div">
                                                {searchedUser.name}
                                            </Typography>
                                            <Typography variant="body1">
                                                User Type: {searchedUser.userType}
                                            </Typography>
                                            <Typography variant="body1" gutterBottom>
                                                Mobile: {searchedUser.contactNo}
                                            </Typography>
                                            <Typography variant="h6" component="div">
                                                Package Details
                                            </Typography>
                                            {activePackage ? (
                                                activePackage.packageCode === "MEDDY_CONNECT_STARTER" ? (
                                                    <>
                                                        <Typography variant="body1" color="error">
                                                            User does not have any active plan.
                                                        </Typography>
                                                        <Box sx={{ mt: 1 }}>
                                                            <FormControl fullWidth>
                                                                <Select
                                                                    labelId="package-select-label"
                                                                    id="package-select"
                                                                    value={selectedPackage}
                                                                    onChange={handlePackageSelection}
                                                                    displayEmpty
                                                                >
                                                                    <MenuItem disabled value="">
                                                                        Select a Package
                                                                    </MenuItem>
                                                                    {packages.map((item, index) => (
                                                                        <MenuItem key={index} value={item.packageCode}>
                                                                            {item.packageTitle}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                gap: 1,
                                                                mt: 2,
                                                                width: '100%',
                                                            }}
                                                        >
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                sx={{
                                                                    width: '100%', textTransform: 'capitalize', fontSize: "16px"
                                                                }}
                                                                onClick={() => handlePackageAlertDialogClickOpen('add')}
                                                            >
                                                                Add Package
                                                            </Button>
                                                        </Box>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Typography variant="body1">
                                                            Package Title: {activePackage.packageTitle}
                                                        </Typography>
                                                        <Typography variant="body1">
                                                            No of Products: {activePackage.noOfProducts}
                                                        </Typography>
                                                        <Typography variant="body1">
                                                            Start Date: {activePackage.startDate ? format(parseISO(activePackage.startDate), "MMM d, yyyy") : "N/A"}
                                                        </Typography>
                                                        <Typography variant="body1">
                                                            Expiry Date: {activePackage.endDate ? format(parseISO(activePackage.endDate), "MMM d, yyyy") : "N/A"}
                                                        </Typography>
                                                        <Box sx={{ mt: 1 }}>
                                                            <FormControl fullWidth>
                                                                <Select
                                                                    labelId="package-select-label"
                                                                    id="package-select"
                                                                    value={selectedPackage}
                                                                    onChange={handlePackageSelection}
                                                                    displayEmpty
                                                                >
                                                                    <MenuItem disabled value="">
                                                                        Select a Package
                                                                    </MenuItem>
                                                                    {packages.map((item, index) => (
                                                                        <MenuItem key={index} value={item.packageCode}>
                                                                            {item.packageTitle}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: { xs: 'column', sm: 'row' },
                                                                gap: 2,
                                                                mt: 2,
                                                                width: '100%',
                                                                justifyContent: { sm: 'space-between' },
                                                            }}
                                                        >
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                sx={{
                                                                    width: { xs: '100%', sm: '48%' }, textTransform: 'capitalize', fontSize: "16px"
                                                                }}
                                                                onClick={() => handlePackageAlertDialogClickOpen('change')}
                                                            >
                                                                Change Package
                                                            </Button>
                                                            <Button
                                                                variant="contained"
                                                                color="error"
                                                                sx={{
                                                                    width: { xs: '100%', sm: '48%' }, textTransform: 'capitalize', fontSize: "16px"
                                                                }}
                                                                onClick={() => handlePackageAlertDialogClickOpen('delete')}
                                                            >
                                                                Delete Package
                                                            </Button>
                                                        </Box>
                                                    </>
                                                )
                                            ) : (
                                                <Typography variant="body1">No package information available.</Typography>
                                            )}
                                        </Box>
                                    </CardContent>
                                </Card>

                                <Dialog
                                    open={openPackageAlertDialog}
                                    onClose={handlePackageAlertDialogClickClose}
                                    TransitionComponent={Transition}
                                >
                                    <DialogTitle>
                                        {dialogAction === 'add'
                                            ? "Add Package"
                                            : dialogAction === 'change'
                                                ? "Change Package"
                                                : "Delete Package"}
                                    </DialogTitle>
                                    <DialogContent>
                                        <Typography variant="subtitle1" color="text.primary" align="left" gutterBottom>
                                            Are you sure you want to {dialogAction} this package? Please provide a reason for this action.
                                        </Typography>
                                        <Box>
                                            <TextField
                                                label="Add a comment"
                                                variant="outlined"
                                                fullWidth
                                                margin="normal"
                                                multiline
                                                minRows={3}
                                                value={comment}
                                                onChange={(e) => setComment(e.target.value)}
                                                sx={{ margin: 0 }}
                                            />
                                        </Box>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handlePackageAlertDialogClickClose} color="primary" >Cancel</Button>
                                        <Button onClick={handleSubmit} color="primary">
                                            {dialogAction === 'delete' ? "Delete" : "Submit"}
                                        </Button>
                                    </DialogActions>
                                </Dialog>

                            </Box>

                        </>
                    )}
                </Container>
            </MainLayout>
        </>
    )
}

export default UserPackageDetails