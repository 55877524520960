import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Chip,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  DialogTitle,
  IconButton,
  TextField,
  Paper,
  Button,
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  Container,
  CircularProgress,
} from "@mui/material";
import { toast } from "react-toastify";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import useGetData from "../../../API/useGetData";
import ClearIcon from "@mui/icons-material/Clear";
import { FaRegFilePdf } from "react-icons/fa";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import UrlRoute from "../../../API/UrlRoute";
import { useAuth } from "../../../context/AuthContext";
import { fileCompressor } from "../../../components/FileCompressor";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CloseIcon from "@mui/icons-material/Close";
import "./showcaseproduct.css";
import { useNavigate, useLocation } from "react-router-dom";
import Constants from "../../../MeddyConnectConstant";
import PDFViewer from "../../../components/PDFViewer";
import axios from "axios";
import MainLayout from "../../MainLayout";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UpdateNewProductLaunch = (props) => {
  const theme = useTheme();
  const location = useLocation();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
  const [updateNewProductLaunchId, setUpdateNewProductLaunchId] =
    useState(location.state?.data);
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [productLink, setProductLink] = useState("");
  const [specialityData, setSpecialityData] = useState([]);
  const [selectedPdfFiles, setSelectedPdfFiles] = useState([]);
  const [selectedPDF, setSelectedPDF] = useState({
    contentType: null,
    content: null,
  });
  const [uploadedAttachment, setUploadedAttachment] = useState([]);
  const [showSelectedImages, setShowSelectedImages] = useState([]);
  const [compressedImages, setCompressedImages] = useState([]);
  const [brandName, setBrandName] = useState(null);
  const [brandInputValue, setBrandInputValue] = useState("");
  const [brandOptions, setBrandOptions] = useState([]);
  const [specialityName, setSpecialityName] = useState(null);
  const [specialityInputValue, setSpecialityInputValue] = useState("");
  const [specialityOptions, setSpecialityOptions] = useState([]);
  const [subCategory, setSubCategory] = useState("");
  const [subCategoryChoices, setSubCategoryChoices] = useState([]);
  const [category, setCategory] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [selectedImagesCount, setSelectedImagesCount] = useState(0);
  const [selectedPdfCount, setSelectedPdfCount] = useState(0);
  const [showUploadContainer, setShowUploadContainer] = useState(true);
  const navigate = useNavigate();
  const { authToken, searchedUser } = useAuth();
  const [comment, setComment] = useState("");
  const userId = searchedUser?.userId;

  // Dialogs
  const [openDocumentDialog, setOpenDocumentDialog] = useState(false);

  // Services;
  const { getBrandNames, getSpecialities, getProductCategories } = useGetData();

  useEffect(() => {
    getNewProductLaunch(updateNewProductLaunchId);
  }, []);

  useEffect(() => {
    getBrandNamesData("");
    getCategories();
  }, []);

  useEffect(() => {
    getSpecialitiesData("");
  }, [specialityData]);

  useEffect(() => {
    if (category) {
      handleCategoryWiseFilter(category);
    }
  }, [category, categoryOptions]);

  useEffect(() => {
    // Show the container if either the image count or the PDF count is less than 5
    if (selectedImagesCount < 5 || selectedPdfCount < 5) {
      setShowUploadContainer(true);
    } else {
      setShowUploadContainer(false);
    }
  }, [selectedImagesCount, selectedPdfCount]);

  const getBrandNamesData = async (brand) => {
    const brandOptions = await getBrandNames(brand);
    if (brandOptions) {
      setBrandName(null);
      setBrandOptions(brandOptions);
    }
  };

  const getSpecialitiesData = async (speciality) => {
    const specialityOptions = await getSpecialities(speciality);
    if (specialityOptions) {
      setSpecialityOptions(specialityOptions);
    }
  };

  const getCategories = async () => {
    const categoriesOptions = await getProductCategories();
    if (categoriesOptions) {
      setCategoryOptions(categoriesOptions);
    }
  };

  const handleAddSpecialities = (newSpeciality) => {
    if (
      newSpeciality &&
      newSpeciality.trim() !== "" &&
      !specialityData.some((item) => item.suitableFor === newSpeciality)
    ) {
      setSpecialityData((prevSpecialityData) => [
        ...prevSpecialityData,
        { suitableFor: newSpeciality },
      ]);
      setSpecialityInputValue("");
      setSpecialityName(null);
    }
  };

  const handleSpecialityDelete = (specialityname) => {
    setSpecialityData(
      specialityData.filter((chips) => chips.suitableFor !== specialityname)
    );
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
    handleCategoryWiseFilter(event.target.value);
  };

  const handleCategoryWiseFilter = (category) => {
    let selectedCategory = categoryOptions.find(
      (item) => item.categoryLink?.rel === category
    );

    let selectedSubCategory = selectedCategory
      ? selectedCategory.subcategoryLink
      : [];

    setSubCategoryChoices(selectedSubCategory);
  };

  const handleSubCategoryChange = (event) => {
    setSubCategory(event.target.value);
  };

  const handleDocumentDialogClickOpen = async (pdfUrl) => {
    try {
      const response = await fetch(pdfUrl);
      const blob = await response.blob();
      const base64String = await blobToBase64(blob);
      setSelectedPDF({ contentType: blob.type, content: base64String });
      setOpenDocumentDialog(true);
    } catch (error) {
      console.error("Error fetching PDF:", error);
    }
  };

  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1];
        resolve(base64String);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleDocumentDialogClickClose = () => {
    setOpenDocumentDialog(false);
    setSelectedPDF({ contentType: null, content: null });
  };

  const getNewProductLaunch = async (updateNewProductLaunchId) => {
    setLoading(true);
    const apiUrl = `${process.env.REACT_APP_URL}items/${userId}/${updateNewProductLaunchId}`;

    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      setTitle(response.data.title);
      setCategory(response.data.category);
      setSubCategory(response.data.product);
      setBrandInputValue(response.data.brand);
      setDescription(response.data.description);
      setProductLink(response.data.productLink?.href);
      setSpecialityData(response.data.itemSuitabilities);
      setShowSelectedImages(response.data.itemImages);
      setUploadedAttachment(response.data.itemAttachments);
      setComment(response.data.comment);
      setLoading(false);
    } else {
      toast.error("Something went wrong!");
      setLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);
    const images = [];
    const pdfs = [];

    if (
      selectedFilesArray.length > 5 ||
      selectedFilesArray.some(
        (file) => file.type === "application/pdf" && file.size > 4 * 1024 * 1024
      )
    ) {
      if (selectedFilesArray.length > 5) {
        toast.error("You can not upload more than five files");
      } else {
        toast.error("PDF file size should be 4 MB or less");
      }
      return;
    }

    selectedFilesArray.forEach((file) => {
      if (file.type.startsWith("image/")) {
        images.push(file);
      } else if (file.type === "application/pdf") {
        pdfs.push(file);
      }
    });

    setSelectedImagesCount((prevCount) => prevCount + images.length);
    setSelectedPdfCount((prevCount) => prevCount + pdfs.length);

    if (
      selectedImagesCount + images.length >= 5 &&
      selectedPdfCount + pdfs.length >= 5
    ) {
      setShowUploadContainer(false);
    }

    handleImageProcessing(images);
    setSelectedPdfFiles((prevPdfFiles) => [...prevPdfFiles, ...pdfs]);
  };

  const handleImageProcessing = async (images) => {
    setLoading(true);
    const compressedImagesArray = [];

    const showImages = images.map((file) => URL.createObjectURL(file));
    setShowSelectedImages((prevImages) => prevImages.concat(showImages));

    for (const image of images) {
      try {
        const compressedImage = await fileCompressor(image);
        const imageData = {
          type: image.type,
          compressedImage: compressedImage,
        };
        compressedImagesArray.push(imageData);
      } catch (error) {
        setLoading(false);
        console.error("Error compressing image:", error);
      }
    }

    setCompressedImages((prevCompressedImages) => [
      ...prevCompressedImages,
      ...compressedImagesArray,
    ]);
    setLoading(false);
  };

  const handleImageDelete = (id) => {
    const newImages = showSelectedImages.filter((photo, index) => index !== id);
    setShowSelectedImages(newImages);

    const fileCompressedArray = compressedImages.filter(
      (photo, index) => index !== id
    );
    setCompressedImages(fileCompressedArray);

    // Update the image count after deleting a file
    setSelectedImagesCount((prevCount) => prevCount - 1);
  };

  const handlePdfFileDelete = (id) => {
    const pdfFiles = selectedPdfFiles.filter((photo, index) => index !== id);
    setSelectedPdfFiles(pdfFiles);

    // Update the PDF count after deleting a file
    setSelectedPdfCount((prevCount) => prevCount - 1);
  };

  const handleDeleteImageFromApi = async (productId, imgId) => {
    setLoading(true);
    const apiUrl = `${process.env.REACT_APP_URL}items/image/${userId}/${productId}/${imgId}`;

    const response = await axios.delete(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      const newImages = showSelectedImages.filter(
        (element, index) => element.id !== imgId
      );
      setShowSelectedImages(newImages);
      setLoading(false);
    } else {
      setLoading(false);
      toast.error("Something went wrong!");
    }
  };

  const handleDeletePdfFromApi = async (productId, pdfId) => {
    setLoading(true);
    const apiUrl = `${process.env.REACT_APP_URL}items/attachment/${productId}/${pdfId}/${userId}`;

    const response = await axios.delete(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      const newPdfs = uploadedAttachment.filter(
        (element, index) => element.id !== pdfId
      );
      setUploadedAttachment(newPdfs);
      setLoading(false);
    } else {
      setLoading(false);
      toast.error("Something went wrong!");
    }
  };

  // Function to handle delete action
  const handleDelete = (item, index, updateNewProductLaunchId) => {
    if (item.id) {
      handleDeleteImageFromApi(updateNewProductLaunchId, item.id);
    } else {
      handleImageDelete(index);
    }
  };

  function base64ToBlob(base64String) {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray]);
  }

  const updateNewProductLaunch = async (newProductLaunchId) => {
    setIsApiCallInProgress(true);
    setLoading(true);
    const apiUrl = `${process.env.REACT_APP_URL}items/${userId}/${newProductLaunchId}`;

    const requestBody = {
      id: newProductLaunchId,
      itemType: "NEW_LAUNCH",
      title: title,
      category: category,
      product: subCategory,
      brand: brandInputValue,
      description: description,
      itemSuitabilities: specialityData,
      productLink: productLink,
      country: "India",
      comment: comment,
    };
    const response = await axios.put(apiUrl, requestBody, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      let isImageUploadedSuccessfully = false;
      let isAttachmentUploadedSuccessfully = false;

      toast.success("Product updated successfully !!!");

      console.log(compressedImages)
      if (compressedImages.length !== 0) {
        const photos = new FormData();

        // Iterate over the compressedImages array
        compressedImages.forEach((imageData, index) => {
          try {
            const blob = base64ToBlob(imageData.compressedImage); // Convert base64 string to blob

            // Append the blob with the correct MIME type and filename
            photos.append(
              "images",
              blob,
              `image_${index}.${imageData.type}`,
              `image/${imageData.type}`
            );
            console.log(photos)
          } catch (error) {
            console.error("Error converting image to blob:", error);
          }
        });
        const uploadImageurl = `${process.env.REACT_APP_URL}${UrlRoute.UPLOAD_ITEM_IMAGES_URL}/${userId}/${newProductLaunchId}`;
        const response = await axios.post(uploadImageurl, photos, {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'multipart/form-data',
          },
        });
        if (response.status === 200) {
          setLoading(false);
          setIsApiCallInProgress(false);
          toast.success("Product images updated successfully !!!");
          isImageUploadedSuccessfully = true;
        } else {
          setLoading(false);
          setIsApiCallInProgress(false);
          toast.error(
            "Product updated Successfully, but there's an error uploading images, Please upload again in Edit Product.!"
          );
        }
      }

      if (selectedPdfFiles.length !== 0) {
        const formData = new FormData();

        for (let i = 0; i < selectedPdfFiles.length; i++) {
          formData.append("attachments", selectedPdfFiles[i]);
        }
        const uploadAttachmenturl = `${process.env.REACT_APP_URL}${UrlRoute.UPLOAD_ITEM_ATTACHMENTS_URL}/${userId}/${newProductLaunchId}`;

        const response = await axios.post(uploadAttachmenturl, formData, {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'multipart/form-data',
          },
        });
        if (response.status === 200) {
          setLoading(false);
          setIsApiCallInProgress(false);
          toast.success("Product pdf files uploaded successfully !!!");
          isAttachmentUploadedSuccessfully = true;
        } else {
          setLoading(false);
          setIsApiCallInProgress(false);
          toast.error(
            "Product updated Successfully, but there's an error uploading attachments, Please upload again in Edit Product.!"
          );
        }
      }

      if (
        isImageUploadedSuccessfully === true ||
        isAttachmentUploadedSuccessfully === true
      ) {
        navigate(Constants.INVENTORY);
      } else {
        setLoading(false);
        setIsApiCallInProgress(false);
        navigate(Constants.INVENTORY);
      }
    } else {
      setLoading(false);
      setIsApiCallInProgress(false);
      toast.error("Something went wrong!");
    }
  };

  const handleUpdateNewProductLaunchSubmit = () => {
    if (validateUpdateNewProductLaunchForm()) {
      updateNewProductLaunch(updateNewProductLaunchId);
    }
  };

  const validateUpdateNewProductLaunchForm = () => {
    let valid = true;

    if (title.trim() === "") {
      toast.error("Product Title cannot be empty", { appearance: "error" });
      valid = false;
    }

    if (category.trim() === "") {
      toast.error("Product Category cannot be empty", { appearance: "error" });
      valid = false;
    }

    if (subCategory.trim() === "") {
      toast.error("Product Name cannot be empty", { appearance: "error" });
      valid = false;
    }

    if (brandInputValue.trim() === "") {
      toast.error("Brand cannot be empty", { appearance: "error" });
      valid = false;
    }

    if (description.trim() === "") {
      toast.error("Description cannot be empty", { appearance: "error" });
      valid = false;
    }

    const urlRegex =
      /^(http[s]?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}(\/\S*)?$/;

    if (productLink.trim() !== "" && !urlRegex.test(productLink)) {
      toast.error("Please enter a valid website link", { appearance: "error" });
      valid = false;
    }

    if (compressedImages.length > 5) {
      toast.error("You can not upload more than five images", {
        appearance: "error",
      });
      valid = false;
    }

    if (selectedPdfFiles.length > 5) {
      toast.error("You can not upload more than five attachments", {
        appearance: "error",
      });
      valid = false;
    }

    if (comment.trim() === "") {
      toast.error("comment can not be empty", { appearance: "error" });
      valid = false;
    }

    return valid;
  };

  return (
    <>
      <MainLayout>
        <Container
          maxWidth="md"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 0.5,
            overflowY: "auto",
            width: "100%",
            p: 0,
          }}
        >
          {" "}
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Paper elevation={2} className="showcase-product-paper-container">
                <Box display="flex" flexDirection="column" gap={2}>
                  <Box flex={1}>
                    <TextField
                      fullWidth
                      label="Product Title"
                      name="producttitle"
                      required
                      value={title}
                      onChange={(event) => {
                        const inputValue = event.target.value;
                        const formattedValue = inputValue
                          .split(" ")
                          .map((word) => {
                            if (word === word.toUpperCase()) {
                              return word;
                            } else {
                              return (
                                word.charAt(0).toUpperCase() +
                                word.slice(1).toLowerCase()
                              );
                            }
                          })
                          .join(" ");

                        setTitle(formattedValue);
                      }}
                      autoComplete="off"
                      id="producttitle"
                      variant="outlined"
                    />
                  </Box>
                  <Box flex={1}>
                    <FormControl fullWidth>
                      <InputLabel
                        id="category-select-label"
                        htmlFor="category-picker"
                      >
                        Product Category
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="category-label"
                        id="category-picker"
                        label="Select Category"
                        value={category}
                        onChange={handleCategoryChange}
                      >
                        <MenuItem value={""}>Select Category</MenuItem>
                        {categoryOptions.map((choice, index) => {
                          return (
                            <MenuItem
                              value={choice.categoryLink?.rel}
                              key={index}
                            >
                              {choice.categoryLink?.rel}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box flex={1}>
                    <FormControl fullWidth>
                      <InputLabel id="subCategory-label">
                        Product Name
                      </InputLabel>
                      <Select
                        labelId="subCategory-label"
                        id="subCategory"
                        value={subCategory}
                        label="Product Name"
                        onChange={handleSubCategoryChange}
                        disabled={!category}
                      >
                        {subCategoryChoices &&
                          subCategoryChoices.map((option, index) => (
                            <MenuItem key={index} value={option.rel}>
                              {option.rel}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box flex={1}>
                    <Autocomplete
                      freeSolo
                      id="brandname"
                      disableClearable
                      options={brandOptions.map((option) => option)}
                      value={brandName}
                      onChange={(event, newValue) => {
                        setBrandName(newValue);
                      }}
                      inputValue={brandInputValue}
                      onInputChange={(event, newInputValue) => {
                        const capitalizedBrandInput = newInputValue
                          .toLowerCase()
                          .split(" ")
                          .map((word) => {
                            return word.charAt(0).toUpperCase() + word.slice(1);
                          })
                          .join(" ");

                        setBrandInputValue(capitalizedBrandInput);

                        if (
                          !brandOptions.find(
                            (option) => option === capitalizedBrandInput
                          )
                        ) {
                          getBrandNamesData(capitalizedBrandInput);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Brand Name"
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Box flex={1}>
                    <TextField
                      fullWidth
                      label="Product Description"
                      name="description"
                      value={description}
                      onChange={(event) =>
                        setDescription(
                          event.target.value
                            .toLowerCase()
                            .split(" ")
                            .map((word) => {
                              return (
                                word.charAt(0).toUpperCase() + word.slice(1)
                              );
                            })
                            .join(" ")
                        )
                      }
                      autoComplete="off"
                      multiline
                      minRows={3}
                      id="description"
                      variant="outlined"
                    />
                  </Box>
                  <Box flex={1}>
                    <TextField
                      fullWidth
                      label="Website Link"
                      name="websitelink"
                      required
                      value={productLink}
                      onChange={(event) => setProductLink(event.target.value)}
                      autoComplete="off"
                      id="websitelink"
                      variant="outlined"
                    />
                  </Box>
                  <Box flex={1}>
                    <Autocomplete
                      freeSolo
                      id="specialityname"
                      disableClearable
                      options={specialityOptions.map((option) => option)}
                      value={specialityName}
                      onChange={(event, newValue) => {
                        setSpecialityName(newValue);
                        if (
                          !specialityData.some(
                            (item) => item.suitableFor === newValue
                          )
                        ) {
                          handleAddSpecialities(newValue);
                        }
                      }}
                      inputValue={specialityInputValue}
                      onInputChange={(event, newInputValue) => {
                        const capitalizedInput = newInputValue
                          .toLowerCase()
                          .split(" ")
                          .map((word) => {
                            return word.charAt(0).toUpperCase() + word.slice(1);
                          })
                          .join(" ");

                        setSpecialityInputValue(capitalizedInput);

                        if (!specialityOptions.includes(capitalizedInput)) {
                          getSpecialitiesData(capitalizedInput);
                        }
                      }}
                      onBlur={() => {
                        if (
                          !specialityOptions.find(
                            (option) => option === specialityInputValue
                          )
                        ) {
                          handleAddSpecialities(specialityInputValue);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Suitable For"
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                          }}
                        />
                      )}
                    />
                  </Box>
                  {specialityData.length > 0 && (
                    <Box container direction="column">
                      <Box
                        container
                        direction="row"
                        style={{ padding: "10px", marginTop: "10px" }}
                      >
                        <Box item>
                          <Paper
                            elevation={0}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              flexWrap: "wrap",
                              listStyle: "none",
                              backgroundColor: "transparent",
                              p: 0.5,
                              m: 0,
                            }}
                            component="ul"
                          >
                            {specialityData.map((data, index) => {
                              return (
                                <ListItem key={index}>
                                  <Chip
                                    variant="outlined"
                                    color="primary"
                                    style={{
                                      backgroundColor:
                                        theme.palette.mode === "light"
                                          ? "#fff"
                                          : "#292929",
                                      color:
                                        theme.palette.mode === "light"
                                          ? "#000"
                                          : "#fff",
                                    }}
                                    label={data.suitableFor}
                                    onDelete={() =>
                                      data.suitableFor === ""
                                        ? undefined
                                        : handleSpecialityDelete(
                                            data.suitableFor
                                          )
                                    }
                                  />
                                </ListItem>
                              );
                            })}
                          </Paper>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>

                {/* ############################  contract document section ######################################### */}

                <Box flex={1} style={{ marginTop: "10px" }}>
                  <Typography
                    color="text.primary"
                    gutterBottom
                    className="new-product-launch-label"
                  >
                    Attachments
                  </Typography>
                </Box>

                {showUploadContainer && (
                  <Box
                    container
                    direction="column"
                    justifyContent="center"
                    align="center"
                    className="showcase-product-upload-Container"
                  >
                    <Box item>
                      <IconButton component="label" style={{ padding: "0px" }}>
                        <input
                          hidden
                          accept="image/*,.pdf"
                          multiple
                          type="file"
                          onChange={handleFileChange}
                        />
                        <CloudUploadIcon
                          color="primary"
                          className="showcase-product-upload-Icon"
                        />
                      </IconButton>
                    </Box>
                    <Box item>
                      <Typography
                        variant="subtitle1"
                        color="text.primary"
                        align="center"
                        gutterBottom
                      >
                        Upload Documents
                      </Typography>
                    </Box>
                  </Box>
                )}

                <div className="contract-gallery">
                  {showSelectedImages.map((item, index) => {
                    return (
                      <div key={index} style={{ position: "relative" }}>
                        <img
                          src={item.imageLink ? item.imageLink?.href : item}
                          alt="upload"
                          height="100%"
                          width="100%"
                        />
                        <CloseIcon
                          style={{
                            position: "absolute",
                            top: "6px",
                            right: "6px",
                            fontSize: fullScreen ? "18px" : "22px",
                            color: "red",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            handleDelete(item, index, updateNewProductLaunchId)
                          }
                        />
                      </div>
                    );
                  })}
                </div>

                {uploadedAttachment && (
                  <div className="pdf-container">
                    {uploadedAttachment.map((element, index) => {
                      return (
                        <div key={element.id} style={{ position: "relative" }}>
                          <FaRegFilePdf
                            style={{
                              fontSize: fullScreen ? "80px" : "60px",
                              color:
                                theme.palette.mode === "light"
                                  ? "#002E6E"
                                  : "#00B9F1",
                            }}
                            className="dialog-uploaded-icon"
                            onClick={() =>
                              handleDocumentDialogClickOpen(
                                element.attachmentLink.href
                              )
                            }
                          />
                          <IconButton
                            disableRipple
                            style={{
                              position: "absolute",
                              top: "0px",
                              right: "0px",
                              zIndex: 1,
                              padding: "0px",
                            }}
                            onClick={() =>
                              handleDeletePdfFromApi(
                                updateNewProductLaunchId,
                                element.id
                              )
                            }
                          >
                            <CloseIcon
                              style={{
                                fontSize: fullScreen ? "18px" : "22px",
                                color: "red",
                                cursor: "pointer",
                              }}
                            />
                          </IconButton>
                        </div>
                      );
                    })}
                  </div>
                )}

                {selectedPdfFiles.map((item, index) => {
                  return (
                    <div
                      className="showcase-product-upload-fileInfo"
                      key={index}
                    >
                      <PictureAsPdfIcon className="showcase-product-upload-fileIcon" />
                      <Typography
                        variant="subtitle1"
                        className="showcase-product-file-name"
                      >
                        {item.name}
                      </Typography>
                      <ClearIcon
                        className="showcase-product-upload-fileIcon"
                        onClick={() => handlePdfFileDelete(index)}
                      />
                    </div>
                  );
                })}

                <Box flex={1}>
                  <TextField
                    label="Add a comment"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    sx={{ margin: 0 }}
                  />
                </Box>
              </Paper>

              {/* ############################ PDF viewer dialog ######################################### */}

              <Dialog
                open={openDocumentDialog}
                onClose={handleDocumentDialogClickClose}
                fullScreen={fullScreen}
                maxWidth="md"
                fullWidth
                TransitionComponent={Transition}
              >
                <DialogTitle
                  style={{ backgroundColor: "#00B9F1", color: "white" }}
                >
                  <IconButton
                    edge="start"
                    onClick={handleDocumentDialogClickClose}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                    }}
                  >
                    <CloseIcon style={{ color: "white" }} />
                  </IconButton>
                  View Document
                </DialogTitle>
                <DialogContent style={{ padding: "0px" }}>
                  <PDFViewer
                    pdfData={`data:${selectedPDF.contentType};base64,${selectedPDF.content}`}
                  />
                </DialogContent>
              </Dialog>

              {!isApiCallInProgress && (
                <Box
                  container
                  direction="row"
                  style={{ padding: fullScreen ? "0px 6px" : "0px 10px" }}
                >
                  <Box flex={1}>
                    <Button
                      variant="contained"
                      fullWidth
                      className="showcase-product-button"
                      color="primary"
                      onClick={handleUpdateNewProductLaunchSubmit}
                    >
                      Update
                    </Button>
                  </Box>
                </Box>
              )}
            </>
          )}
        </Container>
      </MainLayout>
    </>
  );
};

export default UpdateNewProductLaunch;
