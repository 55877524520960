import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, CircularProgress, IconButton } from '@mui/material';
import { CloudUpload as CloudUploadIcon, Cancel as CancelIcon } from '@mui/icons-material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import UrlRoute from "../API/UrlRoute";
import { useAuth } from '../context/AuthContext';
import { fileCompressor } from "../components/FileCompressor";
import { toast } from 'react-toastify';

const IdentityDocument = ({ identityFrontDocId, identityBackDocId, userId, userName, setIdentityFrontDocId, setIdentityBackDocId }) => {
    const [frontDocData, setFrontDocData] = useState(null);
    const [backDocData, setBackDocData] = useState(null);
    const [loadingFrontDoc, setLoadingFrontDoc] = useState(true);
    const [loadingBackDoc, setLoadingBackDoc] = useState(true);
    const [loadingAction, setLoadingAction] = useState(false);
    const navigate = useNavigate();
    const { authToken } = useAuth();

    const [frontDocumentType, setFrontDocumentType] = useState('');
    const [frontDocumentNumber, setFrontDocumentNumber] = useState('');
    const [frontComment, setFrontComment] = useState('');
    const [frontVerificationStatus, setFrontVerificationStatus] = useState('');

    // Back document states
    const [backDocumentType, setBackDocumentType] = useState('');
    const [backDocumentNumber, setBackDocumentNumber] = useState('');
    const [backComment, setBackComment] = useState('');
    const [backVerificationStatus, setBackVerificationStatus] = useState('');

    // State for image selection and compression
    const [selectedFrontImage, setSelectedFrontImage] = useState(null);
    const [compressedFrontImage, setCompressedFrontImage] = useState({ contentType: null, content: null });
    const [selectedBackImage, setSelectedBackImage] = useState(null);
    const [compressedBackImage, setCompressedBackImage] = useState({ contentType: null, content: null });

    const fetchFrontDoc = async () => {
        try {
            if (identityFrontDocId && userId) {

                const apiUrl = `${process.env.REACT_APP_URL}${UrlRoute.USER_VERIFICATION_DOCUMENT_URL}/${userId}/${identityFrontDocId}`;

                const response = await axios.get(apiUrl, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                        'Content-Type': 'application/json',
                    },
                });
                setFrontDocData(response.data);
                setFrontDocumentType(response.data.documentType || '');
                setFrontDocumentNumber(response.data.documentNumber || '');
                setFrontComment(response.data.comment || '');
                setFrontVerificationStatus(response.data.verificationStatus || '');
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/');
            } else {
                toast.error("Error fetching front document:");
            }
        } finally {
            setLoadingFrontDoc(false);
        }
    };

    // Fetch the back document data
    const fetchBackDoc = async () => {
        try {
            if (identityBackDocId && userId) {

                const apiUrl = `${process.env.REACT_APP_URL}${UrlRoute.USER_VERIFICATION_DOCUMENT_URL}/${userId}/${identityBackDocId}`;

                const response = await axios.get(apiUrl, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                        'Content-Type': 'application/json',
                    },
                });
                setBackDocData(response.data);
                setBackDocumentType(response.data.documentType || '');
                setBackDocumentNumber(response.data.documentNumber || '');
                setBackComment(response.data.comment || '');
                setBackVerificationStatus(response.data.verificationStatus || '');
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/');
            } else {
                toast.error("Error fetching back document:");
            }
        } finally {
            setLoadingBackDoc(false);
        }
    };

    useEffect(() => {
        fetchFrontDoc();
    }, [identityFrontDocId]);

    useEffect(() => {
        fetchBackDoc();
    }, [identityBackDocId]);

    // Approve Document API Call
    const handleApprove = async (docId, documentType, documentNumber, comment, isFront) => {
        setLoadingAction(true);
        try {

            const apiUrl = `${process.env.REACT_APP_URL}${UrlRoute.USER_VERIFICATION_DOCUMENT_URL}/${userId}/${docId}`;
            const data = {
                documentType,
                documentNumber,
                comment: 'Your document has been successfully verified',
                verificationStatus: 'VERIFIED',
            };

            await axios.put(apiUrl, data, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    'Content-Type': 'application/json',
                },
            });
            toast.success("Document approved successfully !!!");
            if (isFront) {
                fetchFrontDoc();
            } else {
                fetchBackDoc();
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/');
            } else {
                toast.error("Error approving document:");
            }
        } finally {
            setLoadingAction(false);
        }
    };

    // Reject Document API Call
    const handleReject = async (docId, documentType, documentNumber, comment, isFront) => {
        setLoadingAction(true);
        try {

            const apiUrl = `${process.env.REACT_APP_URL}${UrlRoute.USER_VERIFICATION_DOCUMENT_URL}/${userId}/${docId}`;
            const data = {
                documentType,
                documentNumber,
                comment: 'Your document has been rejected',
                verificationStatus: 'VERIFICATION_FAILED',
            };

            await axios.put(apiUrl, data, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    'Content-Type': 'application/json',
                },
            });
            toast.success("Document rejected successfully !!!");
            if (isFront) {
                fetchFrontDoc();
            } else {
                fetchBackDoc();
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/');
            } else {
                toast.error("Error rejecting document:");
            }
        } finally {
            setLoadingAction(false);
        }
    };

    const handleImageChange = async (event, type) => {
        try {
            const img = event.target.files[0];

            if (img) {
                let fileName = img.name;
                let idxDot = fileName.lastIndexOf(".") + 1;
                let extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
                if (extFile === "jpg" || extFile === "jpeg" || extFile === "png") {
                    // Show loader while processing
                    setLoadingAction(true);

                    let url = URL.createObjectURL(img);
                    let imageData = await fileCompressor(img);

                    if (imageData) {
                        let contentType =
                            extFile.toLowerCase() === "png" ? "image/png" : "image/jpeg";

                        if (type === 'ID_CARD_DOCUMENT_FRONT') {
                            setCompressedFrontImage({ contentType: contentType, content: imageData });
                            setSelectedFrontImage(url);
                        } else if (type === 'ID_CARD_DOCUMENT_BACK') {
                            setCompressedBackImage({ contentType: contentType, content: imageData });
                            setSelectedBackImage(url);
                        }

                    } else {
                        toast.error("An error occurred while compressing the image");
                    }

                    // Hide loader after processing
                    setLoadingAction(false);
                } else {
                    toast.error("Please upload valid(jpeg/png) image!!!");
                }
            }

        } catch (error) {
            toast.error("An error occurred while processing the image");
            // Hide loader in case of error
            setLoadingAction(false);
        }

    };

    const handleRemoveImage = (type) => {
        if (type === 'ID_CARD_DOCUMENT_FRONT') {
            setCompressedFrontImage({ contentType: null, content: null });
            setSelectedFrontImage(null);
        } else if (type === 'ID_CARD_DOCUMENT_BACK') {
            setCompressedBackImage({ contentType: null, content: null });
            setSelectedBackImage(null);
        }
    };

    const handleSubmit = async (type) => {

        if (type === 'ID_CARD_DOCUMENT_FRONT') {
            if (!selectedFrontImage || !compressedFrontImage.content) {
                toast.error("Please select the front image");
                return;
            }
        }

        if (type === 'ID_CARD_DOCUMENT_BACK') {
            if (!selectedBackImage || !compressedBackImage.content) {
                toast.error("Please select the back image");
                return;
            }
        }

        setLoadingAction(true);
        try {

            const apiUrl = `${process.env.REACT_APP_URL}${UrlRoute.USER_VERIFICATION_DOCUMENT_URL}/${userId}`;

            const requestBody = type === 'ID_CARD_DOCUMENT_FRONT'
                ? {
                    documentType: 'ID_CARD_DOCUMENT_FRONT',
                    contentType: compressedFrontImage.contentType,
                    content: compressedFrontImage.content,
                    comment: `Uploading document on behalf of ${userName}`,
                }
                : {
                    documentType: 'ID_CARD_DOCUMENT_BACK',
                    contentType: compressedBackImage.contentType,
                    content: compressedBackImage.content,
                    comment: `Uploading document on behalf of ${userName}`,
                };

            const response = await axios.post(apiUrl, requestBody, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                if (type === 'ID_CARD_DOCUMENT_FRONT') {
                    setIdentityFrontDocId(response.data.id);
                    setCompressedFrontImage({ contentType: null, content: null });
                    setSelectedFrontImage(null);
                } else if (type === 'ID_CARD_DOCUMENT_BACK') {
                    setIdentityBackDocId(response.data.id);
                    setCompressedBackImage({ contentType: null, content: null });
                    setSelectedBackImage(null);
                }
                alert(`${type === 'ID_CARD_DOCUMENT_FRONT' ? 'Front' : 'Back'} document uploaded successfully`);
            }

        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/');
            } else {
                toast.error("An error occurred while uploading the document");
            }
        } finally {
            setLoadingAction(false);
        }
    };


    return (
        <>
            {loadingFrontDoc ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </Box>
            ) : loadingAction ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Typography variant="h6" gutterBottom>
                        Identity Document (Front)
                    </Typography>
                    {frontDocData ? (
                        <Box sx={{ width: '100%' }}>
                            <Typography variant="subtitle1" gutterBottom>
                                Status : {frontVerificationStatus && frontVerificationStatus}
                            </Typography>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, justifyContent: 'center' }}>

                                <Box
                                    sx={{
                                        width: { xs: '100%', sm: '80%' },
                                        height: 'auto',
                                        maxHeight: '600px',
                                        overflow: 'hidden',
                                        borderRadius: 2,
                                        boxShadow: 3,
                                    }}
                                >
                                    <img
                                        src={`data:${frontDocData.contentType};base64,${frontDocData.content}`}
                                        alt="Identity Document Front"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',
                                        }}
                                    />
                                </Box>

                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: { xs: 'column', sm: 'row' },
                                    gap: 2,
                                    mt: 2,
                                    width: '100%',
                                    justifyContent: { sm: 'space-between' }
                                }}
                            >
                                <Button
                                    variant="contained"
                                    color="success"
                                    sx={{
                                        width: { xs: '100%', sm: '48%' }, textTransform: 'capitalize', fontSize: "16px"
                                    }}
                                    onClick={() => handleApprove(identityFrontDocId, frontDocumentType, frontDocumentNumber, frontComment, true)}
                                >
                                    Approve Front
                                </Button>
                                <Button
                                    variant="contained"
                                    color="error"
                                    sx={{
                                        width: { xs: '100%', sm: '48%' }, textTransform: 'capitalize', fontSize: "16px"
                                    }}
                                    onClick={() => handleReject(identityFrontDocId, frontDocumentType, frontDocumentNumber, frontComment, true)}
                                >
                                    Reject Front
                                </Button>
                            </Box>
                        </Box>
                    ) : (
                        // <Typography variant="body1" align="center">
                        //     No front document available.
                        // </Typography>
                        <>
                            <Box sx={{ width: '100%' }}>

                                <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center' }}>

                                    <Box
                                        sx={{
                                            width: { xs: '100%', sm: '80%' },
                                            height: "400px",
                                            overflow: 'hidden',
                                            position: 'relative',
                                            cursor: 'pointer',
                                            borderRadius: 2,
                                            boxShadow: 3,
                                        }}
                                    >
                                        {/* Image Picker */}
                                        {!selectedFrontImage ? (
                                            <>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={(event) => handleImageChange(event, 'ID_CARD_DOCUMENT_FRONT')}
                                                    style={{
                                                        position: 'absolute',
                                                        width: '100%',
                                                        height: '100%',
                                                        opacity: 0,
                                                        cursor: 'pointer',
                                                        zIndex: 2,
                                                    }}
                                                />
                                                <CloudUploadIcon sx={{
                                                    fontSize: 85,
                                                    color: 'grey',
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    zIndex: 1,
                                                }}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <img
                                                    src={selectedFrontImage}
                                                    alt="Selected"
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        // objectFit: 'cover',
                                                    }}
                                                />
                                                <IconButton
                                                    sx={{
                                                        position: 'absolute',
                                                        top: 5,
                                                        right: 5,
                                                        backgroundColor: 'white',
                                                        color: 'red',
                                                        '&:hover': { backgroundColor: 'lightgrey' },
                                                    }}
                                                    size="small"
                                                    onClick={() => handleRemoveImage('ID_CARD_DOCUMENT_FRONT')}
                                                >
                                                    <CancelIcon fontSize="small" />
                                                </IconButton>
                                            </>
                                        )}
                                    </Box>

                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: 1,
                                        mt: 2,
                                        width: '100%',
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        color="success"
                                        sx={{
                                            width: '100%', textTransform: 'capitalize', fontSize: "16px"
                                        }}
                                        onClick={() => handleSubmit('ID_CARD_DOCUMENT_FRONT')}
                                    >
                                        Submit
                                    </Button>
                                </Box>
                            </Box>
                        </>
                    )}
                </>
            )}

            {loadingBackDoc ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </Box>
            ) : loadingAction ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Typography variant="h6" gutterBottom>
                        Identity Document (Back)
                    </Typography>
                    {backDocData ? (
                        <Box sx={{ width: '100%' }}>
                            <Typography variant="subtitle1" gutterBottom>
                                Status : {backVerificationStatus && backVerificationStatus}
                            </Typography>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, justifyContent: 'center' }}>

                                <Box
                                    sx={{
                                        width: { xs: '100%', sm: '80%' },
                                        height: 'auto',
                                        maxHeight: '600px',
                                        overflow: 'hidden',
                                        borderRadius: 2,
                                        boxShadow: 3,
                                    }}
                                >
                                    <img
                                        src={`data:${backDocData.contentType};base64,${backDocData.content}`}
                                        alt="Identity Document Back"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',
                                        }}
                                    />
                                </Box>

                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: { xs: 'column', sm: 'row' },
                                    gap: 2,
                                    mt: 2,
                                    width: '100%',
                                    justifyContent: { sm: 'space-between' }
                                }}
                            >
                                <Button
                                    variant="contained"
                                    color="success"
                                    sx={{
                                        width: { xs: '100%', sm: '48%' }, textTransform: 'capitalize', fontSize: "16px"
                                    }}
                                    onClick={() => handleApprove(identityBackDocId, backDocumentType, backDocumentNumber, backComment, false)}
                                >
                                    Approve Back
                                </Button>
                                <Button
                                    variant="contained"
                                    color="error"
                                    sx={{
                                        width: { xs: '100%', sm: '48%' }, textTransform: 'capitalize', fontSize: "16px"
                                    }}
                                    onClick={() => handleReject(identityBackDocId, backDocumentType, backDocumentNumber, backComment, false)}
                                >
                                    Reject Back
                                </Button>
                            </Box>
                        </Box>
                    ) : (
                        // <Typography variant="body1" align="center">
                        //     No back document available.
                        // </Typography>
                        <>
                            <Box sx={{ width: '100%' }}>

                                <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center' }}>

                                    <Box
                                        sx={{
                                            width: { xs: '100%', sm: '80%' },
                                            height: "400px",
                                            overflow: 'hidden',
                                            position: 'relative',
                                            cursor: 'pointer',
                                            borderRadius: 2,
                                            boxShadow: 3,
                                        }}
                                    >
                                        {/* Image Picker */}
                                        {!selectedBackImage ? (
                                            <>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={(event) => handleImageChange(event, 'ID_CARD_DOCUMENT_BACK')}
                                                    style={{
                                                        position: 'absolute',
                                                        width: '100%',
                                                        height: '100%',
                                                        opacity: 0,
                                                        cursor: 'pointer',
                                                        zIndex: 2,
                                                    }}
                                                />
                                                <CloudUploadIcon sx={{
                                                    fontSize: 85,
                                                    color: 'grey',
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    zIndex: 1,
                                                }}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <img
                                                    src={selectedBackImage}
                                                    alt="Selected"
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        // objectFit: 'cover',
                                                    }}
                                                />
                                                <IconButton
                                                    sx={{
                                                        position: 'absolute',
                                                        top: 5,
                                                        right: 5,
                                                        backgroundColor: 'white',
                                                        color: 'red',
                                                        '&:hover': { backgroundColor: 'lightgrey' },
                                                    }}
                                                    size="small"
                                                    onClick={() => handleRemoveImage('ID_CARD_DOCUMENT_BACK')}
                                                >
                                                    <CancelIcon fontSize="small" />
                                                </IconButton>
                                            </>
                                        )}
                                    </Box>

                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: 1,
                                        mt: 2,
                                        width: '100%',
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        color="success"
                                        sx={{
                                            width: '100%', textTransform: 'capitalize', fontSize: "16px"
                                        }}
                                        onClick={() => handleSubmit('ID_CARD_DOCUMENT_BACK')}
                                    >
                                        Submit
                                    </Button>
                                </Box>
                            </Box>
                        </>
                    )}
                </>
            )}
        </>
    )
}

export default IdentityDocument
