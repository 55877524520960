import React from 'react'
import { Box } from '@mui/material';
import Navbar from "./Navbar/Navbar";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const drawerWidth = 240; // Width of the Navbar

const MainLayout = ({ children }) => {
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

    return (
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
            <Navbar />
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 2,
                    display: 'flex',
                    justifyContent: 'center',
                    // alignItems: 'center',
                    minHeight: '100vh',
                    overflow: 'auto',
                    marginLeft: isLargeScreen ? `${drawerWidth}px` : 0,
                    mt: { xs: 7, sm: 7, md: 7, lg: 7 },
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        maxWidth: '900px',
                    }}
                >
                    {children}
                </Box>
            </Box>
        </Box>
    )
}

export default MainLayout