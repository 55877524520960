import React, { useState, useEffect } from "react";
import {
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Paper,
  DialogTitle,
  IconButton,
  Typography,
  Button,
  MenuItem,
  Autocomplete,
  InputLabel,
  Select,
  FormControl,
  Container,
  CircularProgress,
} from "@mui/material";
import { toast } from "react-toastify";
import useMediaQuery from "@mui/material/useMediaQuery";
import VerificationAlertDialog from "../../../utils/VerificationAlertDialog";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { useAuth } from "../../../context/AuthContext";
import "./sellcontractpage.css";
import axios from "axios";
import useGetData from "../../../API/useGetData";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import UrlRoute from "../../../API/UrlRoute";
import Constants from "../../../MeddyConnectConstant";
import { fileCompressor } from "../../FileCompressor";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useNavigate } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import MainLayout from "../../MainLayout";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const SellContract = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { countryCode } = "+91";
  const [title, setTitle] = useState("");
  const { authToken, searchedUser } = useAuth();
  const userId = searchedUser?.userId;
  const [description, setDescription] = useState("");
  const [duration, setDuration] = useState("");
  const [addPackageName, setAddPackageName] = useState("");
  const [price, setPrice] = useState("");
  const [term, setTerm] = React.useState("");
  const [packageData, setPackageData] = React.useState([]);
  const [selectedPdfFiles, setSelectedPdfFiles] = useState([]);
  const [showSelectedImages, setShowSelectedImages] = useState([]);
  const [compressedImages, setCompressedImages] = useState([]);
  const [brandName, setBrandName] = useState(null);
  const [brandInputValue, setBrandInputValue] = useState("");
  const [brandOptions, setBrandOptions] = useState([]);
  const [specialityData, setSpecialityData] = useState([]);
  const [specialityName, setSpecialityName] = useState(null);
  const [specialityInputValue, setSpecialityInputValue] = useState("");
  const [specialityOptions, setSpecialityOptions] = useState([]);
  const [subCategory, setSubCategory] = useState("");
  const [subCategoryChoices, setSubCategoryChoices] = useState("");
  const [category, setCategory] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [stateChoices, setStateChoices] = useState("");
  const [cityChoices, setCityChoices] = useState("");
  const [countryChoices, setCountryChoices] = useState("");
  const navigate = useNavigate();
  const [comment, setComment] = useState("");
  const [searchNumber, setSearchNumber] = useState("");
  const [pinCode, setPinCode] = useState("");
  // Dialogs
  const [openCreatePackageDialog, setOpenCreatePackageDialog] =
    React.useState(false);
    const [openAlertVerificationDialog, setOpenAlertVerificationDialog] =
    useState(false);

  // Services
  const {
    getBrandNames,
    getSpecialities,
    getProductCategories,
    getCountry,
    getStates,
    getCity,
  } = useGetData();

  useEffect(() => {
    getReferenceData();
    getBrandNamesData("");
    getCategories();
  }, []);

  useEffect(() => {
    getSpecialitiesData("");
  }, [specialityData]);

  useEffect(() => {
    if (searchedUser.identityVerified === true) {
        getReferenceData();
    } else {
        handleAlertVerificationDialogClickOpen();
    }
}, [searchedUser.identityVerified]);

  const handleCreatePackageDialogClickClose = () => {
    setOpenCreatePackageDialog(false);
  };
  const handleCreatePackageDialogClickOpen = () => {
    setOpenCreatePackageDialog(true);
  };
  const handleChange = (event) => {
    setTerm(event.target.value);
  };

  const handleAddPackages = () => {
    setAddPackageName("");
    setTerm("");
    setDuration("");
    setPrice("");
    setPackageData([
      ...packageData,
      {
        packageName: addPackageName,
        contractTerm: term,
        duration: duration,
        price: price,
      },
    ]);
    setOpenCreatePackageDialog(false);
  };

  const handlePackageDelete = (id) => {
    const newPackages = packageData.filter((value, index) => index !== id);
    setPackageData(newPackages);
  };

  const handleAlertVerificationDialogClickOpen = () => {
    setOpenAlertVerificationDialog(true);
  };

  const handleAlertVerificationDialogClickClose = () => {
    setOpenAlertVerificationDialog(false);
  };


  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);
    const images = [];
    const pdfs = [];

    if (
      selectedFilesArray.length > 5 ||
      selectedFilesArray.some(
        (file) => file.type === "application/pdf" && file.size > 4 * 1024 * 1024
      )
    ) {
      if (selectedFilesArray.length > 5) {
        toast.error("You can not upload more than five files");
      } else {
        toast.error("PDF file size should be 4 MB or less");
      }
      return;
    }

    selectedFilesArray.forEach((file) => {
      if (file.type.startsWith("image/")) {
        images.push(file);
      } else if (file.type === "application/pdf") {
        pdfs.push(file);
      }
    });

    handleImageProcessing(images);
    setSelectedPdfFiles((prevPdfFiles) => [...prevPdfFiles, ...pdfs]);
  };

  const handleImageProcessing = async (images) => {
    setLoading(true);
    const compressedImagesArray = [];

    const showImages = images.map((file) => URL.createObjectURL(file));
    setShowSelectedImages((prevImages) => prevImages.concat(showImages));

    for (const image of images) {
      try {
        const compressedImage = await fileCompressor(image);
        const imageData = {
          type: image.type,
          compressedImage: compressedImage,
        };
        compressedImagesArray.push(imageData);
      } catch (error) {
        setLoading(false);
        console.error("Error compressing image:", error);
      }
    }

    setCompressedImages((prevCompressedImages) => [
      ...prevCompressedImages,
      ...compressedImagesArray,
    ]);
    setLoading(false);
  };

  const handleImageDelete = (id) => {
    const newImages = showSelectedImages.filter((photo, index) => index !== id);
    setShowSelectedImages(newImages);

    const fileCompressedArray = compressedImages.filter(
      (photo, index) => index !== id
    );
    setCompressedImages(fileCompressedArray);
  };

  const handlePdfFileDelete = (id) => {
    const pdfFiles = selectedPdfFiles.filter((photo, index) => index !== id);
    setSelectedPdfFiles(pdfFiles);
  };

  const getReferenceData = async () => {
    const countryOptions = await getCountry();
    if (countryOptions) {
      setCountry("");
      setCountryChoices(countryOptions);
    }
  };

  const getStatesData = async (country) => {
    if (country && country != "") {
      let stateOptions = [];
      stateOptions = await getStates(country);
      if (stateOptions) {
        setState("");
        setStateChoices(stateOptions);
      }
    }
  };

  const getCityData = async (country, state) => {
    if (country && country != "" && state && state != "") {
      let cityOptions = [];
      cityOptions = await getCity(country, state);
      if (cityOptions) {
        setCity("");
        setCityChoices(cityOptions);
      }
    }
  };

  const getBrandNamesData = async (brand) => {
    const brandOptions = await getBrandNames(brand);
    if (brandOptions) {
      setBrandName(null);
      setBrandOptions(brandOptions);
    }
  };

  const getSpecialitiesData = async (speciality) => {
    const specialityOptions = await getSpecialities(speciality);
    if (specialityOptions) {
      setSpecialityOptions(specialityOptions);
    }
  };

  const handleAddSpecialities = (newSpeciality) => {
    if (
      newSpeciality &&
      newSpeciality.trim() !== "" &&
      !specialityData.some((item) => item.suitableFor === newSpeciality)
    ) {
      setSpecialityData((prevSpecialityData) => [
        ...prevSpecialityData,
        { suitableFor: newSpeciality },
      ]);
      setSpecialityInputValue("");
      setSpecialityName(null);
    }
  };

  const handleSpecialityDelete = (specialityname) => {
    setSpecialityData(
      specialityData.filter((chips) => chips.suitableFor !== specialityname)
    );
  };

  const getCategories = async () => {
    const categoriesOptions = await getProductCategories();
    if (categoriesOptions) {
      setCategoryOptions(categoriesOptions);
    }
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
    handleCategoryWiseFilter(event.target.value);
    setSubCategory("");
  };

  const handleCategoryWiseFilter = (category) => {
    let selectedCategory = categoryOptions.filter(
      (item) => item.categoryLink?.rel === category
    );
    let selectedSubCategory = selectedCategory.map((item) => {
      return item.subcategoryLink;
    });
    setSubCategoryChoices(selectedSubCategory);
  };

  const handleSubCategoryChange = (event) => {
    setSubCategory(event.target.value);
  };

  function base64ToBlob(base64String) {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray]);
  }

  const submitContractData = async () => {
    try {
      setIsApiCallInProgress(true);
      setLoading(true);

      const apiUrl = `${process.env.REACT_APP_URL}${UrlRoute.SELL_URL}/${userId}`;

      const requestBody = {
        itemType: "CONTRACT",
        title: title,
        category: category,
        product: subCategory,
        description: description,
        brand: brandInputValue,
        itemSuitabilities: specialityData,
        itemPackages: packageData,
        country: country,
        state: state,
        city: city,
        comment: comment,
      };

      // Create the contract
      const contractResponse = await axios.post(apiUrl, requestBody, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      });

      if (contractResponse.status === 200) {
        const contractId = contractResponse.data.id; // Assuming the response contains the created contract ID
        toast.success("Contract created successfully !!!");

        // Handle image uploads if any
        if (showSelectedImages.length !== 0) {
          const photos = new FormData();

          compressedImages.forEach((imageData, index) => {
            try {
              const blob = base64ToBlob(imageData.compressedImage);
              photos.append("images", blob, `image_${index}.${imageData.type}`);
            } catch (error) {
              console.error("Error converting image to blob:", error);
            }
          });

          const imageUploadResponse = await axios.post(
            `${process.env.REACT_APP_URL}${UrlRoute.SELL_URL}/${userId}/upload-image/${contractId}`,
            photos,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );

          if (imageUploadResponse.status === 200) {
            toast.success("Contract images uploaded successfully !!!");
          } else {
            toast.error(
              "Contract created successfully, but there was an error uploading images. Please upload them again in Edit Contract!"
            );
          }
        }

        // Handle PDF uploads if any
        if (selectedPdfFiles.length !== 0) {
          const formData = new FormData();

          for (let i = 0; i < selectedPdfFiles.length; i++) {
            formData.append("attachments", selectedPdfFiles[i]);
          }

          const attachmentUploadResponse = await axios.post(
            `${process.env.REACT_APP_URL}${UrlRoute.SELL_URL}/${userId}/upload-attachment/${contractId}`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );

          if (attachmentUploadResponse.status === 200) {
            toast.success("Contract PDF files uploaded successfully !!!");
          } else {
            toast.error(
              "Contract created successfully, but there was an error uploading attachments. Please upload them again in Edit Contract!"
            );
          }
        }

        navigate(Constants.HOME_PAGE);; // Redirect after success
      } else {
        throw new Error("Contract creation failed");
      }
    } catch (error) {
      console.error("Error during contract creation:", error);
      toast.error("Something went wrong!");
    } finally {
      setLoading(false);
      setIsApiCallInProgress(false);
    }
  };

  const validateForm = () => {
    let valid = true;

    if (title.trim() === "") {
      toast.error("Contract Title cannot be empty");
      valid = false;
    }

    if (category.trim() === "") {
      toast.error("Product Category cannot be empty");
      valid = false;
    }

    if (subCategory.trim() === "") {
      toast.error("Product Name cannot be empty");
      valid = false;
    }

    if (description.trim() === "") {
      toast.error("Description cannot be empty");
      valid = false;
    }

    if (brandInputValue.trim() === "") {
      toast.error("Brand Name cannot be empty");
      valid = false;
    }

    if (country.trim() === "") {
      toast.error("Country cannot be empty");
      valid = false;
    }

    if (showSelectedImages.length > 5) {
      toast.error("You can not upload more than five images");
      valid = false;
    }

    if (selectedPdfFiles.length > 5) {
      toast.error("You can not upload more than five attachments");
      valid = false;
    }

    if (pinCode.trim() !== "") {
      // Check if pincodes contains only numbers
      const numericRegex = /^[0-9]+$/;

      if (!numericRegex.test(pinCode)) {
        toast.error("Invalid PinCode. Please enter only numbers.");
        valid = false;
      } else if (pinCode.length !== 6) {
        toast.error("PinCode must be 6 digits long");
        valid = false;
      }
    }

    if (comment === "") {
      toast.error("please add a comment");
      valid = false;
    }

    return valid;
  };

  const handleSellContractSubmit = () => {
    if (validateForm()) {
      submitContractData();
    }
  };

  return (
    <>
      <MainLayout>
        <Container
          maxWidth="md"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 0.5,
            overflowY: "auto",
            width: "100%",
            p: 0,
          }}
        >
          {" "}
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Paper elevation={2} className="sellcontract-paper-container">
                <Box display="flex" flexDirection="column" gap={2}>
                  <Box flex={1}>
                    <TextField
                      fullWidth
                      required
                      label="Contract title"
                      name="title"
                      value={title}
                      onChange={(event) => {
                        const inputValue = event.target.value;
                        const formattedValue = inputValue
                          .split(" ")
                          .map((word) => {
                            if (word === word.toUpperCase()) {
                              return word;
                            } else {
                              return (
                                word.charAt(0).toUpperCase() +
                                word.slice(1).toLowerCase()
                              );
                            }
                          })
                          .join(" ");

                        setTitle(formattedValue);
                      }}
                      autoComplete="off"
                      id="contract-title"
                      variant="outlined"
                    />
                  </Box>
                  <Box flex={1}>
                    <FormControl fullWidth>
                      <InputLabel
                        id="category-select-label"
                        htmlFor="category-picker"
                      >
                        Product Category
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="category-label"
                        id="category-picker"
                        label="Select Category"
                        value={category}
                        onChange={handleCategoryChange}
                      >
                        <MenuItem value={""}>Select Category</MenuItem>
                        {categoryOptions.map((choice, index) => {
                          return (
                            <MenuItem
                              value={choice.categoryLink?.rel}
                              key={index}
                            >
                              {choice.categoryLink?.rel}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box flex={1}>
                    <FormControl fullWidth>
                      <InputLabel id="subCategory-label">
                        Product Name
                      </InputLabel>
                      <Select
                        labelId="subCategory-label"
                        id="subCategory"
                        value={subCategory}
                        label="Product Name"
                        onChange={handleSubCategoryChange}
                        disabled={!category}
                      >
                        {subCategoryChoices &&
                          subCategoryChoices[0].map((option, index) => (
                            <MenuItem key={index} value={option.rel}>
                              {option.rel}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box flex={1}>
                    <TextField
                      fullWidth
                      label="Contract Description"
                      name="contractdescription"
                      value={description}
                      onChange={(event) =>
                        setDescription(
                          event.target.value
                            .toLowerCase()
                            .split(" ")
                            .map((word) => {
                              return (
                                word.charAt(0).toUpperCase() + word.slice(1)
                              );
                            })
                            .join(" ")
                        )
                      }
                      autoComplete="off"
                      multiline
                      minRows={3}
                      id="contractdescription"
                      variant="outlined"
                    />
                  </Box>
                  <Box flex={1}>
                    <Autocomplete
                      freeSolo
                      id="brand"
                      disableClearable
                      options={brandOptions.map((option) => option)}
                      value={brandName}
                      onChange={(event, newValue) => {
                        setBrandName(newValue);
                      }}
                      inputValue={brandInputValue}
                      onInputChange={(event, newInputValue) => {
                        const capitalizedBrandInput = newInputValue
                          .toLowerCase()
                          .split(" ")
                          .map((word) => {
                            return word.charAt(0).toUpperCase() + word.slice(1);
                          })
                          .join(" ");

                        setBrandInputValue(capitalizedBrandInput);

                        if (
                          !brandOptions.find(
                            (option) => option === capitalizedBrandInput
                          )
                        ) {
                          getBrandNamesData(capitalizedBrandInput);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Brand Name"
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Box flex={1}>
                    <Autocomplete
                      freeSolo
                      id="specialityname"
                      disableClearable
                      options={specialityOptions.map((option) => option)}
                      value={specialityName}
                      onChange={(event, newValue) => {
                        setSpecialityName(newValue);
                        if (
                          !specialityData.some(
                            (item) => item.suitableFor === newValue
                          )
                        ) {
                          handleAddSpecialities(newValue);
                        }
                      }}
                      inputValue={specialityInputValue}
                      onInputChange={(event, newInputValue) => {
                        const capitalizedInput = newInputValue
                          .toLowerCase()
                          .split(" ")
                          .map((word) => {
                            return word.charAt(0).toUpperCase() + word.slice(1);
                          })
                          .join(" ");

                        setSpecialityInputValue(capitalizedInput);

                        if (!specialityOptions.includes(capitalizedInput)) {
                          getSpecialitiesData(capitalizedInput);
                        }
                      }}
                      onBlur={() => {
                        if (
                          !specialityOptions.find(
                            (option) => option === specialityInputValue
                          )
                        ) {
                          handleAddSpecialities(specialityInputValue);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Suitable For"
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                          }}
                        />
                      )}
                    />
                  </Box>
                  {specialityData.length > 0 && (
                    <Box container direction="column">
                      <Box
                        container
                        direction="row"
                        style={{ padding: "10px", marginTop: "10px" }}
                      >
                        <Box item>
                          <Paper
                            elevation={0}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              flexWrap: "wrap",
                              listStyle: "none",
                              backgroundColor: "transparent",
                              p: 0.5,
                              m: 0,
                            }}
                            component="ul"
                          >
                            {specialityData.map((data, index) => {
                              return (
                                <ListItem key={index}>
                                  <Chip
                                    variant="outlined"
                                    color="primary"
                                    style={{
                                      backgroundColor:
                                        theme.palette.mode === "light"
                                          ? "#fff"
                                          : "#292929",
                                      color:
                                        theme.palette.mode === "light"
                                          ? "#000"
                                          : "#fff",
                                    }}
                                    label={data.suitableFor}
                                    onDelete={() =>
                                      data.suitableFor === ""
                                        ? undefined
                                        : handleSpecialityDelete(
                                            data.suitableFor
                                          )
                                    }
                                  />
                                </ListItem>
                              );
                            })}
                          </Paper>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>

                {/* ############################  contract document section ######################################### */}

                <Box display="flex" flexDirection="column" gap={2}>
                  <Typography
                    color="text.primary"
                    gutterBottom
                    className="sell-contract-label"
                    style={{ marginTop: "20px", marginBottom: "20px" }}
                  >
                    Attachments
                  </Typography>
                </Box>

                {(showSelectedImages.length < 5 ||
                  selectedPdfFiles.length < 5) && (
                  <Box
                    container
                    direction="column"
                    justifyContent="center"
                    align="center"
                    className="sell-contract-upload-Container"
                  >
                    <Box flex={1}>
                      <IconButton component="label" style={{ padding: "0px" }}>
                        <input
                          hidden
                          accept="image/*,.pdf"
                          multiple
                          type="file"
                          onChange={handleFileChange}
                        />
                        <CloudUploadIcon
                          color="primary"
                          className="sell-contract-upload-Icon"
                        />
                      </IconButton>
                    </Box>
                    <Box>
                      <Typography
                        variant="subtitle1"
                        color="text.primary"
                        align="center"
                        gutterBottom
                      >
                        Upload Documents
                      </Typography>
                    </Box>
                  </Box>
                )}

                <div className="contract-gallery">
                  {showSelectedImages.map((photo, index) => {
                    return (
                      <div key={photo} style={{ position: "relative" }}>
                        <img
                          src={photo}
                          alt="upload"
                          height="100%"
                          width="100%"
                        />
                        <CloseIcon
                          style={{
                            position: "absolute",
                            top: "6px",
                            right: "6px",
                            fontSize: fullScreen ? "18px" : "22px",
                            color: "red",
                            cursor: "pointer",
                          }}
                          onClick={() => handleImageDelete(index)}
                        />
                      </div>
                    );
                  })}
                </div>

                {selectedPdfFiles.map((item, index) => {
                  return (
                    <div className="contract-pdf-container" key={index}>
                      <PictureAsPdfIcon className="contract-pdf-icon" />
                      <Typography
                        variant="subtitle1"
                        className="contract-pdf-filename"
                      >
                        {item.name}
                      </Typography>
                      <ClearIcon
                        className="contract-pdf-icon"
                        onClick={() => handlePdfFileDelete(index)}
                      />
                    </div>
                  );
                })}

                {/* ############################  Contract packages section ######################################### */}
                <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                  <div className="repair-sell-contract-container">
                    <div className="add-icon-container">
                      <Typography
                        align="left"
                        variant="subtitle1"
                        color="text.primary"
                        className="sell-contract-label"
                      >
                        Contract Packages
                      </Typography>
                    </div>

                    <IconButton
                      style={{ padding: "0px" }}
                      onClick={handleCreatePackageDialogClickOpen}
                    >
                      <AddIcon color="primary" size="large" />
                    </IconButton>
                  </div>
                  <Box display="flex" flexDirection="column" gap={2}>
                    {packageData.map((value, index) => {
                      return (
                        <Paper
                          elevation={4}
                          className="contract-package-container"
                          key={index}
                        >
                          <div className="package-container">
                            <div className="package_details">
                              <Typography
                                align="left"
                                color="text.primary"
                                className="text-capital contract-title"
                              >
                                {value.packageName}
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                align="left"
                                color="text.primary"
                                className="text-capital contract-subtitle"
                              >
                                {value.duration} {value.contractTerm}
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                align="left"
                                color="text.primary"
                                className="text-capital contract-description"
                              >
                                &#8377; {value.price}
                              </Typography>
                            </div>
                            <div className="package_iconbtn_container">
                              <IconButton
                                style={{ padding: "0px" }}
                                onClick={() => {
                                  handlePackageDelete(index);
                                }}
                              >
                                <DeleteIcon
                                  color="primary"
                                  style={{ verticalAlign: "middle" }}
                                />
                              </IconButton>
                            </div>
                          </div>
                        </Paper>
                      );
                    })}
                  </Box>

                  {/* ############################ Create Sellproduct Package dialog ######################################### */}
                  <Dialog
                    open={openCreatePackageDialog}
                    onClose={handleCreatePackageDialogClickClose}
                    fullScreen={fullScreen}
                  >
                    <DialogTitle
                      style={{ backgroundColor: "#00B9F1", color: "white" }}
                    >
                      <IconButton
                        edge="start"
                        onClick={handleCreatePackageDialogClickClose}
                        sx={{
                          position: "absolute",
                          right: 8,
                          top: 8,
                        }}
                      >
                        <CloseIcon style={{ color: "white" }} />
                      </IconButton>
                      Add Package
                    </DialogTitle>
                    <DialogContent>
                      <Box
                        display="flex"
                        flexDirection="column"
                        gap={2}
                        style={{ marginTop: "10px", marginBottom: "10px" }}
                      >
                        <Box flex={1}>
                          <TextField
                            fullWidth
                            label="Package Name"
                            name="packagename"
                            value={addPackageName}
                            onChange={(event) => {
                              const inputValue = event.target.value;
                              const formattedValue = inputValue
                                .split(" ")
                                .map((word) => {
                                  if (word === word.toUpperCase()) {
                                    return word;
                                  } else {
                                    return (
                                      word.charAt(0).toUpperCase() +
                                      word.slice(1).toLowerCase()
                                    );
                                  }
                                })
                                .join(" ");

                              setAddPackageName(formattedValue);
                            }}
                            autoComplete="off"
                            id="packagename"
                            variant="outlined"
                          />
                        </Box>
                        <Box flex={1}>
                          <TextField
                            label="Term"
                            select
                            value={term}
                            onChange={handleChange}
                            fullWidth
                          >
                            <MenuItem value="MONTHS">Months</MenuItem>
                            <MenuItem value="YEARS">Years</MenuItem>
                          </TextField>
                        </Box>
                        <Box flex={1}>
                          <TextField
                            fullWidth
                            label="Duration"
                            name="duration"
                            type="tel"
                            value={duration}
                            onChange={(event) =>
                              setDuration(event.target.value)
                            }
                            autoComplete="off"
                            id="duration"
                            variant="outlined"
                          />
                        </Box>
                        <Box flex={1}>
                          <TextField
                            fullWidth
                            label="Price"
                            name="price"
                            type="tel"
                            value={price}
                            onChange={(event) => setPrice(event.target.value)}
                            autoComplete="off"
                            id="price"
                            variant="outlined"
                          />
                        </Box>
                      </Box>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={handleAddPackages}
                        fullWidth
                        variant="contained"
                        className="contract-button"
                        color="primary"
                      >
                        Save
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>

                <Box display="flex" flexDirection="column" gap={2}>
                  <Box flex={1}>
                    <Typography
                      align="left"
                      color="text.primary"
                      className="sell-contract-label"
                      style={{ marginTop: "10px", marginBottom: "10px" }}
                    >
                      Can be sold at
                    </Typography>
                  </Box>
                  <Box flex={1}>
                    <FormControl fullWidth>
                      <InputLabel
                        id="country-select-label"
                        htmlFor="contract-country"
                      >
                        Country
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="country-label"
                        id="contract-country"
                        label="Select Country"
                        value={country}
                        onChange={(event) => {
                          setCountry(event.target.value);
                          setStateChoices([]);
                          setCityChoices([]);
                          getStatesData(event.target.value);
                        }}
                      >
                        <MenuItem value={""} selected>
                          Select Country
                        </MenuItem>
                        {Array.isArray(countryChoices) &&
                          countryChoices.map((choice, index) => {
                            return (
                              <MenuItem value={choice} key={index}>
                                {choice}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box flex={1}>
                    <FormControl fullWidth>
                      <InputLabel
                        id="state-select-label"
                        htmlFor="contract-state"
                      >
                        State (Optional)
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="state-label"
                        id="contract-state"
                        label="Select State"
                        value={state}
                        onChange={(event) => {
                          setState(event.target.value);
                          setCityChoices([]);
                          getCityData(country, event.target.value);
                        }}
                      >
                        <MenuItem value={""}>Select State</MenuItem>
                        {Array.isArray(stateChoices) &&
                          stateChoices.map((choice, index) => {
                            return (
                              <MenuItem value={choice} key={index}>
                                {choice}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box flex={1}>
                    <FormControl fullWidth>
                      <InputLabel
                        id="demo-dialog-select-label"
                        htmlFor="contract-city"
                      >
                        City (Optional)
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="city-label"
                        id="contract-city"
                        label="Select City"
                        value={city}
                        onChange={(event) => setCity(event.target.value)}
                      >
                        <MenuItem value={""}>Select City</MenuItem>
                        {Array.isArray(cityChoices) &&
                          cityChoices.map((choice, index) => {
                            return (
                              <MenuItem value={choice} key={index}>
                                {choice}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box flex={1}>
                    <TextField
                      fullWidth
                      label="Pincode (Optional)"
                      name="pincode"
                      value={pinCode}
                      onChange={(event) => setPinCode(event.target.value)}
                      autoComplete="off"
                      id="pincode"
                      variant="outlined"
                    />
                  </Box>
                  <Box>
                    <TextField
                      label="Add a comment"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      sx={{ margin: 0 }}
                    />
                  </Box>
                </Box>
              </Paper>

              {!isApiCallInProgress && (
                <Box
                  container
                  direction="row"
                  style={{ padding: fullScreen ? "0px 6px" : "0px 10px" }}
                >
                  <Box flex={1}>
                    <Button
                      variant="contained"
                      fullWidth
                      className="contract-button"
                      color="primary"
                      onClick={handleSellContractSubmit}
                    >
                      {" "}
                      Sell Contract{" "}
                    </Button>
                  </Box>
                </Box>
              )}

              {/* Verification Alert Dialog */}
              <VerificationAlertDialog
                openAlertVerificationDialog={openAlertVerificationDialog}
                handleAlertVerificationDialogClickClose={
                  handleAlertVerificationDialogClickClose
                }
                message="Currently, you cannot post a Contract because you are not a verified user."
              />
            </>
          )}
        </Container>
      </MainLayout>
    </>
  );
};

export default SellContract;
