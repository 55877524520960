import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid2";
import {
  Select,
  FormLabel,
  Chip,
  IconButton,
  TextField,
  Paper,
  Button,
  Autocomplete,
  Typography,
  FormControlLabel,
  InputLabel,
  MenuItem,
  FormControl,
  Radio,
  Box,
  RadioGroup,
  Container,
  CircularProgress,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import VerificationAlertDialog from "../../../utils/VerificationAlertDialog";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import "./sellproductpage.css";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import useGetData from "../../../API/useGetData";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import { fileCompressor } from "../../FileCompressor";
import UrlRoute from "../../../API/UrlRoute";
import Constants from "../../../MeddyConnectConstant";
import axios from "axios";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import MainLayout from "../../MainLayout";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const SellProduct = () => {
  const theme = useTheme();
  const { countryCode } = "+91";
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [condition, setCondition] = React.useState("");
  const [country, setCountry] = useState("");
  const { authToken, searchedUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
  const userId = searchedUser?.userId;
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [stateChoices, setStateChoices] = useState("");
  const [cityChoices, setCityChoices] = useState("");
  const [countryChoices, setCountryChoices] = useState("");
  const [pincodes, setPincodes] = useState("");
  const [value, setValue] = React.useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [numberOfItems, setNumberOfItems] = useState("");
  const [showSelectedImages, setShowSelectedImages] = useState([]);
  const [compressedImages, setCompressedImages] = useState([]);
  const [subCategory, setSubCategory] = useState("");
  const [subCategoryChoices, setSubCategoryChoices] = useState("");
  const [category, setCategory] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [brandName, setBrandName] = useState(null);
  const [brandInputValue, setBrandInputValue] = useState("");
  const [brandOptions, setBrandOptions] = useState([]);
  const [specialityData, setSpecialityData] = useState([]);
  const [specialityName, setSpecialityName] = useState(null);
  const [specialityInputValue, setSpecialityInputValue] = useState("");
  const [specialityOptions, setSpecialityOptions] = useState([]);
  const navigate = useNavigate();
  const [manufacturingYear, setManufacturingYear] = useState(new Date());
  const [comment, setComment] = useState("");
  const [searchNumber, setSearchNumber] = useState("");
  const [openCreatePackageDialog, setOpenCreatePackageDialog] =
    React.useState(false);
  const [openAlertVerificationDialog, setOpenAlertVerificationDialog] =
    useState(false);

  // Services
  const {
    getProfession,
    getCountry,
    getStates,
    getCity,
    getBrandNames,
    getSpecialities,
    getProductCategories,
  } = useGetData();

  const handleChange = (event) => {
    setCondition(event.target.value);
  };

  const handleRadioButtonChange = (event) => {
    setValue(event.target.value);
  };

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    getReferenceData();
    getCategories();
    getBrandNamesData("");
  }, []);

  useEffect(() => {
    getSpecialitiesData("");
  }, [specialityData]);

  useEffect(() => {
    if (searchedUser.identityVerified === true) {
      getReferenceData();
    } else {
      handleAlertVerificationDialogClickOpen();
    }
  }, [searchedUser.identityVerified]);

  const handleAlertVerificationDialogClickOpen = () => {
    setOpenAlertVerificationDialog(true);
  };

  const handleAlertVerificationDialogClickClose = () => {
    setOpenAlertVerificationDialog(false);
  };

  const getReferenceData = async () => {
    const countryOptions = await getCountry();
    if (countryOptions) {
      setCountry("");
      setCountryChoices(countryOptions);
    }
  };

  const getStatesData = async (country) => {
    if (country && country != "") {
      let stateOptions = [];
      stateOptions = await getStates(country);
      if (stateOptions) {
        setState("");
        setStateChoices(stateOptions);
      }
    }
  };

  const getCityData = async (country, state) => {
    if (country && country != "" && state && state != "") {
      let cityOptions = [];
      cityOptions = await getCity(country, state);
      if (cityOptions) {
        setCity("");
        setCityChoices(cityOptions);
      }
    }
  };

  const handleImageChange = async (e) => {
    setLoading(true);
    const files = e.target.files;
    const selectedImagesArray = Array.from(files);
    const compressedImagesArray = [];

    if (selectedImagesArray.length > 5) {
      setLoading(false);
      toast.error("You can not upload more than five images");
      return;
    }

    const showImages = selectedImagesArray.map((file) =>
      URL.createObjectURL(file)
    );
    setShowSelectedImages((prevImages) => prevImages.concat(showImages));

    for (const image of selectedImagesArray) {
      try {
        const compressedImage = await fileCompressor(image);
        const imageData = {
          type: image.type,
          compressedImage: compressedImage,
        };
        compressedImagesArray.push(imageData);
      } catch (error) {
        setLoading(false);
        console.error("Error compressing image:", error);
      }
    }

    setCompressedImages((prevCompressedImages) => [
      ...prevCompressedImages,
      ...compressedImagesArray,
    ]);
    setLoading(false);
  };

  const handleImageDelete = (id) => {
    const newImages = showSelectedImages.filter((photo, index) => index !== id);
    setShowSelectedImages(newImages);

    const fileCompressedArray = compressedImages.filter(
      (photo, index) => index !== id
    );
    setCompressedImages(fileCompressedArray);
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
    handleCategoryWiseFilter(event.target.value);
    setSubCategory("");
  };

  const handleCategoryWiseFilter = (category) => {
    let selectedCategory = categoryOptions.filter(
      (item) => item.categoryLink?.rel === category
    );
    let selectedSubCategory = selectedCategory.map((item) => {
      return item.subcategoryLink;
    });
    setSubCategoryChoices(selectedSubCategory);
  };

  const handleSubCategoryChange = (event) => {
    setSubCategory(event.target.value);
  };

  const getCategories = async () => {
    const categoriesOptions = await getProductCategories();
    if (categoriesOptions) {
      setCategoryOptions(categoriesOptions);
    }
  };

  const getBrandNamesData = async (brand) => {
    const brandOptions = await getBrandNames(brand);
    if (brandOptions) {
      setBrandName(null);
      setBrandOptions(brandOptions);
    }
  };

  const getSpecialitiesData = async (speciality) => {
    const specialityOptions = await getSpecialities(speciality);
    if (specialityOptions) {
      setSpecialityOptions(specialityOptions);
    }
  };

  const handleAddSpecialities = (newSpeciality) => {
    if (
      newSpeciality &&
      newSpeciality.trim() !== "" &&
      !specialityData.some((item) => item.suitableFor === newSpeciality)
    ) {
      setSpecialityData((prevSpecialityData) => [
        ...prevSpecialityData,
        { suitableFor: newSpeciality },
      ]);
      setSpecialityInputValue("");
      setSpecialityName(null);
    }
  };

  const handleSpecialityDelete = (specialityname) => {
    setSpecialityData(
      specialityData.filter((chips) => chips.suitableFor !== specialityname)
    );
  };

  function base64ToBlob(base64String) {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray]);
  }

  const createProductData = async () => {
    try {
      setIsApiCallInProgress(true);
      setLoading(true);

      const manufacturingYearInNumber = manufacturingYear
        ? manufacturingYear.getFullYear()
        : null;

      const apiUrl = `${process.env.REACT_APP_URL}${UrlRoute.SELL_URL}/${userId}`;

      // Create the request body for the product creation API
      const requestBody = {
        itemType: "PRODUCT",
        title: title,
        description: description,
        category: category,
        product: subCategory,
        brand: brandInputValue,
        price: price,
        noOfItems: numberOfItems,
        manufacturingYear: manufacturingYearInNumber,
        workingCondition: condition,
        isUsed: value,
        country: country,
        state: state,
        city: city,
        pinCode: pincodes,
        itemSuitabilities: specialityData,
        comment: comment,
      };

      // Make the API request to create the product
      const response = await axios.post(apiUrl, requestBody, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      });

      // Check if the product was created successfully
      if (response.status === 200) {
        const productId = response.data.id; // Assuming the response contains the created product ID
        toast.success("Product created successfully !!!");

        // If images were selected, proceed with uploading them
        if (showSelectedImages.length !== 0) {
          const photos = new FormData();

          // Iterate over the compressedImages array and append each image to the FormData
          compressedImages.forEach((imageData, index) => {
            try {
              const blob = base64ToBlob(imageData.compressedImage); // Convert base64 string to blob
              photos.append(
                "images",
                blob,
                `image_${index}.${imageData.type}` // Append the blob with a filename
              );
            } catch (error) {
              console.error("Error converting image:", error);
            }
          });

          // Make the API request to upload the images
          const imageUploadResponse = await axios.post(
            `${process.env.REACT_APP_URL}${UrlRoute.SELL_URL}/${userId}/upload-image/${productId}`,
            photos,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "multipart/form-data", // Important for uploading files
              },
            }
          );

          if (imageUploadResponse.status === 200) {
            toast.success("Product images uploaded successfully !!!");
            setLoading(false);
            setIsApiCallInProgress(false);
            navigate(Constants.HOME_PAGE);; // Redirect or perform other actions after success
          } else {
            setLoading(false);
            setIsApiCallInProgress(false);
            toast.error(
              "Product created successfully, but there was an error uploading images. Please upload them again in Edit Product!"
            );
          }
        } else {
          // No images to upload, simply finish the process
          setLoading(false);
          setIsApiCallInProgress(false);
          navigate("/"); // Redirect or perform other actions after success
        }
      } else {
        throw new Error("Product creation failed");
      }
    } catch (error) {
      console.error("Error during product creation:", error);
      setLoading(false);
      setIsApiCallInProgress(false);
      toast.error("Something went wrong!");
    }
  };

  const validateForm = () => {
    let valid = true;

    if (!value) {
      toast.error("Please select whether the product is New or Used");
      valid = false;
    }

    if (title.trim() === "") {
      toast.error("Product Title cannot be empty");
      valid = false;
    }

    if (brandInputValue.trim() === "") {
      toast.error("Brand cannot be empty");
      valid = false;
    }

    if (description.trim() === "") {
      toast.error("Description cannot be empty", { appearance: "error" });
      valid = false;
    }

    if (price.trim() === "") {
      toast.error("Price cannot be empty");
      valid = false;
    } else if (!/^[0-9]*$/.test(price)) {
      toast.error("Price must contain only numbers");
      valid = false;
    }

    if (category.trim() === "") {
      toast.error("Product Category cannot be empty");
      valid = false;
    }

    if (subCategory.trim() === "") {
      toast.error("Product Name cannot be empty");
      valid = false;
    }

    if (!condition) {
      toast.error("Please select the working condition");
      valid = false;
    }

    if (numberOfItems.trim() !== "") {
      if (!/^[0-9]*$/.test(numberOfItems)) {
        toast.error("Number of Items must contain only numbers");
        valid = false;
      }
    }

    if (country.trim() === "") {
      toast.error("Country cannot be empty");
      valid = false;
    }

    if (pincodes.trim() !== "") {
      // Check if pincodes contains only numbers
      const numericRegex = /^[0-9]+$/;

      if (!numericRegex.test(pincodes)) {
        toast.error("Invalid PinCode. Please enter only numbers.");
        valid = false;
      } else if (pincodes.length !== 6) {
        toast.error("PinCode must be 6 digits long");
        valid = false;
      }
    }

    if (showSelectedImages.length > 5) {
      toast.error("You can not upload more than five images");
      valid = false;
    }
    if (comment === "") {
      toast.error("please add a comment");
      valid = false;
    }

    return valid;
  };

  const handleSellProductSubmit = () => {
    if (validateForm()) {
      createProductData();
    }
  };

  return (
    <>
      <MainLayout>
        <Container
          maxWidth="md"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 0.5,
            overflowY: "auto",
            width: "100%",
            p: 0,
          }}
        >
          {" "}
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Paper elevation={2} className="sellproduct-paper-container">
                <Box display="flex" flexDirection="column" gap={2}>
                  <Box flex={1}>
                    <FormControl>
                      <FormLabel
                        id="sell-product-radio-buttons-group"
                        style={{
                          color:
                            theme.palette.mode === "light" ? "#000000" : "#fff",
                        }}
                        className="sell-product-label"
                      >
                        Product is ?
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="sell-product-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={value}
                        onChange={handleRadioButtonChange}
                      >
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="New"
                        />
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="Used"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box flex={1}>
                    <TextField
                      fullWidth
                      required
                      label="Product Title"
                      name="title"
                      value={title}
                      onChange={(event) => {
                        const inputValue = event.target.value;
                        const formattedValue = inputValue
                          .split(" ")
                          .map((word) => {
                            if (word === word.toUpperCase()) {
                              return word;
                            } else {
                              return (
                                word.charAt(0).toUpperCase() +
                                word.slice(1).toLowerCase()
                              );
                            }
                          })
                          .join(" ");

                        setTitle(formattedValue);
                      }}
                      autoComplete="off"
                      id="title"
                      variant="outlined"
                    />
                  </Box>
                  <Box flex={1}>
                    <FormControl fullWidth>
                      <InputLabel
                        id="category-select-label"
                        htmlFor="category-picker"
                      >
                        Product Category
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="category-label"
                        id="category-picker"
                        label="Select Category"
                        value={category}
                        onChange={handleCategoryChange}
                      >
                        <MenuItem value={""}>Select Category</MenuItem>
                        {categoryOptions.map((choice, index) => {
                          return (
                            <MenuItem
                              value={choice.categoryLink?.rel}
                              key={index}
                            >
                              {choice.categoryLink?.rel}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box flex={1}>
                    <FormControl fullWidth>
                      <InputLabel id="subCategory-label">
                        Product Name
                      </InputLabel>
                      <Select
                        labelId="subCategory-label"
                        id="subCategory"
                        value={subCategory}
                        label="Product Name"
                        onChange={handleSubCategoryChange}
                        disabled={!category}
                      >
                        {subCategoryChoices &&
                          subCategoryChoices[0].map((option, index) => (
                            <MenuItem key={index} value={option.rel}>
                              {option.rel}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box flex={1}>
                    <Autocomplete
                      freeSolo
                      id="brandname"
                      disableClearable
                      options={brandOptions.map((option) => option)}
                      value={brandName}
                      onChange={(event, newValue) => {
                        setBrandName(newValue);
                      }}
                      inputValue={brandInputValue}
                      onInputChange={(event, newInputValue) => {
                        const capitalizedBrandInput = newInputValue
                          .toLowerCase()
                          .split(" ")
                          .map((word) => {
                            return word.charAt(0).toUpperCase() + word.slice(1);
                          })
                          .join(" ");

                        setBrandInputValue(capitalizedBrandInput);

                        if (
                          !brandOptions.find(
                            (option) => option === capitalizedBrandInput
                          )
                        ) {
                          getBrandNamesData(capitalizedBrandInput);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Brand Name"
                          slotProps={{
                            input: {
                              ...params.InputProps,
                              type: "search",
                            },
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Box flex={1}>
                    <TextField
                      fullWidth
                      label="Product Description"
                      name="description"
                      value={description}
                      onChange={(event) =>
                        setDescription(
                          event.target.value
                            .toLowerCase()
                            .split(" ")
                            .map((word) => {
                              return (
                                word.charAt(0).toUpperCase() + word.slice(1)
                              );
                            })
                            .join(" ")
                        )
                      }
                      autoComplete="off"
                      multiline
                      minRows={3}
                      id="description"
                      variant="outlined"
                    />
                  </Box>
                  <Box flex={1}>
                    <TextField
                      fullWidth
                      label="Price"
                      name="price"
                      type="tel"
                      value={price}
                      onChange={(event) => setPrice(event.target.value)}
                      autoComplete="off"
                      id="price"
                      variant="outlined"
                    />
                  </Box>
                  <Box flex={1}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        sx={{ width: "100% " }}
                        label="Manufacturing Year"
                        openTo="year"
                        views={["year"]}
                        value={manufacturingYear}
                        onChange={(newValue) => setManufacturingYear(newValue)}
                        disableFuture
                        minDate={new Date(currentYear - 60, 0, 1)} // Previous year
                        maxDate={new Date(currentYear, 11, 31)} // Current year
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box flex={1}>
                    <TextField
                      label="Working Condition"
                      select
                      value={condition}
                      onChange={handleChange}
                      fullWidth
                    >
                      <MenuItem value="WORKING">Working</MenuItem>
                      <MenuItem value="NON_WORKING">Not Working</MenuItem>
                      <MenuItem value="PARTIALLY_WORKING">
                        Partially Working
                      </MenuItem>
                    </TextField>
                  </Box>
                  <Box>
                    <TextField
                      fullWidth
                      label="No of Items"
                      name="items"
                      value={numberOfItems}
                      onChange={(event) => setNumberOfItems(event.target.value)}
                      autoComplete="off"
                      id="items"
                      variant="outlined"
                    />
                  </Box>
                  <Box flex={1}>
                    <Autocomplete
                      freeSolo
                      id="specialityname"
                      disableClearable
                      options={specialityOptions.map((option) => option)}
                      value={specialityName}
                      onChange={(event, newValue) => {
                        setSpecialityName(newValue);
                        if (
                          !specialityData.some(
                            (item) => item.suitableFor === newValue
                          )
                        ) {
                          handleAddSpecialities(newValue);
                        }
                      }}
                      inputValue={specialityInputValue}
                      onInputChange={(event, newInputValue) => {
                        const capitalizedInput = newInputValue
                          .toLowerCase()
                          .split(" ")
                          .map((word) => {
                            return word.charAt(0).toUpperCase() + word.slice(1);
                          })
                          .join(" ");

                        setSpecialityInputValue(capitalizedInput);

                        if (!specialityOptions.includes(capitalizedInput)) {
                          getSpecialitiesData(capitalizedInput);
                        }
                      }}
                      onBlur={() => {
                        if (
                          !specialityOptions.find(
                            (option) => option === specialityInputValue
                          )
                        ) {
                          handleAddSpecialities(specialityInputValue);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Suitable For"
                          slotProps={{
                            input: {
                              ...params.InputProps,
                              type: "search",
                            },
                          }}
                        />
                      )}
                    />
                  </Box>
                  {specialityData.length > 0 && (
                    <Box flex={1}>
                      <Grid
                        container
                        direction="row"
                        style={{ padding: "10px", marginTop: "10px" }}
                      >
                        <Grid item>
                          <Paper
                            elevation={0}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              flexWrap: "wrap",
                              listStyle: "none",
                              backgroundColor: "transparent",
                              p: 0.5,
                              m: 0,
                            }}
                            component="ul"
                          >
                            {specialityData &&
                              specialityData.map((data, index) => {
                                return (
                                  <ListItem key={index}>
                                    <Chip
                                      variant="outlined"
                                      color="primary"
                                      style={{
                                        backgroundColor:
                                          theme.palette.mode === "light"
                                            ? "#fff"
                                            : "#292929",
                                        color:
                                          theme.palette.mode === "light"
                                            ? "#000"
                                            : "#fff",
                                      }}
                                      label={data.suitableFor}
                                      onDelete={() =>
                                        data.suitableFor === ""
                                          ? undefined
                                          : handleSpecialityDelete(
                                              data.suitableFor
                                            )
                                      }
                                    />
                                  </ListItem>
                                );
                              })}
                          </Paper>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </Box>

                {/* ############################  product upload images section ######################################### */}

                <Box display="flex" flexDirection="column" gap={2}>
                  <Typography
                    color="text.primary"
                    gutterBottom
                    className="sell-product-label"
                    style={{ marginTop: "20px", marginBottom: "20px" }}
                  >
                    Product Images
                  </Typography>
                </Box>

                {showSelectedImages.length < 5 && (
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    className="sell-product-upload-container"
                  >
                    <Grid item>
                      <IconButton component="label" style={{ padding: "0px" }}>
                        <input
                          hidden
                          accept="image/*"
                          multiple
                          type="file"
                          onChange={handleImageChange}
                        />
                        <CloudUploadIcon
                          color="primary"
                          className="sell-product-upload-Icon"
                        />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="subtitle1"
                        color="text.primary"
                        align="center"
                        gutterBottom
                      >
                        Upload Images
                      </Typography>
                    </Grid>
                  </Grid>
                )}

                <div className="gallery">
                  {showSelectedImages.map((photo, index) => {
                    return (
                      <div key={photo} style={{ position: "relative" }}>
                        <img
                          src={photo}
                          alt="upload"
                          height="100%"
                          width="100%"
                        />
                        <CloseIcon
                          style={{
                            position: "absolute",
                            top: "6px",
                            right: "6px",
                            fontSize: fullScreen ? "18px" : "22px",
                            color: "red",
                            cursor: "pointer",
                          }}
                          onClick={() => handleImageDelete(index)}
                        />
                      </div>
                    );
                  })}
                </div>

                <Box
                  display="flex"
                  flexDirection="column"
                  gap={2}
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  <Box flex={1}>
                    <Typography
                      align="left"
                      color="text.primary"
                      className="sell-product-label"
                      style={{ marginTop: "10px", marginBottom: "-6px" }}
                    >
                      Can be sold at
                    </Typography>
                  </Box>
                  <Box flex={1}>
                    <FormControl fullWidth>
                      <InputLabel
                        id="country-select-label"
                        htmlFor="country-picker"
                      >
                        Country
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="country-label"
                        id="country-picker"
                        label="Select Country"
                        value={country}
                        onChange={(event) => {
                          setCountry(event.target.value);
                          setStateChoices([]);
                          setCityChoices([]);
                          getStatesData(event.target.value);
                        }}
                      >
                        <MenuItem value={""} selected>
                          Select Country
                        </MenuItem>
                        {Array.isArray(countryChoices) &&
                          countryChoices.map((choice, index) => {
                            return (
                              <MenuItem value={choice} key={index}>
                                {choice}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box flex={1}>
                    <FormControl fullWidth>
                      <InputLabel
                        id="state-select-label"
                        htmlFor="state-picker"
                      >
                        State (Optional)
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="state-label"
                        id="state-picker"
                        label="Select State"
                        value={state}
                        onChange={(event) => {
                          setState(event.target.value);
                          setCityChoices([]);
                          getCityData(country, event.target.value);
                        }}
                      >
                        <MenuItem value={""}>Select State</MenuItem>
                        {Array.isArray(stateChoices) &&
                          stateChoices.map((choice, index) => {
                            return (
                              <MenuItem value={choice} key={index}>
                                {choice}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl fullWidth>
                      <InputLabel
                        id="demo-dialog-select-label"
                        htmlFor="city-picker"
                      >
                        City (Optional)
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="city-label"
                        id="city-picker"
                        label="Select City"
                        value={city}
                        onChange={(event) => setCity(event.target.value)}
                      >
                        <MenuItem value={""}>Select City</MenuItem>
                        {Array.isArray(cityChoices) &&
                          cityChoices.map((choice, index) => {
                            return (
                              <MenuItem value={choice} key={index}>
                                {choice}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box flex={1}>
                    <TextField
                      fullWidth
                      label="Pincode (Optional)"
                      name="pincode"
                      value={pincodes}
                      onChange={(event) => setPincodes(event.target.value)}
                      autoComplete="off"
                      id="pincode"
                      variant="outlined"
                    />
                  </Box>
                  <Box>
                    <TextField
                      label="Add a comment"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      sx={{ margin: 0 }}
                    />
                  </Box>
                </Box>
              </Paper>
              {!isApiCallInProgress && (
                <Box display="flex" flexDirection="row" justifyContent="center">
                  <Box flex={1}>
                    <Button
                      variant="contained"
                      fullWidth
                      className="sellproduct-button"
                      color="primary"
                      onClick={handleSellProductSubmit}
                    >
                      Create Product
                    </Button>
                  </Box>
                </Box>
              )}

              {/* Verification Alert Dialog */}
              <VerificationAlertDialog
                openAlertVerificationDialog={openAlertVerificationDialog}
                handleAlertVerificationDialogClickClose={
                  handleAlertVerificationDialogClickClose
                }
                message="Currently, you cannot post a Contract because you are not a verified user."
              />
            </>
          )}
        </Container>
      </MainLayout>
    </>
  );
};

export default SellProduct;
