import { createContext, useState, useContext } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [authToken, setAuthToken] = useState(null);
    const [searchedUser, setSearchedUser] = useState({});

  
    const saveToken = (token) => {
      setAuthToken(token);
    };

    const saveSearchedUser = (userData) => {
      setSearchedUser(userData);
    };

    const logout = () => {
      setAuthToken(null);
      setSearchedUser({});
    };
  
    return (
      <AuthContext.Provider value={{ authToken, saveToken, logout, searchedUser, saveSearchedUser }}>
        {children}
      </AuthContext.Provider>
    );
  };
  
  export const useAuth = () => useContext(AuthContext);