import React, { useState, useEffect } from "react";
import {
  Typography, IconButton, Box, Avatar, Dialog, DialogTitle,Container,
  Button, DialogContent, DialogActions, Slide, Chip, CircularProgress,
} from '@mui/material';
import "./showinterested.css";
import { BsChat } from "react-icons/bs";
import { toast } from "react-toastify";
import PhoneIcon from '@mui/icons-material/Phone';
import { useTheme } from '@mui/material/styles';
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import Constants from "../../../MeddyConnectConstant";
import UrlRoute from "../../../API/UrlRoute";
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import MainLayout from "../../MainLayout";
import { useAuth } from "../../../context/AuthContext";
import axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ShowInterestedList = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { interestId } = useParams();

  const [interestedUserList, setInterestedUserList] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [interestedUserContactNumber, setInterestedUserContactNumber] = useState("");
  const { authToken, searchedUser } = useAuth();
  const userId = searchedUser?.userId;
  // Services

  // Dialogs
  const [openAlertVerificationDialog, setOpenAlertVerificationDialog] = React.useState(false);
  const [isInterestedUserCallDetailsDialogOpen, setIsInterestedUserCallDetailsDialogOpen] = useState(false);

  const handleAlertVerificationDialogClickOpen = () => {
    setOpenAlertVerificationDialog(true);
  };

  const handleAlertVerificationDialogClickClose = () => {
    setOpenAlertVerificationDialog(false);
  };

  const handleInterestedUserCallDetailsDialogClickOpen = () => {
    setIsInterestedUserCallDetailsDialogOpen(true);
  };

  const handleInterestedUserCallDetailsDialogClickClose = () => {
    setIsInterestedUserCallDetailsDialogOpen(false);
  };

  const handleVerifyClick = () => {
    navigate(Constants.VERIFICATION);
  };

  useEffect(() => {
    getInterestedUserList(interestId, pageNo);
  }, [])


  const getInterestedUserList = async (interestId, pageNo) => {
    setLoading(true)
    const apiUrl = `${process.env.REACT_APP_URL}items/interest/${userId}/${interestId}?page=${pageNo}`;

    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      setInterestedUserList((prevData) => {
        const uniqueEntities = response.data.entities.filter(newEntity =>
          !prevData.some(existingEntity => existingEntity.id === newEntity.id)
        );
        return [...prevData, ...uniqueEntities];
      });
      setTotalPages(response.data.totalPages);
      setPageNo(response.data.page);
      setLoading(false);
    } else {
      setLoading(false);
      toast.error("Something went wrong!");
    }
  };

  const getInterestedUserCallDetails = async (itemId, userId) => {
    setLoading(true)
     const apiUrl = `${process.env.REACT_APP_URL}${UrlRoute.GET_INTERESTED_USER_CALL_DETAILS_URL}/${userId}/${itemId}`;

    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      setInterestedUserContactNumber(response.data);
      setLoading(false);
      handleInterestedUserCallDetailsDialogClickOpen();
    } else if (response.status === 403) {
      setLoading(false);
      handleAlertVerificationDialogClickOpen();
    } else {
      setLoading(false);
      toast.error("Something went wrong!");
    }

  };

  return (
    <>
         <MainLayout>
        <Container
          maxWidth="md"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 0.5,
            overflowY: "auto",
            width: "100%",
            p: 0,
          }}
        >
          {" "}
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
        <div style={{ display: "flex" }}>

          <div className="show-interested-container">

            <div style={{ marginTop: "12px" }}>
              <InfiniteScroll
                style={{ overflow: "hidden" }}
                dataLength={interestedUserList.length}
                next={() => {
                  if (pageNo > 1) {
                    getInterestedUserList(interestId, pageNo + 1);
                  }
                }}
                hasMore={!(pageNo + 1 === totalPages)}
                // loader={<YLoader loading={loading} />}
                endMessage={<span></span>}
              >
                {interestedUserList.length > 0 ? (

                  interestedUserList.map((value, index) => {
                    return (
                      <div className="interested-user-list" key={value.id} style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929" }} onClick={() => getInterestedUserCallDetails(value.itemId, value.userId)} >
                        <Avatar src={value.profileLink ? value.profileLink.href : " "} className='interested-user-picture' />
                        <div className="interested-user-info">
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <Typography className='interested-user-Name interested-user_title' align="left" color="text.primary">
                              {value.name}
                            </Typography>
                            {value.userType === "VENDOR" && (
                              <Chip label="Distributor" color="primary" variant="outlined" style={{ marginLeft: '8px' }} />
                            )}
                          </div>
                          <Typography className='interested-user_subtitle1' align="left" color="text.primary">
                            {value.created ? moment(value.created).format("D-MMM-YYYY") : "N/A"}
                          </Typography>
                        </div>

                        <IconButton className="call-button" disableRipple>
                          <PhoneIcon color="primary" />
                        </IconButton>

                      </div>
                    );
                  })

                ) : (
                  <Box container style={{ marginTop: "10px" }}>
                    <Box item xs={12}>
                      <Typography align="center" color="text.primary" variant="h6" gutterBottom>
                        No results found
                      </Typography>
                    </Box>
                  </Box>
                )}

              </InfiniteScroll>

              <Dialog open={openAlertVerificationDialog} onClose={handleAlertVerificationDialogClickClose}>
                <DialogTitle >MeddyConnect</DialogTitle>
                <DialogContent>

                  <Typography variant="subtitle1" color="text.primary" align="left">
                    You cannot contact seller as you are not a verified user
                  </Typography>

                </DialogContent>
                <DialogActions>
                  <Button onClick={handleAlertVerificationDialogClickClose} color="primary" >Close</Button>
                  <Button onClick={handleVerifyClick} color="primary" >Verify</Button>
                </DialogActions>
              </Dialog>

              <Dialog open={isInterestedUserCallDetailsDialogOpen} onClose={handleInterestedUserCallDetailsDialogClickClose}
                maxWidth="sm" fullWidth TransitionComponent={Transition}
              >
                <DialogTitle >Interested User Call Details</DialogTitle>
                <DialogContent>

                  <Typography variant="subtitle1" color="text.primary" align="left">
                    <span className='bold-text'> Contact No : </span> <a href={`tel:${interestedUserContactNumber}`} style={{ color: theme.palette.mode === "light" ? "#002E6E" : "#00B9F1", textDecoration: "none" }}>{interestedUserContactNumber}</a>
                  </Typography>

                </DialogContent>
                <DialogActions>
                  <Button onClick={handleInterestedUserCallDetailsDialogClickClose} color="primary" >Close</Button>
                </DialogActions>
              </Dialog>

            </div>

          </div>
        </div>
          )}
          </Container>
          </MainLayout>
    </>
  )
}

export default ShowInterestedList


