import React from 'react'
import {
  AppBar, Toolbar, IconButton, Typography,
  Drawer, List, ListItem, ListItemText, Divider,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Constants from "../../MeddyConnectConstant";

const drawerWidth = 240;

const Navbar = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {/* Using Link for navigation */}
         {[
          { text: 'Home', route: Constants.HOME_PAGE },
        ].map(({ text, route }) => (
          <ListItem button={true} key={text} sx={{ cursor: 'pointer' }}>
            <Link to={route} style={{ textDecoration: 'none', color: 'inherit', width: '100%' }}>
              <ListItemText primary={text} />
            </Link>
          </ListItem>
        ))}
         {/* Log Out item, handled separately */}
         <ListItem button={true} onClick={handleLogout} sx={{ cursor: 'pointer' }}>
          <ListItemText primary="Log Out" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <>
      <AppBar position="fixed" sx={{ zIndex: theme.zIndex.drawer + 1 }}>
        <Toolbar>
          {/* Menu Icon for mobile screens */}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { lg: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            MeddyConnect
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Drawer for mobile and large screens */}
      <Drawer
        variant={isLargeScreen ? 'permanent' : 'temporary'}
        open={isLargeScreen || mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', lg: 'none' },
          '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        {drawer}
      </Drawer>

      {/* Permanent Drawer for Large Screens */}
      {isLargeScreen && (
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', lg: 'block' },
            '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box' },
          }}
          open
        >
          {drawer}
        </Drawer>
      )}
    </>
  )
}

export default Navbar