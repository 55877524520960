const Urls = {
  // Login
  GENERATE_TOKEN_URL: "token/generate-token",

  REFERENCES_PROFESSIONS_URL: "references/professions",
  REFERENCES_COUNTRIES_URL: "references/countries",
  PRODUCT_CATEGORIES_URL: "references/item-categories",

  // Verification
  UPLOAD_ITEM_IMAGES_URL: "items/upload-image",
  USER_VERIFICATION_DOCUMENT_URL: "identities",
  UPLOAD_ITEM_ATTACHMENTS_URL: "items/upload-attachment",

  // Packages
  GET_ACTIVE_PACKAGE_URL: "active-package",
  GET_ORDER_URL: "orders",
  GET_ALL_PACKAGES_URL: "references/packages",
  CHANGE_PACKAGE_URL: 'change-package',


  //Marketplace
  MANAGEINVENTORY_URL: "items/findByUser",
  SHOW_INTERESTED_USER: "items/interest",
  ACTIVE_PACKAGE_URL: "orders/active-package",
  GET_INTERESTED_USER_CALL_DETAILS_URL: "items/get-contact",

  // Job and Observership
  JOBS_URL: "jobs",
  OBSERVERSHIP_URL: "internships",
  SELL_URL: "items",
  GET_POSTED_JOBS_BY_USER_URL: "jobs/findActiveByUser",
  GET_POSTED_OBSERVERSHIPS_BY_USER_URL: "internships/findActiveByUser",
};

export default Urls;
