import React, { useState, useEffect } from "react";
import {
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  TextField,
  Paper,
  DialogTitle,
  IconButton,
  Typography,
  Button,
  MenuItem,
  Autocomplete,
  InputLabel,
  Select,
  FormControl,
  Container,
  CircularProgress,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import "./sellcontractpage.css";
import { toast } from "react-toastify";
import { useAuth } from "../../../context/AuthContext";
import useGetData from "../../../API/useGetData";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { fileCompressor } from "../../../components/FileCompressor";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useNavigate, useLocation } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import EditIcon from "@mui/icons-material/Edit";
import { FaRegFilePdf } from "react-icons/fa";
import PDFViewer from "../../../components/PDFViewer";
import Constants from "../../../MeddyConnectConstant";
import MainLayout from "../../MainLayout";
import axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const Updatesellcontract = (props) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { authToken, searchedUser } = useAuth();
  const userId = searchedUser?.userId;
  const location = useLocation();
  const contractId = location.state?.data;
  // const [updateContractId, setUpdateContractId] = useState(null);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [stateChoices, setStateChoices] = useState("");
  const [cityChoices, setCityChoices] = useState("");
  const [countryChoices, setCountryChoices] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [duration, setDuration] = useState("");
  const [addPackageName, setAddPackageName] = useState("");
  const [price, setPrice] = useState("");
  const [term, setTerm] = React.useState("");
  const [packageData, setPackageData] = React.useState([]);
  const [selectedPdfFiles, setSelectedPdfFiles] = useState([]);
  const [selectedPDF, setSelectedPDF] = useState({
    contentType: null,
    content: null,
  });
  const [uploadedAttachment, setUploadedAttachment] = useState([]);
  const [showSelectedImages, setShowSelectedImages] = useState([]);
  const [compressedImages, setCompressedImages] = useState([]);
  const [brandName, setBrandName] = useState(null);
  const [brandInputValue, setBrandInputValue] = useState("");
  const [brandOptions, setBrandOptions] = useState([]);
  const [specialityData, setSpecialityData] = useState([]);
  const [specialityName, setSpecialityName] = useState(null);
  const [specialityInputValue, setSpecialityInputValue] = useState("");
  const [specialityOptions, setSpecialityOptions] = useState([]);
  const [subCategory, setSubCategory] = useState("");
  const [subCategoryChoices, setSubCategoryChoices] = useState([]);
  const [category, setCategory] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([]);
  const navigate = useNavigate();
  const [editDuration, setEditDuration] = useState("");
  const [editPackageName, setEditPackageName] = useState("");
  const [editPrice, setEditPrice] = useState("");
  const [editTerm, setEditTerm] = React.useState("");
  const [contractPackageEditingIndex, setContractPackageEditingIndex] =
    useState(null);
  const [selectedImagesCount, setSelectedImagesCount] = useState(0);
  const [selectedPdfCount, setSelectedPdfCount] = useState(0);
  const [showUploadContainer, setShowUploadContainer] = useState(true);
  const [comment, setComment] = useState("");

  // Dialogs
  const [openCreatePackageDialog, setOpenCreatePackageDialog] =
    React.useState(false);
  const [openEditPackageDialog, setOpenEditPackageDialog] =
    React.useState(false);
  const [openDocumentDialog, setOpenDocumentDialog] = useState(false);

  // Services
  const {
    getBrandNames,
    getSpecialities,
    getProductCategories,
    getCountry,
    getStates,
    getCity,
  } = useGetData();

  useEffect(() => {
    getSellContract(contractId);
  }, []);

  useEffect(() => {
    getReferenceData();
    getBrandNamesData("");
    getCategories();
  }, []);

  useEffect(() => {
    getSpecialitiesData("");
  }, [specialityData]);

  useEffect(() => {
    if (category) {
      handleCategoryWiseFilter(category);
    }
  }, [category, categoryOptions]);

  useEffect(() => {
    // Show the container if either the image count or the PDF count is less than 5
    if (selectedImagesCount < 5 || selectedPdfCount < 5) {
      setShowUploadContainer(true);
    } else {
      setShowUploadContainer(false);
    }
  }, [selectedImagesCount, selectedPdfCount]);

  const handleCreatePackageDialogClickClose = () => {
    setOpenCreatePackageDialog(false);
  };

  const handleCreatePackageDialogClickOpen = () => {
    setOpenCreatePackageDialog(true);
  };

  const handleEditPackageDialogClickClose = () => {
    setOpenEditPackageDialog(false);
  };

  const handleDocumentDialogClickOpen = async (pdfUrl) => {
    try {
      const response = await fetch(pdfUrl);
      const blob = await response.blob();
      const base64String = await blobToBase64(blob);
      setSelectedPDF({ contentType: blob.type, content: base64String });
      setOpenDocumentDialog(true);
    } catch (error) {
      console.error("Error fetching PDF:", error);
    }
  };

  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1];
        resolve(base64String);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleDocumentDialogClickClose = () => {
    setOpenDocumentDialog(false);
    setSelectedPDF({ contentType: null, content: null });
  };

  const handleEditPackageDialogClickOpen = (index) => {
    setOpenEditPackageDialog(true);

    let contractPackage;

    contractPackage = packageData[index];

    if (contractPackage) {
      setContractPackageEditingIndex(index);
      setEditPackageName(contractPackage.packageName);
      setEditTerm(contractPackage.contractTerm);
      setEditDuration(contractPackage.duration);
      setEditPrice(contractPackage.price);
    }
  };

  const handleChange = (event) => {
    setTerm(event.target.value);
  };

  const handleEditTermChange = (event) => {
    setEditTerm(event.target.value);
  };

  const handleAddPackages = () => {
    setAddPackageName("");
    setTerm("");
    setDuration("");
    setPrice("");

    setPackageData((prevPackageData) => [
      ...prevPackageData,
      {
        packageName: addPackageName,
        contractTerm: term,
        duration: duration,
        price: price,
      },
    ]);
    setOpenCreatePackageDialog(false);
  };

  const handlePackageDelete = (index, updateContractId, contractPackageId) => {
    if (contractPackageId !== undefined) {
      handlePackageDeleteByPackageId(updateContractId, contractPackageId);
    } else {
      handlePackageDeleteByIndex(index);
    }
  };

  const handlePackageDeleteByIndex = (id) => {
    const newPackages = packageData.filter((value, index) => index !== id);
    setPackageData(newPackages);
  };

  const handlePackageDeleteByPackageId = async (
    updateContractId,
    contractPackageId
  ) => {
    setLoading(true);
    const apiUrl = `${process.env.REACT_APP_URL}items/package/${updateContractId}/${contractPackageId}/${userId}`;

    const response = await axios.delete(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      setLoading(false);
      getSellContract(updateContractId);
    } else {
      setLoading(false);
      toast.error("Something went wrong!");
    }
  };

  const getReferenceData = async () => {
    const countryOptions = await getCountry();
    if (countryOptions) {
      //   setCountry("");
      setCountryChoices(countryOptions);
    }
  };

  const getStatesData = async (country) => {
    if (country && country !== "") {
      let stateOptions = [];
      stateOptions = await getStates(country);
      if (stateOptions) {
        // setState("");
        setStateChoices(stateOptions);
      }
    }
  };

  const getCityData = async (country, state) => {
    if (country && country !== "" && state && state !== "") {
      let cityOptions = [];
      cityOptions = await getCity(country, state);
      if (cityOptions) {
        // setCity("");
        setCityChoices(cityOptions);
      }
    }
  };

  const getBrandNamesData = async (brand) => {
    const brandOptions = await getBrandNames(brand);
    if (brandOptions) {
      setBrandName(null);
      setBrandOptions(brandOptions);
    }
  };

  const getSpecialitiesData = async (speciality) => {
    const specialityOptions = await getSpecialities(speciality);
    if (specialityOptions) {
      setSpecialityOptions(specialityOptions);
    }
  };

  const handleAddSpecialities = (newSpeciality) => {
    if (
      newSpeciality &&
      newSpeciality.trim() !== "" &&
      !specialityData.some((item) => item.suitableFor === newSpeciality)
    ) {
      setSpecialityData((prevSpecialityData) => [
        ...prevSpecialityData,
        { suitableFor: newSpeciality },
      ]);
      setSpecialityInputValue("");
      setSpecialityName(null);
    }
  };

  const handleSpecialityDelete = (specialityname) => {
    setSpecialityData(
      specialityData.filter((chips) => chips.suitableFor !== specialityname)
    );
  };

  const getCategories = async () => {
    const categoriesOptions = await getProductCategories();
    if (categoriesOptions) {
      setCategoryOptions(categoriesOptions);
    }
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
    handleCategoryWiseFilter(event.target.value);
  };

  const handleCategoryWiseFilter = (category) => {
    let selectedCategory = categoryOptions.find(
      (item) => item.categoryLink?.rel === category
    );

    let selectedSubCategory = selectedCategory
      ? selectedCategory.subcategoryLink
      : [];

    setSubCategoryChoices(selectedSubCategory);
  };

  const handleSubCategoryChange = (event) => {
    setSubCategory(event.target.value);
  };

  const getSellContract = async (updateContractId) => {
    setLoading(true);

    const apiUrl = `${process.env.REACT_APP_URL}items/${userId}/${updateContractId}`;

    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      setTitle(response.data.title);
      setCategory(response.data.category);
      setSubCategory(response.data.product);
      setDescription(response.data.description);
      setBrandInputValue(response.data.brand);
      setSpecialityData(response.data.itemSuitabilities);
      setShowSelectedImages(response.data.itemImages);
      setUploadedAttachment(response.data.itemAttachments);
      setPackageData(response.data.itemPackages);
      setCountry(response.data.country);
      getStatesData(response.data.country);
      setState(response.data.state);
      getCityData(response.data.country, response.data.state);
      setCity(response.data.city);
      setComment(response.data.comment);
      setLoading(false);
    } else {
      toast.error("Something went wrong!");
      setLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);
    const images = [];
    const pdfs = [];

    if (
      selectedFilesArray.length > 5 ||
      selectedFilesArray.some(
        (file) => file.type === "application/pdf" && file.size > 4 * 1024 * 1024
      )
    ) {
      if (selectedFilesArray.length > 5) {
        toast.error("You can not upload more than five files");
      } else {
        toast.error("PDF file size should be 4 MB or less");
      }
      return;
    }

    selectedFilesArray.forEach((file) => {
      if (file.type.startsWith("image/")) {
        images.push(file);
      } else if (file.type === "application/pdf") {
        pdfs.push(file);
      }
    });

    setSelectedImagesCount((prevCount) => prevCount + images.length);
    setSelectedPdfCount((prevCount) => prevCount + pdfs.length);

    if (
      selectedImagesCount + images.length >= 5 &&
      selectedPdfCount + pdfs.length >= 5
    ) {
      setShowUploadContainer(false);
    }

    handleImageProcessing(images);
    setSelectedPdfFiles((prevPdfFiles) => [...prevPdfFiles, ...pdfs]);
  };

  const handleImageProcessing = async (images) => {
    setLoading(true);
    const compressedImagesArray = [];

    const showImages = images.map((file) => URL.createObjectURL(file));
    setShowSelectedImages((prevImages) => prevImages.concat(showImages));

    for (const image of images) {
      try {
        const compressedImage = await fileCompressor(image);
        const imageData = {
          type: image.type,
          compressedImage: compressedImage,
        };
        compressedImagesArray.push(imageData);
      } catch (error) {
        setLoading(false);
        console.error("Error compressing image:", error);
      }
    }

    setCompressedImages((prevCompressedImages) => [
      ...prevCompressedImages,
      ...compressedImagesArray,
    ]);
    setLoading(false);
  };

  const handleImageDelete = (id) => {
    const newImages = showSelectedImages.filter((photo, index) => index !== id);
    setShowSelectedImages(newImages);

    const fileCompressedArray = compressedImages.filter(
      (photo, index) => index !== id
    );
    setCompressedImages(fileCompressedArray);

    // Update the image count after deleting a file
    setSelectedImagesCount((prevCount) => prevCount - 1);
  };

  const handleDeleteImageFromApi = async (contractId, imgId) => {
    setLoading(true);
    const apiUrl = `${process.env.REACT_APP_URL}items/image/${userId}/${contractId}/${imgId}`;

    const response = await axios.delete(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      const newImages = showSelectedImages.filter(
        (element, index) => element.id !== imgId
      );
      setShowSelectedImages(newImages);
      setLoading(false);
    } else {
      setLoading(false);
      toast.error("Something went wrong!");
    }
  };

  const handleDeletePdfFromApi = async (contractId, pdfId) => {
    setLoading(true);
    const apiUrl = `${process.env.REACT_APP_URL}items/attachment/${userId}/${contractId}/${pdfId}`;

    const response = await axios.delete(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      const newPdfs = uploadedAttachment.filter(
        (element, index) => element.id !== pdfId
      );
      setUploadedAttachment(newPdfs);
      setLoading(false);
    } else {
      setLoading(false);
      toast.error("Something went wrong!");
    }
  };

  // Function to handle delete action
  const handleDelete = (item, index, updateContractId) => {
    if (item.id) {
      handleDeleteImageFromApi(updateContractId, item.id);
    } else {
      handleImageDelete(index);
    }
  };

  const handlePdfFileDelete = (id) => {
    const pdfFiles = selectedPdfFiles.filter((photo, index) => index !== id);
    setSelectedPdfFiles(pdfFiles);

    // Update the PDF count after deleting a file
    setSelectedPdfCount((prevCount) => prevCount - 1);
  };

  function base64ToBlob(base64String) {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray]);
  }

  const updateSellContract = async (contractId) => {
    setIsApiCallInProgress(true);
    setLoading(true);

    const apiUrl = `${process.env.REACT_APP_URL}items/${userId}/${contractId}`;

    const requestBody = {
      id: contractId,
      itemType: "CONTRACT",
      title: title,
      category: category,
      product: subCategory,
      description: description,
      brand: brandInputValue,
      itemSuitabilities: specialityData,
      itemPackages: packageData,
      country: country,
      state: state,
      city: city,
      comment: comment,
    };
    const response = await axios.put(apiUrl, requestBody, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });

    if (response.data) {
      let isImageUploadedSuccessfully = false;
      let isAttachmentUploadedSuccessfully = false;

      toast.success("Contract updated successfully !!!");
      if (compressedImages.length !== 0) {
        const photos = new FormData();

        // Iterate over the compressedImages array
        compressedImages.forEach((imageData, index) => {
          try {
            const blob = base64ToBlob(imageData.compressedImage); // Convert base64 string to blob

            // Append the blob with the correct MIME type and filename
            photos.append(
              "images",
              blob,
              `image_${index}.${imageData.type}`,
              `image/${imageData.type}`
            );
          } catch (error) {
            console.error("Error converting image to blob:", error);
          }
        });
        const uploadImageurl = `${process.env.REACT_APP_URL}items/upload-image/${userId}/${contractId}`;

        const response = await axios.post(uploadImageurl, photos, {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "multipart/form-data",
          },
        });
        if (response.status === 200) {
          setLoading(false);
          setIsApiCallInProgress(false);
          toast.success("Contract images updated successfully !!!");
          isImageUploadedSuccessfully = true;
        } else {
          setLoading(false);
          setIsApiCallInProgress(false);
          toast.error(
            "Contract updated Successfully, but there's an error uploading images, Please upload again in Edit Contract.!"
          );
        }
      }

      if (selectedPdfFiles.length !== 0) {
        const formData = new FormData();

        for (let i = 0; i < selectedPdfFiles.length; i++) {
          formData.append("attachments", selectedPdfFiles[i]);
        }
        const uploadAttachmenturl = `${process.env.REACT_APP_URL}items/upload-attachment/${userId}/${contractId}`;

        const response = await axios.post(uploadAttachmenturl, formData, {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "multipart/form-data",
          },
        });
        if (response.status === 200) {
          setLoading(false);
          setIsApiCallInProgress(false);
          toast.success("Contract pdf files uploaded successfully !!!");
          isAttachmentUploadedSuccessfully = true;
        } else {
          setLoading(false);
          setIsApiCallInProgress(false);
          toast.error(
            "Contract updated Successfully, but there's an error uploading attachments, Please upload again in Edit Contract.!"
          );
        }
      }

      if (
        isImageUploadedSuccessfully === true ||
        isAttachmentUploadedSuccessfully === true
      ) {
        navigate(Constants.INVENTORY);
      } else {
        setLoading(false);
        setIsApiCallInProgress(false);
        navigate(Constants.INVENTORY);
      }
    } else {
      setLoading(false);
      setIsApiCallInProgress(false);
      toast.error("Something went wrong!");
    }
  };

  const handleEditPackages = () => {
    const index = contractPackageEditingIndex;

    if (index !== null && index >= 0 && index < packageData.length) {
      const updatedPackageData = [...packageData];

      updatedPackageData[index] = {
        ...updatedPackageData[index],
        packageName: editPackageName,
        contractTerm: editTerm,
        duration: editDuration,
        price: editPrice,
      };

      setPackageData(updatedPackageData);
    }
    setOpenEditPackageDialog(false);
    setContractPackageEditingIndex(null);
  };

  const handleUpdateContractSubmit = () => {
    if (validateUpdateContractForm()) {
      updateSellContract(contractId);
    }
  };

  const validateUpdateContractForm = () => {
    let valid = true;

    if (title.trim() === "") {
      toast.error("Contract Title cannot be empty");
      valid = false;
    }

    if (category.trim() === "") {
      toast.error("Product Category cannot be empty");
      valid = false;
    }

    if (subCategory.trim() === "") {
      toast.error("Product Name cannot be empty");
      valid = false;
    }

    if (description.trim() === "") {
      toast.error("Description cannot be empty");
      valid = false;
    }

    if (brandInputValue.trim() === "") {
      toast.error("Brand Name cannot be empty");
      valid = false;
    }

    if (country.trim() === "") {
      toast.error("Country cannot be empty");
      valid = false;
    }

    if (compressedImages.length > 5) {
      toast.error("You can not upload more than five images");
      valid = false;
    }

    if (selectedPdfFiles.length > 5) {
      toast.error("You can not upload more than five attachments");
      valid = false;
    }

    if (comment === "") {
      toast.error("please add a comment");
      valid = false;
    }

    return valid;
  };

  return (
    <>
      <MainLayout>
        <Container
          maxWidth="md"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 0.5,
            overflowY: "auto",
            width: "100%",
            p: 0,
          }}
        >
          {" "}
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Paper elevation={2} className="sellcontract-paper-container">
                <Box display="flex" flexDirection="column" gap={2}>
                  <Box flex={1}>
                    <TextField
                      fullWidth
                      required
                      label="Contract title"
                      name="title"
                      value={title}
                      onChange={(event) => {
                        const inputValue = event.target.value;
                        const formattedValue = inputValue
                          .split(" ")
                          .map((word) => {
                            if (word === word.toUpperCase()) {
                              return word;
                            } else {
                              return (
                                word.charAt(0).toUpperCase() +
                                word.slice(1).toLowerCase()
                              );
                            }
                          })
                          .join(" ");

                        setTitle(formattedValue);
                      }}
                      autoComplete="off"
                      id="contract-title"
                      variant="outlined"
                    />
                  </Box>
                  <Box flex={1}>
                    <FormControl fullWidth>
                      <InputLabel
                        id="category-select-label"
                        htmlFor="category-picker"
                      >
                        Product Category
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="category-label"
                        id="category-picker"
                        label="Select Category"
                        value={category}
                        onChange={handleCategoryChange}
                      >
                        <MenuItem value={""}>Select Category</MenuItem>
                        {categoryOptions &&
                          categoryOptions.map((choice, index) => {
                            return (
                              <MenuItem
                                value={choice.categoryLink?.rel}
                                key={index}
                              >
                                {choice.categoryLink?.rel}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box flex={1}>
                    <FormControl fullWidth>
                      <InputLabel id="subCategory-label">
                        Product Name
                      </InputLabel>
                      <Select
                        labelId="subCategory-label"
                        id="subCategory"
                        value={subCategory}
                        label="Product Name"
                        onChange={handleSubCategoryChange}
                        disabled={!category}
                      >
                        {subCategoryChoices &&
                          subCategoryChoices.map((option, index) => (
                            <MenuItem key={index} value={option.rel}>
                              {option.rel}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box flex={1}>
                    <TextField
                      fullWidth
                      label="Contract Description"
                      name="contractdescription"
                      value={description}
                      onChange={(event) =>
                        setDescription(
                          event.target.value
                            .toLowerCase()
                            .split(" ")
                            .map((word) => {
                              return (
                                word.charAt(0).toUpperCase() + word.slice(1)
                              );
                            })
                            .join(" ")
                        )
                      }
                      autoComplete="off"
                      multiline
                      minRows={3}
                      id="contractdescription"
                      variant="outlined"
                    />
                  </Box>
                  <Box flex={1}>
                    <Autocomplete
                      freeSolo
                      id="brand"
                      disableClearable
                      options={
                        brandOptions && brandOptions.map((option) => option)
                      }
                      value={brandName}
                      onChange={(event, newValue) => {
                        setBrandName(newValue);
                      }}
                      inputValue={brandInputValue}
                      onInputChange={(event, newInputValue) => {
                        const capitalizedBrandInput = newInputValue
                          .toLowerCase()
                          .split(" ")
                          .map((word) => {
                            return word.charAt(0).toUpperCase() + word.slice(1);
                          })
                          .join(" ");

                        setBrandInputValue(capitalizedBrandInput);

                        if (
                          !brandOptions.find(
                            (option) => option === capitalizedBrandInput
                          )
                        ) {
                          getBrandNamesData(capitalizedBrandInput);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Brand Name"
                          slotProps={{
                            ...params.InputProps,
                            type: "search",
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Box flex={1}>
                    <Autocomplete
                      freeSolo
                      id="specialityname"
                      disableClearable
                      options={
                        specialityOptions &&
                        specialityOptions.map((option) => option)
                      }
                      value={specialityName}
                      onChange={(event, newValue) => {
                        setSpecialityName(newValue);
                        if (
                          !specialityData.some(
                            (item) => item.suitableFor === newValue
                          )
                        ) {
                          handleAddSpecialities(newValue);
                        }
                      }}
                      inputValue={specialityInputValue}
                      onInputChange={(event, newInputValue) => {
                        const capitalizedInput = newInputValue
                          .toLowerCase()
                          .split(" ")
                          .map((word) => {
                            return word.charAt(0).toUpperCase() + word.slice(1);
                          })
                          .join(" ");

                        setSpecialityInputValue(capitalizedInput);

                        if (!specialityOptions.includes(capitalizedInput)) {
                          getSpecialitiesData(capitalizedInput);
                        }
                      }}
                      onBlur={() => {
                        if (
                          !specialityOptions.find(
                            (option) => option === specialityInputValue
                          )
                        ) {
                          handleAddSpecialities(specialityInputValue);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Suitable For"
                          slotProps={{
                            ...params.InputProps,
                            type: "search",
                          }}
                        />
                      )}
                    />
                  </Box>
                  {specialityData && (
                    <Box container direction="column">
                      <Box
                        container
                        direction="row"
                        style={{ padding: "10px", marginTop: "10px" }}
                      >
                        <Box item>
                          <Paper
                            elevation={0}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              flexWrap: "wrap",
                              listStyle: "none",
                              backgroundColor: "transparent",
                              p: 0.5,
                              m: 0,
                            }}
                            component="ul"
                          >
                            {specialityData.map((data, index) => {
                              return (
                                <ListItem key={index}>
                                  <Chip
                                    variant="outlined"
                                    color="primary"
                                    style={{
                                      backgroundColor:
                                        theme.palette.mode === "light"
                                          ? "#fff"
                                          : "#292929",
                                      color:
                                        theme.palette.mode === "light"
                                          ? "#000"
                                          : "#fff",
                                    }}
                                    label={data.suitableFor}
                                    onDelete={() =>
                                      data.suitableFor === ""
                                        ? undefined
                                        : handleSpecialityDelete(
                                            data.suitableFor
                                          )
                                    }
                                  />
                                </ListItem>
                              );
                            })}
                          </Paper>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>

                {/* ############################  contract document section ######################################### */}

                <Box display="flex" flexDirection="column" gap={2}>
                  <Typography
                    color="text.primary"
                    gutterBottom
                    className="sell-contract-label"
                  >
                    Attachments
                  </Typography>
                </Box>

                {showUploadContainer && (
                  <Box
                    container
                    direction="column"
                    justifyContent="center"
                    align="center"
                    className="showcase-product-upload-Container"
                  >
                    <Box flex={1}>
                      <IconButton component="label" style={{ padding: "0px" }}>
                        <input
                          hidden
                          accept="image/*,.pdf"
                          multiple
                          type="file"
                          onChange={handleFileChange}
                        />
                        <CloudUploadIcon
                          color="primary"
                          className="sell-contract-upload-Icon"
                        />
                      </IconButton>
                    </Box>
                    <Box item>
                      <Typography
                        variant="subtitle1"
                        color="text.primary"
                        align="center"
                        gutterBottom
                      >
                        Upload Documents
                      </Typography>
                    </Box>
                  </Box>
                )}

                <div className="contract-gallery">
                  {showSelectedImages &&
                    showSelectedImages.map((item, index) => {
                      return (
                        <div key={index} style={{ position: "relative" }}>
                          <img
                            src={item.imageLink ? item.imageLink?.href : item}
                            alt="upload"
                            height="100%"
                            width="100%"
                          />
                          <CloseIcon
                            style={{
                              position: "absolute",
                              top: "6px",
                              right: "6px",
                              fontSize: fullScreen ? "18px" : "22px",
                              color: "red",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleDelete(item, index, contractId)
                            }
                          />
                        </div>
                      );
                    })}
                </div>

                {uploadedAttachment && (
                  <div className="pdf-container">
                    {uploadedAttachment &&
                      uploadedAttachment.map((element, index) => {
                        return (
                          <div
                            key={element.id}
                            style={{ position: "relative" }}
                          >
                            <FaRegFilePdf
                              style={{
                                fontSize: fullScreen ? "80px" : "60px",
                                color:
                                  theme.palette.mode === "light"
                                    ? "#002E6E"
                                    : "#00B9F1",
                              }}
                              className="dialog-uploaded-icon"
                              onClick={() =>
                                handleDocumentDialogClickOpen(
                                  element.attachmentLink.href
                                )
                              }
                            />
                            <IconButton
                              disableRipple
                              style={{
                                position: "absolute",
                                top: "0px",
                                right: "0px",
                                zIndex: 1,
                                padding: "0px",
                              }}
                              onClick={() =>
                                handleDeletePdfFromApi(contractId, element.id)
                              }
                            >
                              <CloseIcon
                                style={{
                                  fontSize: fullScreen ? "18px" : "22px",
                                  color: "red",
                                  cursor: "pointer",
                                }}
                              />
                            </IconButton>
                          </div>
                        );
                      })}
                  </div>
                )}

                {selectedPdfFiles &&
                  selectedPdfFiles.map((item, index) => {
                    return (
                      <div className="contract-pdf-container" key={index}>
                        <PictureAsPdfIcon className="contract-pdf-icon" />
                        <Typography
                          variant="subtitle1"
                          className="contract-pdf-filename"
                        >
                          {item.name}
                        </Typography>
                        <ClearIcon
                          className="contract-pdf-icon"
                          onClick={() => handlePdfFileDelete(index)}
                        />
                      </div>
                    );
                  })}

                {/* ############################ PDF viewer dialog ######################################### */}

                <Dialog
                  open={openDocumentDialog}
                  onClose={handleDocumentDialogClickClose}
                  fullScreen={fullScreen}
                  maxWidth="md"
                  fullWidth
                  TransitionComponent={Transition}
                >
                  <DialogTitle
                    style={{ backgroundColor: "#00B9F1", color: "white" }}
                  >
                    <IconButton
                      edge="start"
                      onClick={handleDocumentDialogClickClose}
                      sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                      }}
                    >
                      <CloseIcon style={{ color: "white" }} />
                    </IconButton>
                    View Document
                  </DialogTitle>
                  <DialogContent style={{ padding: "0px" }}>
                    <PDFViewer
                      pdfData={`data:${selectedPDF.contentType};base64,${selectedPDF.content}`}
                    />
                  </DialogContent>
                </Dialog>

                {/* ############################  Contract packages section ######################################### */}
                <div>
                  <div className="repair-sell-contract-container">
                    <div className="add-icon-container">
                      <Typography
                        align="left"
                        variant="subtitle1"
                        color="text.primary"
                        className="sell-contract-label"
                      >
                        Contract Packages
                      </Typography>
                    </div>

                    <IconButton
                      style={{ padding: "0px" }}
                      onClick={handleCreatePackageDialogClickOpen}
                    >
                      <AddIcon color="primary" size="large" />
                    </IconButton>
                  </div>
                  <Box display="flex" flexDirection="column" gap={2}>
                    {packageData &&
                      packageData.map((value, index) => {
                        return (
                          <>
                            <Paper
                              elevation={4}
                              className="contract-package-container"
                              key={value.id}
                            >
                              <div className="package-container">
                                <div className="package_details">
                                  <Typography
                                    align="left"
                                    color="text.primary"
                                    className="text-capital contract-title"
                                  >
                                    {value.packageName}
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    align="left"
                                    color="text.primary"
                                    className="text-capital contract-subtitle"
                                  >
                                    {value.duration} {value.contractTerm}
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    align="left"
                                    color="text.primary"
                                    className="text-capital contract-description"
                                  >
                                    &#8377; {value.price}
                                  </Typography>
                                </div>
                                <div className="package_iconbtn_container">
                                  <IconButton
                                    onClick={() => {
                                      handleEditPackageDialogClickOpen(index);
                                    }}
                                    className="icon_btn"
                                  >
                                    <EditIcon color="primary" />
                                  </IconButton>
                                  <IconButton
                                    style={{ padding: "0px" }}
                                    onClick={() => {
                                      handlePackageDelete(
                                        index,
                                        contractId,
                                        value.id
                                      );
                                    }}
                                  >
                                    <DeleteIcon
                                      color="primary"
                                      style={{ verticalAlign: "middle" }}
                                    />
                                  </IconButton>
                                </div>
                              </div>
                            </Paper>
                          </>
                        );
                      })}
                  </Box>

                  {/* ############################ Create Sellcontract Package dialog ######################################### */}
                  <Dialog
                    open={openCreatePackageDialog}
                    onClose={handleCreatePackageDialogClickClose}
                    fullScreen={fullScreen}
                  >
                    <DialogTitle
                      style={{ backgroundColor: "#00B9F1", color: "white",marginBottom: "20px" }}
                    >
                      <IconButton
                        edge="start"
                        onClick={handleCreatePackageDialogClickClose}
                        sx={{
                          position: "absolute",
                          right: 8,
                          top: 8,
                        }}
                      >
                        <CloseIcon style={{ color: "white" }} />
                      </IconButton>
                      Add Package
                    </DialogTitle>
                    <DialogContent sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px" // Adds consistent spacing between children
                }}>
                      <Box display="flex" flexDirection="column" gap={2}>
                        {/* Package Name */}
                        <TextField
                          fullWidth
                          label="Package Name"
                          name="packagename"
                          value={addPackageName}
                          onChange={(event) => {
                            const inputValue = event.target.value;
                            const formattedValue = inputValue
                              .split(" ")
                              .map((word) => {
                                if (word === word.toUpperCase()) {
                                  return word;
                                } else {
                                  return (
                                    word.charAt(0).toUpperCase() +
                                    word.slice(1).toLowerCase()
                                  );
                                }
                              })
                              .join(" ");
                            setAddPackageName(formattedValue);
                          }}
                          autoComplete="off"
                          id="packagename"
                          variant="outlined"
                        />

                        {/* Term */}
                        <TextField
                          label="Term"
                          select
                          value={term}
                          onChange={handleChange}
                          fullWidth
                        >
                          <MenuItem value="MONTHS">Months</MenuItem>
                          <MenuItem value="YEARS">Years</MenuItem>
                        </TextField>

                        {/* Duration */}
                        <TextField
                          fullWidth
                          label="Duration"
                          name="duration"
                          type="tel"
                          value={duration}
                          onChange={(event) => setDuration(event.target.value)}
                          autoComplete="off"
                          id="duration"
                          variant="outlined"
                        />

                        {/* Price */}
                        <TextField
                          fullWidth
                          label="Price"
                          name="price"
                          type="tel"
                          value={price}
                          onChange={(event) => setPrice(event.target.value)}
                          autoComplete="off"
                          id="price"
                          variant="outlined"
                        />
                      </Box>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={handleAddPackages}
                        fullWidth
                        variant="contained"
                        className="contract-button"
                        color="primary"
                      >
                        Save
                      </Button>
                    </DialogActions>
                  </Dialog>

                  {/* ############################ Edit Sellcontract Package dialog ######################################### */}
                  <Dialog
                    open={openEditPackageDialog}
                    onClose={handleEditPackageDialogClickClose}
                    fullScreen={fullScreen}
                    fullWidth
                    maxWidth="sm"
                  >
                    <DialogTitle
                      style={{ backgroundColor: "#00B9F1", color: "white",marginBottom: "20px"}}
                    >
                      <IconButton
                        edge="start"
                        onClick={handleEditPackageDialogClickClose}
                        sx={{
                          position: "absolute",
                          right: 8,
                          top: 8,
                        }}
                      >
                        <CloseIcon style={{ color: "white" }} />
                      </IconButton>
                      Add Package
                    </DialogTitle>
                    <DialogContent>
                      <Box display="flex" flexDirection="column" gap={2}>
                        <Box flex={1}>
                          <TextField
                            fullWidth
                            label="Package Name"
                            name="editpackagename"
                            value={editPackageName}
                            onChange={(event) =>
                              setEditPackageName(
                                event.target.value
                                  .toLowerCase()
                                  .split(" ")
                                  .map((word) => {
                                    return (
                                      word.charAt(0).toUpperCase() +
                                      word.slice(1)
                                    );
                                  })
                                  .join(" ")
                              )
                            }
                            autoComplete="off"
                            id="editpackagename"
                            variant="outlined"
                          />
                        </Box>
                        <Box flex={1}>
                          <TextField
                            label="Term"
                            select
                            value={editTerm}
                            onChange={handleEditTermChange}
                            fullWidth
                          >
                            <MenuItem value="MONTHS">Months</MenuItem>
                            <MenuItem value="YEARS">Years</MenuItem>
                          </TextField>
                        </Box>
                        <Box flex={1}>
                          <TextField
                            fullWidth
                            label="Duration"
                            name="editduration"
                            type="tel"
                            value={editDuration}
                            onChange={(event) =>
                              setEditDuration(event.target.value)
                            }
                            autoComplete="off"
                            id="editduration"
                            variant="outlined"
                          />
                        </Box>
                        <Box flex={1}>
                          <TextField
                            fullWidth
                            label="Price"
                            name="editprice"
                            type="tel"
                            value={editPrice}
                            onChange={(event) =>
                              setEditPrice(event.target.value)
                            }
                            autoComplete="off"
                            id="editprice"
                            variant="outlined"
                          />
                        </Box>
                      </Box>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={handleEditPackages}
                        fullWidth
                        variant="contained"
                        className="contract-button"
                        color="primary"
                      >
                        Save
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>

                <Box display="flex" flexDirection="column" gap={2}>
                  <Box flex={1}>
                    <Typography
                      align="left"
                      color="text.primary"
                      className="sell-product-label"
                      style={{ marginTop: "10px", marginBottom: "10px" }}
                    >
                      Can be sold at
                    </Typography>
                  </Box>
                  <Box flex={1}>
                    <FormControl fullWidth>
                      <InputLabel
                        id="country-select-label"
                        htmlFor="country-picker"
                      >
                        Country
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="country-label"
                        id="country-picker"
                        label="Select Country"
                        value={country}
                        onChange={(event) => {
                          setCountry(event.target.value);
                          setStateChoices([]);
                          setCityChoices([]);
                          getStatesData(event.target.value);
                        }}
                      >
                        <MenuItem value={""} selected>
                          Select Country
                        </MenuItem>
                        {Array.isArray(countryChoices) &&
                          countryChoices.map((choice, index) => {
                            return (
                              <MenuItem value={choice} key={index}>
                                {choice}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box flex={1}>
                    <FormControl fullWidth>
                      <InputLabel
                        id="state-select-label"
                        htmlFor="state-picker"
                      >
                        State
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="state-label"
                        id="state-picker"
                        label="Select State"
                        value={state}
                        onChange={(event) => {
                          setState(event.target.value);
                          setCityChoices([]);
                          getCityData(country, event.target.value);
                        }}
                      >
                        <MenuItem value={""}>Select State</MenuItem>
                        {Array.isArray(stateChoices) &&
                          stateChoices.map((choice, index) => {
                            return (
                              <MenuItem value={choice} key={index}>
                                {choice}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box flex={1}>
                    <FormControl fullWidth>
                      <InputLabel
                        id="demo-dialog-select-label"
                        htmlFor="city-picker"
                      >
                        City
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="city-label"
                        id="city-picker"
                        label="Select City"
                        value={city}
                        onChange={(event) => setCity(event.target.value)}
                      >
                        <MenuItem value={""}>Select City</MenuItem>
                        {Array.isArray(cityChoices) &&
                          cityChoices.map((choice, index) => {
                            return (
                              <MenuItem value={choice} key={index}>
                                {choice}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box>
                    <TextField
                      label="Add a comment"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      sx={{ margin: 0 }}
                    />
                  </Box>
                </Box>
              </Paper>

              {!isApiCallInProgress && (
                <Box
                  container
                  direction="row"
                  style={{ padding: fullScreen ? "0px 6px" : "0px 10px" }}
                >
                  <Box flex={1}>
                    <Button
                      variant="contained"
                      fullWidth
                      className="contract-button"
                      color="primary"
                      onClick={handleUpdateContractSubmit}
                    >
                      Update Contract
                    </Button>
                  </Box>
                </Box>
              )}
            </>
          )}
        </Container>
      </MainLayout>
    </>
  );
};

export default Updatesellcontract;
