import React, { useState, useEffect } from 'react';
import { TextField, Paper, Button, InputLabel, Select, MenuItem, FormControl, Autocomplete, Chip, Box, Container, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import { useTheme } from '@mui/material/styles';
import useGetData from '../../../API/useGetData';
import Constants from "../../../MeddyConnectConstant";
import UrlRoute from "../../../API/UrlRoute";
import MainLayout from "../../MainLayout";
import { toast } from 'react-toastify';
import axios from 'axios';
import "./editpostajob.css";

const EditPostAJob = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { jobId } = useParams();
  const { authToken, searchedUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
  const userId = searchedUser?.userId;

  const [jobTitle, setJobTitle] = useState("");
  const [description, setDescription] = useState('');
  const [qualification, setQualification] = useState("");
  const [jobType, setJobType] = useState('');
  const [expectedSalary, setExpectedSalary] = useState("");
  const [skillsData, setSkillsData] = useState([]);
  const [skills, setSkills] = useState(null);
  const [skillsInputValue, setSkillsInputValue] = useState('');
  const [skillsOptions, setSkillsOptions] = useState([]);
  const [hashtagsData, setHashtagsData] = useState([]);
  const [hashtag, setHashtag] = useState(null);
  const [hashtagsInputValue, setHashtagsInputValue] = useState('');
  const [hashtagsOptions, setHashtagsOptions] = useState([]);
  const [pinCode, setPinCode] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [stateChoices, setStateChoices] = useState("");
  const [cityChoices, setCityChoices] = useState("");
  const [countryChoices, setCountryChoices] = useState("");
  const [comment, setComment] = useState('');

  // Services
  const { getCountry, getStates, getCity, getSkills, getHashtags } = useGetData();

  useEffect(() => {
    getReferenceData();
    getPostedJobByUser(userId, jobId);
  }, []);

  useEffect(() => {
    getSkillsData("");
  }, [skillsData]);

  useEffect(() => {
    getHashtagsData("");
  }, [hashtagsData]);

  const getReferenceData = async () => {
    const countryOptions = await getCountry();
    if (countryOptions) {
      // setCountry("");
      setCountryChoices(countryOptions);
    }
  };

  const getStatesData = async (country) => {
    if (country && country !== "") {
      let stateOptions = [];
      stateOptions = await getStates(country);
      if (stateOptions) {
        // setState("");
        setStateChoices(stateOptions);
      }
    }
  };

  const getCityData = async (country, state) => {
    if (country && country !== "" && state && state !== "") {
      let cityOptions = [];
      cityOptions = await getCity(country, state);
      if (cityOptions) {
        // setCity("");
        setCityChoices(cityOptions);
      }
    }
  };

  const getPostedJobByUser = async (userId, jobId) => {
    setLoading(true);

    try {
      if (userId && jobId) {

        const apiUrl = `${process.env.REACT_APP_URL}${UrlRoute.JOBS_URL}/${userId}/${jobId}`;

        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.status === 200) {
          const data = response.data;
          setJobTitle(data.title);
          setDescription(data.description);
          setQualification(data.qualification);
          setExpectedSalary(data.expectedSalary);
          setSkillsData(data.skills);
          setJobType(data.jobType);
          setHashtagsData(data.tags);
          setPinCode(data.pinCode);
          setCountry(data.country);
          getStatesData(data.country);
          setState(data.state);
          getCityData(data.country, data.state);
          setCity(data.city);
          toast.success("Job details fetched successfully!");
        } else {
          toast.error("Failed to fetch job details. Please try again.");
        }

      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/');
      } else {
        toast.error("Error fetching job details:");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleJobTypeChange = (event) => {
    setJobType(event.target.value);
  };

  const getSkillsData = async (skill) => {
    const skillsOptions = await getSkills(skill);
    if (skillsOptions) {
      setSkillsOptions(skillsOptions);
    }
  };

  const handleAddSkills = (newSkill) => {
    if (newSkill && newSkill.trim() !== '' && !skillsData.includes(newSkill)) {
      setSkillsData((prevSkillsData) => [...prevSkillsData, newSkill]);
      setSkillsInputValue('');
      setSkills(null);
    }
  };

  const handleSkillDelete = (deletedSkill) => {
    setSkillsData(
      skillsData.filter(item =>
        item !== deletedSkill
      )
    );
  }

  const getHashtagsData = async (tags) => {
    const tagsOptions = await getHashtags(tags);
    if (tagsOptions) {
      setHashtagsOptions(tagsOptions);
    }
  };

  const handleAddHashtags = (newHashtag) => {
    // Trim leading and trailing spaces
    const trimmedInputValue = newHashtag.trim();

    // Remove leading # and capitalize the first letter of each word
    const formattedHashtag = `#${trimmedInputValue.replace(/^#+/, '').split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")}`;

    if (formattedHashtag !== '#' && !hashtagsData.includes(formattedHashtag)) {
      setHashtagsData((prevHashtagsData) => [...prevHashtagsData, formattedHashtag]);
      setHashtagsInputValue('');
    }
  };

  const handleHashtagDelete = (deletedHashtag) => {
    setHashtagsData(
      hashtagsData.filter(element =>
        element !== deletedHashtag
      )
    );
  }

  const validateJobForm = () => {

    let valid = true;

    if (jobTitle.trim() === '') {
      toast.error("Job title cannot be empty");
      valid = false;
    }

    if (description.trim() === '') {
      toast.error("Description cannot be empty");
      valid = false;
    }

    if (qualification.trim() === '') {
      toast.error("Qualification cannot be empty");
      valid = false;
    }

    if (String(expectedSalary).trim() === '') {
      toast.error("Expected salary cannot be empty");
      valid = false;
    } else if (!/^[0-9]*$/.test(expectedSalary)) {
      toast.error("Expected salary must contain only numbers");
      valid = false;
    }

    if (country.trim() === '') {
      toast.error("Country cannot be empty");
      valid = false;
    }

    if (state.trim() === '') {
      toast.error("State cannot be empty");
      valid = false;
    }

    if (city.trim() === '') {
      toast.error("City cannot be empty");
      valid = false;
    }

    if (pinCode.trim() === '') {
      toast.error("PinCode cannot be empty");
      valid = false;
    } else {
      // Check if pincodes contains only numbers
      const numericRegex = /^[0-9]+$/;

      if (!numericRegex.test(pinCode)) {
        toast.error("Invalid PinCode. Please enter only numbers.");
        valid = false;
      } else if (pinCode.length !== 6) {
        toast.error("PinCode must be 6 digits long");
        valid = false;
      }
    }

    if (comment.trim() === '') {
      toast.error("Comment cannot be empty");
      valid = false;
    }

    return valid;
  };

  const updatePostedJobByUser = async (userId, jobId) => {

    setIsApiCallInProgress(true);
    setLoading(true);

    try {

      const apiUrl = `${process.env.REACT_APP_URL}${UrlRoute.JOBS_URL}/${userId}/${jobId}`;

      const requestBody = {
        id: jobId,
        title: jobTitle,
        description: description,
        qualification: qualification,
        jobType: jobType,
        expectedSalary: expectedSalary,
        country: country,
        state: state,
        city: city,
        pinCode: pinCode,
        skills: skillsData,
        tags: hashtagsData,
        comment: comment,
      };

      const response = await axios.put(apiUrl, requestBody, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        toast.success("Updated the job successfully !!!");
        navigate(Constants.MANAGE_JOBS);
      } else {
        toast.error("Something went wrong!");
      }

    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/');
      } else {
        toast.error("An error occurred while updating the job details.");
      }
    } finally {
      setLoading(false);
      setIsApiCallInProgress(false);
    }
  };

  const handleUpdateJobSubmit = () => {

    if (validateJobForm()) {
      updatePostedJobByUser(userId, jobId);
    }

  };

  return (
    <>
      <MainLayout>
        <Container
          maxWidth="md"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 0.5,
            overflowY: 'auto',
            width: '100%',
            p: 0
          }}
        >
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <CircularProgress />
            </Box>
          ) : (
            <>

              <Paper elevation={2} className="edit-job-paper-container">
                <Box display="flex" flexDirection="column" gap={2}>
                  <Box>
                    <TextField fullWidth
                      label="Job Title"
                      name="jobtitle"
                      value={jobTitle}
                      onChange={(event) => {
                        const inputValue = event.target.value;
                        const formattedValue = inputValue
                          .split(" ")
                          .map((word) => {
                            if (word === word.toUpperCase()) {
                              return word;
                            } else {
                              return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                            }
                          })
                          .join(" ");

                        setJobTitle(formattedValue);
                      }}
                      autoComplete="off"
                      id="jobtitle"
                      variant="outlined"
                    />
                  </Box>
                  <Box>
                    <TextField fullWidth
                      label="Job Description"
                      name="description"
                      value={description}
                      onChange={(event) => setDescription(event.target.value)}
                      autoComplete="off"
                      multiline
                      minRows={3}
                      id="description"
                      variant="outlined"
                    />
                  </Box>
                  <Box>
                    <TextField fullWidth
                      label="Qualification"
                      name="qualification"
                      value={qualification}
                      onChange={(event) => {
                        const inputValue = event.target.value;
                        const formattedValue = inputValue
                          .split(" ")
                          .map((word) => {
                            if (word === word.toUpperCase()) {
                              return word;
                            } else {
                              return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                            }
                          })
                          .join(" ");

                        setQualification(formattedValue);
                      }}
                      autoComplete="off"
                      id="qualification"
                      variant="outlined"
                    />
                  </Box>
                  <Box>
                    <TextField fullWidth
                      label="Salary(per month)"
                      name="salary"
                      value={expectedSalary}
                      onChange={(event) => setExpectedSalary(event.target.value.toLowerCase()
                        .split(" ")
                        .map(word => {
                          return word.charAt(0).toUpperCase() + word.slice(1);
                        })
                        .join(" "))}
                      autoComplete="off"
                      id="salary"
                      variant="outlined"
                    />
                  </Box>
                  <Box>
                    <Autocomplete
                      freeSolo
                      id="skills"
                      disableClearable
                      options={skillsOptions.map((option) => option)}
                      value={skills}
                      onChange={(event, newValue) => {
                        setSkills(newValue);
                        if (!skillsData.some((item) => item === newValue)) {
                          handleAddSkills(newValue);
                        }
                      }}
                      inputValue={skillsInputValue}
                      onInputChange={(event, newInputValue) => {

                        const capitalizedInput = newInputValue.toLowerCase()
                          .split(" ")
                          .map(word => {
                            return word.charAt(0).toUpperCase() + word.slice(1);
                          })
                          .join(" ")

                        setSkillsInputValue(capitalizedInput)

                        if (!skillsOptions.includes(capitalizedInput)) {
                          getSkillsData(capitalizedInput);
                        }

                      }}
                      onBlur={() => {
                        if (!skillsOptions.find((option) => option === skillsInputValue)) {
                          handleAddSkills(skillsInputValue);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Skills"
                          slotProps={{
                            input: {
                              ...params.InputProps,
                              type: 'search',
                            },
                          }}
                        />
                      )}
                    />
                  </Box>

                  {skillsData.length > 0 && (
                    <div className="edit-job-skills-container">
                      {skillsData.map((skill, index) => {
                        return (
                          <div key={index} style={{ margin: '5px' }}>
                            <Chip
                              variant='outlined'
                              color="primary"
                              style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929", color: theme.palette.mode === "light" ? "#000" : "#fff" }}
                              label={skill}
                              onDelete={() => handleSkillDelete(skill)}
                            />
                          </div>
                        );
                      })
                      }
                    </div>
                  )}

                  <Box>
                    <TextField
                      label="Job type"
                      select
                      value={jobType}
                      onChange={handleJobTypeChange}
                      fullWidth
                      disabled={true}
                    >
                      <MenuItem value="PART_TIME">Part Time</MenuItem>
                      <MenuItem value="FULL_TIME">Full Time</MenuItem>
                    </TextField>
                  </Box>
                  <Box>
                    <Autocomplete
                      freeSolo
                      id="hashtags"
                      disableClearable
                      options={hashtagsOptions.map((option) => option)}
                      value={hashtag}
                      onChange={(event, newValue) => {
                        setHashtag(newValue);
                        if (!hashtagsData.some((item) => item === newValue)) {
                          handleAddHashtags(newValue);
                        }
                      }}
                      inputValue={hashtagsInputValue}
                      onInputChange={(event, newInputValue) => {

                        const capitalizedInput = newInputValue.toLowerCase()
                          .split(" ")
                          .map(word => {
                            return word.charAt(0).toUpperCase() + word.slice(1);
                          })
                          .join(" ")

                        setHashtagsInputValue(capitalizedInput)

                        if (!hashtagsOptions.includes(capitalizedInput)) {
                          getHashtagsData(capitalizedInput);
                        }

                      }}
                      onBlur={() => {
                        if (!hashtagsOptions.find((option) => option === hashtagsInputValue)) {
                          handleAddHashtags(hashtagsInputValue);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Hashtags"
                          slotProps={{
                            input: {
                              ...params.InputProps,
                              type: 'search',
                            },
                          }}
                        />
                      )}
                    />
                  </Box>
                  {hashtagsData.length > 0 && (
                    <div className="edit-job-skills-container">
                      {hashtagsData.map((hashtag, index) => {
                        return (
                          <div key={index} style={{ margin: '5px' }}>
                            <Chip
                              variant='outlined'
                              color="primary"
                              style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929", color: theme.palette.mode === "light" ? "#000" : "#fff" }}
                              label={hashtag}
                              onDelete={() => handleHashtagDelete(hashtag)}
                            />
                          </div>
                        );
                      })
                      }
                    </div>
                  )}

                  <Box>
                    <TextField
                      label="Add a comment"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      sx={{ margin: 0 }}
                    />
                  </Box>

                  <Box display="flex" flexDirection={{ xs: "column", sm: "row" }} gap={2}>
                    <Box flex={1}>
                      <FormControl fullWidth>
                        <InputLabel id="country-select-label">
                          Country
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="country-select-label"
                          id="country-select"
                          label="Select Country"
                          value={country}
                          onChange={(event) => {
                            setCountry(event.target.value);
                            setStateChoices([]);
                            setCityChoices([]);
                            getStatesData(event.target.value);
                          }}
                        >
                          <MenuItem value={""} selected>
                            Select Country
                          </MenuItem>
                          {Array.isArray(countryChoices) &&
                            countryChoices.map((choice, index) => {
                              return (
                                <MenuItem value={choice} key={index}>{choice}</MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box flex={1}>
                      <FormControl fullWidth >
                        <InputLabel id="state-select-label">
                          State
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="state-select-label"
                          id="state-select"
                          label="Select State"
                          value={state}
                          onChange={(event) => {
                            setState(event.target.value);
                            setCityChoices([]);
                            getCityData(country, event.target.value);
                          }}
                        >
                          <MenuItem value={""}>Select State</MenuItem>
                          {Array.isArray(stateChoices) &&
                            stateChoices.map((choice, index) => {
                              return (
                                <MenuItem value={choice} key={index}>{choice}</MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>

                  <Box display="flex" flexDirection={{ xs: "column", sm: "row" }} gap={2}>
                    <Box flex={1}>
                      <FormControl fullWidth>
                        <InputLabel id="city-select-label">
                          City
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="city-select-label"
                          id="city-select"
                          label="Select City"
                          value={city}
                          onChange={(event) => setCity(event.target.value)}
                        >
                          <MenuItem value={""}>Select City</MenuItem>
                          {Array.isArray(cityChoices) &&
                            cityChoices.map((choice, index) => {
                              return (
                                <MenuItem value={choice} key={index}>{choice}</MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box flex={1}>
                      <TextField fullWidth
                        label="Pincode"
                        name="pincode"
                        value={pinCode}
                        onChange={(event) => setPinCode(event.target.value)}
                        autoComplete="off"
                        id="pincode"
                        variant="outlined"
                      />
                    </Box>
                  </Box>

                </Box>

              </Paper>

              {!isApiCallInProgress && (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                >
                  <Box flex={1}>
                    <Button
                      variant="contained"
                      fullWidth
                      className='edit-job-button'
                      color="primary"
                      onClick={handleUpdateJobSubmit}
                    >
                      Update Job
                    </Button>
                  </Box>
                </Box>
              )}

            </>
          )}
        </Container>
      </MainLayout>
    </>
  )
}

export default EditPostAJob